import React, { useState, useEffect } from "react";

import "./marketplace.css";

import axios from "axios";

import { Link } from "react-router-dom";

import Navbar from "../Navbar/Navbar";
import PlugIcon from "../../icons/plug";
import ApiIcon from "../../icons/api";
import FilterIcon from "../../icons/filter";
import Search from "../../icons/search";
import OpenIcon from "../../icons/open";
import CheckIcon from "../../icons/check";

import ReactGA from "react-ga4";

function Marketplace(props) {

  const [tabHeader, setTabHeader] = useState("all");

  const [availableApi, setAvailableApi] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  

  useEffect(() => {
    ReactGA.send({ hitType: "pageView", page: "/", title: "Marketplace" });
    try {

      const getApiBody = {
        clientId: props.clientId
      }
      axios.post(
        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/available-api`,
        getApiBody
      )
      .then(res => {
        setAvailableApi(res.data);
      })
    } catch (error) {}  
  }, []);

  const truncateTextWithWords = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
  
    const truncatedText = text.substr(0, maxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
  
    if (lastSpaceIndex === -1) {
      return truncatedText + '...';
    }
  
    return truncatedText.substr(0, lastSpaceIndex) + '...';
  }

  const renderCountriesFilter = () => {
    return availableApi.map(api =>  api.Countries ? api.Countries.split(", ") : []).flat()
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(country =>
      <div
        className={"input_checkbox_container" + (countriesFilter.includes(country) ? " active" : "")}
        onClick={() => toggleCountryFilter(country)}
        >
         <div className="input_radio">
           { countriesFilter.includes(country) && <CheckIcon /> }
         </div>
         <span>{country}</span>
       </div> 
     )
  }

  const toggleCountryFilter = (country) => {
    if (countriesFilter.includes(country)) {
      setCountriesFilter(countriesFilter.filter(c => c != country))
    } else {
      setCountriesFilter([...countriesFilter, country])
    }
  }

  const renderCategoriesFilter = () => {
    return availableApi.map(api => api.Categories ? api.Categories.split(", ") : []).flat()
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(category =>
      <div
        className={"input_checkbox_container" + (categoriesFilter.includes(category) ? " active" : "")}
        onClick={() => toggleCategoryFilter(category)}
        >
         <div className="input_radio">
           { categoriesFilter.includes(category) && <CheckIcon /> }
         </div>
         <span>{category}</span>
       </div> 
     )
  }

  const toggleCategoryFilter = (category) => {
    if (categoriesFilter.includes(category)) {
      setCategoriesFilter(categoriesFilter.filter(c => c != category))
    } else {
      setCategoriesFilter([...categoriesFilter, category])
    }
  }


  return (
    <div className="body" style={{background: "#fcfcfe"}}>
      <div className="navbar">
        <Navbar navActive="marketplace" lang={props.lang} />
        
        <div className="title_search">
          <div className="title_search_info">
            <h1>{props.lang == "english" ? "Marketplace" : "Marketplace"}</h1>
            <p>{props.lang == "english" ? "Welcome to the Marketplace – your hub for APIs. Browse our curated selection of available APIs to supercharge your projects and unlock new possibilities." : "Bienvenido al Marketplace de Scorce. Navega por nuestra selección de APIs disponibles para potenciar tus proyectos y desbloquea nuevas posibilidades."}</p>
            <div className="tabs tabs_page_header">
              <span className={"tab" + (tabHeader == "all" ? " active" : "")} onClick={() => setTabHeader("all")}>{props.lang == "english" ? "Available APIs" : "API Disponibles"}</span>
              <span className={"tab" + (tabHeader == "my" ? " active" : "")} onClick={() => setTabHeader("my")}>{props.lang == "english" ? "Activated APIs" : "API Activas"}</span>
              <div className="tab_controll" style={
                props.lang == "english" ?
                (
                  tabHeader == "all" ? {left: 10} :
                  {left: 153}  
                )
                :
                (
                  tabHeader == "all" ? {left: 13} :
                  {left: 149}  
                )
              }></div>
              <div className="tabs_header_side">
                <div className="input_container">
                  <Search />
                  <input type="text" placeholder={props.lang == "english" ? "Search" : "Buscar"} onChange={(e) => setSearchInput(e.target.value)}/>
                </div>
                <div className="btn btn_glass btn_icon" onClick={() => setFilterIsOpen(!filterIsOpen)}>
                  <FilterIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className={"api_filter_container" + (filterIsOpen ? " active" : "")}>
          <div className="api_filter_item">
            <h2>{props.lang == "english" ? "Countries" : "Países"}</h2>
            <div className="api_filter_list">
              {renderCountriesFilter()}
            </div>
          </div>
          <div className="api_filter_item">
            <h2>{props.lang == "english" ? "Categories" : "Categorías"}</h2>
            <div className="api_filter_list">
             {renderCategoriesFilter()}
            </div> 
          </div>
        </div>
        <div className="card_list api_card_list">
          {
            availableApi.length == 0 ?
            <>
              <div className="api_card_skeleton"></div>
              <div className="api_card_skeleton"></div>
              <div className="api_card_skeleton"></div>
              <div className="api_card_skeleton"></div>
              <div className="api_card_skeleton"></div>
              <div className="api_card_skeleton"></div>
            </>
            : availableApi
            .filter(api => tabHeader == "my" ? api.Activated : true)
            .filter((api) => countriesFilter.length > 0 ? api.Countries.split(", ").some(country => countriesFilter.includes(country)) : true)
            .filter((api) => categoriesFilter.length > 0 ? api.Categories.split(", ").some(category => categoriesFilter.includes(category)) : true)
            .filter(api => {
              const itemWords = api.Name.toLowerCase().split(" ");
              return searchInput
                .toLowerCase()
                .split(" ")
                .every(searchWord =>
                  itemWords.some(itemWord =>
                    itemWord.includes(searchWord)
                  )
                );
            })
            .map(api => 
                <div className="card_container"><div className="card">
                  <div className="card_body">
                    <div className="card_flex">
                      <span className="api_icon">
                        <ApiIcon />
                      </span>
                      <h4>{api.Name}</h4>
                    </div>
                    <p className="card_labels">
                      <span>{props.lang == "english" ? "Categories" : "Categorías"}:</span>
                      <div className="card_label_values">
                        {api.Categories}
                      </div>
                    </p>
                    <p className="card_labels">
                      <span>{props.lang == "english" ? "Countries" : "Países"}:</span>
                      <div className="card_label_values">
                        {api.Countries}
                      </div>
                    </p>
                    <p>{truncateTextWithWords(api.Description, 110)}</p>
                  </div>
                  <div className={"card_action" + (api.Activated ? " card_action_active" : " card_action_plans") }>
                    {
                      api.Activated ?
                        <>
                          <span>{api.NumberCall}{props.lang == "english" ? " calls remaining" : " llamadas restantes"}</span>
                          <Link to={"/api/" + api.ApiId} className="btn btn_icon">
                            <OpenIcon />
                          </Link>
                        </>
                      :
                        <>
                          <Link to={"/api/" + api.ApiId} className="btn btn_icon">
                            <OpenIcon />
                          </Link>
                          <Link to={"/api/" + api.ApiId} className="btn">
                            <span>{props.lang == "english" ? "Check Plans" : "Comprobar Planes"}</span>
                          </Link>
                        </>
                    }
                  </div>
                </div></div>
              )
          }
        </div>
      </div>
    </div>
  );
}

export default Marketplace;