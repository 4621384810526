import React, {useState, useEffect} from 'react'
import axios from "axios";

import SquareIcon from "../../icons/square";
import CheckboxIcon from "../../icons/checkbox";
import DraftIcon from "../../icons/draft";
import DoneIcon from "../../icons/done";
import ProgressIcon from "../../icons/progress";
import PendingIcon from "../../icons/pending";
import ApiIcon from "../../icons/api";

import "./admin_api_prices.css"

function AdminApiPrices(props) {

    const [apiPrices, setApiPrices] = useState([]);
    const [apiPricesLoad, setApiPricesLoad] = useState(true);

    const [priceInput, setPriceInput] = useState({});

    const [unlimitedPerc, setUnlimitedPerc] = useState(0);

    const handlePriceChange = (value, name) => {
      const priceInputNew = { ...priceInput }
      priceInputNew[name] = value.replace(/\D/g, '');
      setPriceInput(priceInputNew)
    }

    const [updatePricesLoading, setUpdatePricesLoading] = useState(false);
    const updatePrices = () => {

      setUpdatePricesLoading(true)
      axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-prices/update", {
        updateObj: priceInput,
        unlimitedPerc: unlimitedPerc
      })
      .then( async (res) => {

        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-prices")
        .then((res) => {
            setApiPricesLoad(false);
            setApiPrices(res.data.prices)
            setUnlimitedPerc(res.data.unlimitedPerc)
            setUpdatePricesLoading(false)
            setPriceInput({})
          })

      })
      .catch( async (err) => {

        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-prices")
        .then((res) => {
            setApiPricesLoad(false);
            setApiPrices(res.data.prices)
            setUnlimitedPerc(res.data.unlimitedPerc)
            setUpdatePricesLoading(false)
            setPriceInput({})
          })

      })
    }

    useEffect(() => {
        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-prices")
        .then((res) => {
            setApiPricesLoad(false);
            setApiPrices(res.data.prices)
            setUnlimitedPerc(res.data.unlimitedPerc)
        })
    }, []);


  return (
    <>
      {
        !apiPricesLoad ?
        <div className="card_list api_card_list api_prices_container">
          <div className="card_container">
            <div className="card_unlimited_perc">
              <label>Unlimited Pourcentage</label>
              <div className="card">
                <input type="text" value={unlimitedPerc} onChange={(e) => setUnlimitedPerc(e.target.value)}/>
                <span>%</span>
              </div>
            </div>
          </div>
        {
            apiPrices.map(apiPrice => (
                <div className="card_container"><div className="card">
                  <div className="card_body">
                    <div className="card_flex">
                      <span className="api_icon">
                        <ApiIcon />
                      </span>
                      <h4>{apiPrice.Name}</h4>
                    </div>
                    <div className="input_three_flex_column">
                        <div className="input_container">
                            <label>Initial Price</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PriceStripeIdInicial] ? priceInput[apiPrice.PriceStripeIdInicial] : apiPrice.PriceInicial} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PriceStripeIdInicial)}/>
                                <span>MXN</span>
                            </div>
                        </div>
                        <div className="input_container">
                            <label>Medium Price</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PriceStripeIdMedio] ? priceInput[apiPrice.PriceStripeIdMedio] : apiPrice.PriceMedio} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PriceStripeIdMedio)}/>
                                <span>MXN</span>
                            </div>
                        </div>
                        <div className="input_container">
                            <label>Advanced Price</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PriceStripeIdAvanzado] ? priceInput[apiPrice.PriceStripeIdAvanzado] : apiPrice.PriceAvanzado} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PriceStripeIdAvanzado)}/>
                                <span>MXN</span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
        }

        {
          !updatePricesLoading
          ?
          <div className="btn api_prices_btn" onClick={() => updatePrices()}>
            <span>Update Prices</span>
          </div>
          :
          <div className="btn api_prices_btn api_prices_btn_loading">
            <span>Update Prices</span>
          </div>
        }
      </div>
        :
        <>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
        </>
      }

    </>
  )
}

export default AdminApiPrices