import React, { useState, useEffect } from "react";

import "./navbar.css";

import LogoFull from "../../icons/logo_full";
import UserIcon from "../../icons/user";

import GrainGIF from "../../img/grain.gif";

import { Link } from "react-router-dom";
import SupportIcon from "../../icons/support";


function Navbar(props) {

  return (
    <>
      <div className="auth_side_backgorund">
        <div className="auth_side_overlay" style={{backgroundImage: "url('" + GrainGIF + "')"}}></div>
        <div className="auth_side_gradient">
          <div className="auth_side_gradient_circle1"></div>
          <div className="auth_side_gradient_circle2"></div>
          <div className="auth_side_gradient_circle3"></div>
        </div>
      </div> 
      <div className="wrapper">
        <Link to="/" className="logo_container">
          <LogoFull />  
        </Link>        
        <div className="nav_link">
          {/* <Link to="/"><span className={props.navActive == "dashboard" ? 'active' : ''}>Dashboard</span></Link> */}
          <Link to="/" ><span className={props.navActive == "marketplace" ? 'active' : ''}>{props.lang == "english" ? "Marketplace" : "Marketplace"}</span></Link>
          <Link to="/payments" ><span className={props.navActive == "payments" ? 'active' : ''}>{props.lang == "english" ? "Payments" : "Pagos"}</span></Link>
          <Link to="/sandbox" ><span className={props.navActive == "sandbox" ? 'active' : ''}>{props.lang == "english" ? "Sandbox" : "Sandbox"}</span></Link>
          <Link to="/workflow" ><span className={props.navActive == "workflow" ? 'active' : ''}>{props.lang == "english" ? "Workflow" : "Flujo de trabajo"}</span></Link>
        </div>
        <div className="nav_icons">
          <Link to="/contact" className={"user_link" + (props.navActive == "contact" ? ' active' : '')}>
            <SupportIcon />  
          </Link>
          <Link to="/profile" className={"user_link" + (props.navActive == "profile" ? ' active' : '')}>
            <UserIcon />  
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navbar;