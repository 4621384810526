import React from "react";

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M479.754-255Q504-255 519.5-269.754q15.5-14.755 15.5-39Q535-333 519.746-349q-15.255-16-39.5-16Q456-365 440.5-349.018 425-333.035 425-308.789q0 24.245 15.254 39.017Q455.509-255 479.754-255zm3.421-177Q503-432 516.5-445.625 530-459.25 530-479v-176q0-19.75-13.675-33.375Q502.649-702 482.825-702 463-702 449.5-688.375 436-674.75 436-655v176q0 19.75 13.675 33.375Q463.351-432 483.175-432zm-2.771 377q-88.872 0-166.125-33.084-77.254-33.083-135.183-91.012-57.929-57.929-91.012-135.119Q55-391.406 55-480.362q0-88.957 33.084-166.285 33.083-77.328 90.855-134.809 57.772-57.482 135.036-91.013Q391.238-906 480.279-906q89.04 0 166.486 33.454 77.446 33.453 134.853 90.802 57.407 57.349 90.895 134.877Q906-569.34 906-480.266q0 89.01-33.531 166.247-33.531 77.237-91.013 134.86-57.481 57.623-134.831 90.891Q569.276-55 480.404-55z"></path>
    </svg>
  );
}

export default ErrorIcon;
