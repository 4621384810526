import React from "react";

function NotVisibleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M631.826-426.348l-62.653-62.088q23.174-55.738-18.804-90.303-41.978-34.565-85.324-13.826l-58.697-59.261q15.87-9.304 35.174-13.739Q460.826-670 480-670q71 0 120.5 49.5T650-500q0 18.609-4.652 39.261t-13.522 34.391zm139.74 140.87l-48.479-49.044q46.174-35.435 80.413-78.522Q837.739-456.13 856.435-500q-51.131-110.435-150.283-175.217Q607-740 490-740q-42 0-83.739 7.435-41.739 7.435-63.348 17.304l-57.304-57.74q35-16 91.195-28 56.196-12 108.196-12 145.261 0 267.435 83.479Q874.609-646.044 933.001-500q-25.435 65.13-67.283 119.544-41.848 54.413-94.152 94.978zm39.347 226.565L650.826-216.565q-35 14-79.565 21.783-44.565 7.783-91.261 7.783-148.826 0-271.5-83.761T27-500q18.869-51.435 55.217-102.348t86.217-97.609L44.13-823.13l46.522-48.088 763.783 763.783-43.522 48.522zm-591.87-590.565q-36.434 28.13-68.391 69.587Q118.696-538.435 103.13-500q51.566 111 153.218 175.5Q358-260 488-260q29.609 0 59.63-3.435 30.022-3.435 46.022-11.435l-64-64q-9.304 4.435-23.326 6.653Q492.304-330 480-330q-70 0-120-49t-50-121q0-12.739 1.935-26.043 1.935-13.305 5.804-23.609l-98.696-99.826zm315.175 134.087zm-127.87 64.217z"></path>
    </svg>
  );
}

export default NotVisibleIcon;
