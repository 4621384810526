import React, { useState, useEffect } from "react";

import axios from "axios";

import LoadingIcon from "../../icons/loading";
import SuccessIcon from "../../icons/success";
import ErrorIcon from "../../icons/error";

import VisbleIcon from "../../icons/visible";
import NotVisibleIcon from "../../icons/not_visible";

function ProfilePassword(props) {

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);

  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(false);

  const [updatePassworBtn, setUpdatePassworBtn] = useState("default"); // default - loading - success - error

  const [passwordResetFunctionError, setPasswordResetFunctionError] = useState(""); // updateError - passwordNotMatch - catchError
  
  const [passwordRequirements, setPasswordRequirements] = useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    specialCharacter: false,
    characterLength: false
  });

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    setCurrentPasswordError(false);
    if (["success","error"].includes(updatePassworBtn)) {
      setUpdatePassworBtn("default");
    }
  }

  const handleNewPassword = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    setNewPasswordError(false);
    
    const hasLowerCase = /[a-z]/.test(newPasswordValue);
    const hasUpperCase = /[A-Z]/.test(newPasswordValue);
    const hasNumber = /\d/.test(newPasswordValue);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-="']/.test(newPasswordValue);
    const hasValidLength = newPasswordValue.length >= 8;

    setPasswordRequirements({
      lowerCase: hasLowerCase,
      upperCase: hasUpperCase,
      number: hasNumber,
      specialCharacter: hasSpecialCharacter,
      characterLength: hasValidLength
    });

    if (["success","error"].includes(updatePassworBtn)) {
      setUpdatePassworBtn("default");
    }
  }

  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
    setConfirmNewPasswordError(false);
    if (["success","error"].includes(updatePassworBtn)) {
      setUpdatePassworBtn("default");
    }
  }

  const handleEnterPress = (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        passwordResetFunction();
      }
  }

  const passwordResetFunction = () => {
    const allRequirementsMet = Object.values(passwordRequirements).every(Boolean);

    let checkErrors = true;

    if (currentPassword.length == 0) {
      setCurrentPasswordError(true);
      checkErrors = false;
    }

    if (!allRequirementsMet) {
      setNewPasswordError(true);
      checkErrors = false;
    }

    if (confirmNewPassword.length == 0 || confirmNewPassword != newPassword) {
      setConfirmNewPasswordError(true);
      checkErrors = false;
    }

    if (checkErrors) {
      try {
        const passwords = {
          currentPassword: currentPassword,
          newPassword: newPassword,
          clientId: props.clientId
        };

        setUpdatePassworBtn("loading");
  
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/profile/update-password`,
          passwords
        )
          .then(res => {
            console.log(res.data);
            if (res.data == "success") {
                setUpdatePassworBtn("success");
            } else if (res.data == "updateError") {
              setUpdatePassworBtn("error");
              setPasswordResetFunctionError("updateError");
            } else if (res.data == "passwordNotMatch") {
              setUpdatePassworBtn("error");
              setPasswordResetFunctionError("passwordNotMatch");
            }
          })
      } catch (error) {
        setUpdatePassworBtn("error");
        setPasswordResetFunctionError("catchError");
      }
    }


  }

  return (
    <div className="wrapper">
      <h1 className="wrapper_titler">{props.lang == "english" ? "Password" : "Contraseña"}</h1>
      <p>{props.lang == "english" ? "Please enter your current password to change your password." : "Introduzca su contraseña actual para cambiarla."}</p>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Current password" : "Contraseña actual"}</label>
        </div>
        <div className="input_side">
        <div className={"input_container" + (currentPasswordError ? " input_container_error" : "")}>
            <input type={currentPasswordVisible ? "text" :"password"} onKeyUp={(e) => handleEnterPress(e)} value={currentPassword} onChange={(e) => handleCurrentPassword(e)}/>
            {
              currentPasswordVisible
              ? <div className="password_visible_btn disable_select" onClick={() => setCurrentPasswordVisible(false)}><NotVisibleIcon /></div>
              : <div className="password_visible_btn disable_select" onClick={() => setCurrentPasswordVisible(true)}><VisbleIcon /></div>
            }
          </div>
        </div>
      </div>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "New password" : "Nueva contraseña"}</label>
        </div>
        <div className="input_side">
        <div className={"input_container" + (newPasswordError ? " input_container_error" : "")}>
          <input type={newPasswordVisible ? "text" :"password"} onKeyUp={(e) => handleEnterPress(e)} value={newPassword} onChange={(e) => handleNewPassword(e)}/>
          {
              newPasswordVisible
              ? <div className="password_visible_btn disable_select" onClick={() => setNewPasswordVisible(false)}><NotVisibleIcon /></div>
              : <div className="password_visible_btn disable_select" onClick={() => setNewPasswordVisible(true)}><VisbleIcon /></div>
            }
        </div>
          <div className="password_verification">
            <div className={"password_verification_item" + ( passwordRequirements.lowerCase ? " password_verification_valid" : "")}>
              <div className="password_verification_icon_wrapper">
                <div className="password_verification_icon"></div>
              </div>
              <span>{props.lang == "english" ? "one lowercase letter" : "una letra minúscula"}</span>
            </div>
            <div className={"password_verification_item" + ( passwordRequirements.upperCase ? " password_verification_valid" : "")}>
            <div className="password_verification_icon_wrapper">
                <div className="password_verification_icon"></div>
              </div>
              <span>{props.lang == "english" ? "one uppercase letter" : "una letra mayúscula"}</span>
            </div>
            <div className={"password_verification_item" + ( passwordRequirements.number ? " password_verification_valid" : "")}>
            <div className="password_verification_icon_wrapper">
                <div className="password_verification_icon"></div>
              </div>
              <span>{props.lang == "english" ? "one number" : "un número"}</span>
            </div>
            <div className={"password_verification_item" + ( passwordRequirements.specialCharacter ? " password_verification_valid" : "")}>
            <div className="password_verification_icon_wrapper">
                <div className="password_verification_icon"></div>
              </div>
              <span>{props.lang == "english" ? "one special character" : "un carácter especial"}</span>
            </div>
            <div className={"password_verification_item" + ( passwordRequirements.characterLength ? " password_verification_valid" : "")}>
            <div className="password_verification_icon_wrapper">
                <div className="password_verification_icon"></div>
              </div>
              <span>{props.lang == "english" ? "8 character minimum" : "8 caracteres mínimo"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Confirm new password" : "Confirmar nueva contraseña"}</label>
        </div>
        <div className="input_side">
          <div className={"input_container" + (confirmNewPasswordError ? " input_container_error" : "")}>
            <input type={confirmNewPasswordVisible ? "text" :"password"} onKeyUp={(e) => handleEnterPress(e)} value={confirmNewPassword} onChange={(e) => handleConfirmNewPassword(e)}/>
            {
              confirmNewPasswordVisible
              ? <div className="password_visible_btn disable_select" onClick={() => setConfirmNewPasswordVisible(false)}><NotVisibleIcon /></div>
              : <div className="password_visible_btn disable_select" onClick={() => setConfirmNewPasswordVisible(true)}><VisbleIcon /></div>
            }
          </div>
        </div>
      </div>
      <div className="two_side_submit">
        {
            updatePassworBtn != "default" &&
                <div className={"btn btn_indicator btn_primary" + (
                    updatePassworBtn == "loading" ? " btn_loading btn_icon":
                    updatePassworBtn == "success" ? " btn_success btn_icon" :
                    " btn_error"
                )}>
                    {
                        updatePassworBtn == "loading" ? <LoadingIcon /> :
                        updatePassworBtn == "success" ? <SuccessIcon /> :
                        <ErrorIcon />
                    }
                    {
                      updatePassworBtn == "error" ? (
                        passwordResetFunctionError == "passwordNotMatch"
                        ? <span>{props.lang == "english" ? "Passwords don't match. Please check again." : "Las contraseñas no coinciden. Por favor, compruébelo de nuevo."}</span>
                        : <span>{props.lang == "english" ? "An error occurred. Please try again later." : "Se ha producido un error. Vuelva a intentarlo más tarde."}</span>  
                      ) : <></>
                    }
                </div>
        }
        <div className={"btn btn_primary" + (updatePassworBtn == "loading" ? " btn_loading" : "")} onClick={() => updatePassworBtn != "loading" ? passwordResetFunction() : null}>
          <span>{props.lang == "english" ? "Update password" : "Modificar contraseña"}</span>
        </div>
      </div>
    </div>
  );
}

export default ProfilePassword;