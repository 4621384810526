import React, { useState, useEffect } from "react";
import { CodeBlock, monokai } from "react-code-blocks";
import beautify from 'json-beautify';
import { Link } from "react-router-dom";
import InboxIcon from "../../icons/inbox";
import DropRight from "../../icons/drop_right";
import DropDown from "../../icons/drop_down";


import axios from "axios";

import "./api.css";

function ApiDocumentation(props) {

  const [dateArchived, setDateArchived] = useState([]);

    useEffect(() => {
        try {

            const getLogs = {
              clientId: props.clientId,
              apiId: props.apiId
            }
    
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/logs`,
            getLogs
          )
          .then(res => {
            console.log(
                res.data.reduce((grouped, item) => {
                    const date = item.CreationDate.slice(0, 10); // Extracting date part
                    if (!grouped[date]) {
                      grouped[date] = [];
                    }
                    grouped[date].push(item);
                    return grouped;
                  }, {})
            );
            props.setlogs(
                res.data.reduce((grouped, item) => {
                    const date = item.CreationDate.slice(0, 10); // Extracting date part
                    if (!grouped[date]) {
                      grouped[date] = [];
                    }
                    grouped[date].push(item);
                    return grouped;
                  }, {})
            );
            props.setLogsLoad(false);
          })
        } catch (error) {}  
      }, []);
    
    const extractTimeFromDate = (dateTimeString) => {
        const dateObj = new Date(dateTimeString);
        const hours = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes();
        const seconds = dateObj.getUTCSeconds();
      
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }   

    const formatDate = (dateString) => {
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const monthsSpanish = [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
      
        const date = new Date(dateString);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(yesterday.getDate() - 1);
      
        if (date.toDateString() === currentDate.toDateString()) {
          return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
          return 'Yesterday';
        } else {
          const day = date.getDate();
          const month = props.lang == "english" ? months[date.getMonth()] : monthsSpanish[date.getMonth()];
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        }
      } 

      const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
      
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }

    const loadLog = ( apiLogId, endPointId, endPointName ) => {
        props.setSelectedLogId({apiLogId: apiLogId, endPointId: endPointId, endPointName: endPointName});
        props.setSelectedLog({});
        props.setSelectedLogLoad(true);
        props.setIsSelectedLogEmpty(false)
        try {

            const getLogDetails = {
              clientId: props.clientId,
              apiLogId: apiLogId
            }
    
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/logs/details`,
            getLogDetails
          )
          .then(res => {
            console.log(res.data[0]);
            props.setSelectedLog(res.data[0]);
            props.setSelectedLogLoad(false);
          })
        } catch (error) {}  
    }

    const formatJson = (jsonString) => {
        try {
          const parsedJson = JSON.parse(jsonString);
          const formattedJson = beautify(parsedJson, null, 2, 80);
          return formattedJson;
        } catch (error) {
          return jsonString;
        }
      }

    const handleDateArchived = (date) => {
      if (dateArchived.includes(date)) {
        setDateArchived(
          dateArchived.filter(dates => dates != date)
        )
      } else {
        setDateArchived([...dateArchived, date])
      }
    }

  return (

    <div className="flex_content" id="flex_log">
        <div className="flex_right_side">
            <div className="logs_list">
            {   
                props.logsLoad
                ?
                <>
                  <div className="api_log_date api_log_date_skeleton"><span></span></div>
                  <div className="api_log api_log_skeleton">
                    <span className="api_log_status"></span>
                    <div className="api_log_endpoint"></div>
                    <span className="api_log_time"></span>
                    <span className="api_log_length"></span>
                  </div>
                  <div className="api_log api_log_skeleton">
                    <span className="api_log_status"></span>
                    <div className="api_log_endpoint"></div>
                    <span className="api_log_time"></span>
                    <span className="api_log_length"></span>
                  </div>
                  <div className="api_log api_log_skeleton">
                    <span className="api_log_status"></span>
                    <div className="api_log_endpoint"></div>
                    <span className="api_log_time"></span>
                    <span className="api_log_length"></span>
                  </div>
                  <div className="api_log_date api_log_date_skeleton"><span></span></div>
                  <div className="api_log api_log_skeleton">
                    <span className="api_log_status"></span>
                    <div className="api_log_endpoint"></div>
                    <span className="api_log_time"></span>
                    <span className="api_log_length"></span>
                  </div>
                  <div className="api_log api_log_skeleton">
                    <span className="api_log_status"></span>
                    <div className="api_log_endpoint"></div>
                    <span className="api_log_time"></span>
                    <span className="api_log_length"></span>
                  </div>
                </>
                :
                Object.keys(props.logs).length == 0
                ?
                <div className="history_empty_container">
                    <div className="plan_empty_icon">
                      <InboxIcon />
                    </div>
                    <h3>No Logs Found</h3>
                    <p>You haven't sent a request using this API. Try it out using our Sandbox.</p>
                    <Link className="btn btn_primary" to="/sandbox">
                      <span>Sandbox</span>
                    </Link>
                  </div>
                :
                Object.keys(props.logs).map(logDate => 
                    <>
                    <div className="api_log_date" onClick={() => handleDateArchived(logDate)}>
                      {
                        dateArchived.includes(logDate)
                        ?
                        <DropRight />
                        :
                        <DropDown />
                      }
                      <span>{formatDate(logDate)}</span>
                    </div>
                    {props.logs[logDate].map(log =>
                    <a href="#flex_log" className={"api_log" + (dateArchived.includes(logDate) ? " api_log_hide" : "")} onClick={() => loadLog(log.ApiLogId, log.EndPointId, log.Name)}>
                        <span className={log.Status == "200 OK" ? "api_log_status" : "api_log_status api_log_status_err"}>{log.Status == "200 OK" ? "200 OK" : "ERROR"}</span>
                        <div className="api_log_endpoint">{log.Name}</div>
                        <span className="api_log_time">{extractTimeFromDate(log.CreationDate)}</span>
                        <span className="api_log_length">{log.Latency} ms</span>
                    </a>)}
                    </>
                )
            }
            </div>
        </div>
        <div className="flex_left_side">
            {
                props.isSelectedLogEmpty
                ?
                <></>
                :
                <>
                <h6 className="api_log_subtite">{props.lang == "english" ? "Request Id" : "ID de la Petición"}</h6>
                <h1 className="api_log_title">{props.selectedLogId.apiLogId}</h1>
                <div className="api_log_details">
                  <div className="api_log_detail">
                      <span>{props.lang == "english" ? "API ID" : "ID de la API"}</span>
                      <span className="api_log_detail_value">{props.apiId}</span>
                  </div>
                  <div className="api_log_detail">
                      <span>{props.lang == "english" ? "API title" : "Título de la API"}</span>
                      <span className="api_log_detail_value">{props.apiName}</span>
                  </div>
                  <div className="api_log_detail">
                      <span>{props.lang == "english" ? "Endpoint ID" : "ID del Endpoint"}</span>
                      <span className="api_log_detail_value">{props.selectedLogId.endPointId}</span>
                  </div>
                  <div className="api_log_detail">
                      <span>{props.lang == "english" ? "Endpoint title" : "Título del Endpoint"}</span>
                      <span className="api_log_detail_value">{props.selectedLogId.endPointName}</span>
                  </div>
                {
                    props.selectedLogLoad
                    ?
                    <>
                      <div className="api_log_detail api_log_detail_skeleton">
                        <span>{props.lang == "english" ? "Status" : "Estado"}</span>
                        <span className="api_log_status"></span>
                      </div>
                      <div className="api_log_detail api_log_detail_skeleton">
                        <span>{props.lang == "english" ? "Date and time" : "Fecha y hora"}</span>
                        <span className="api_log_detail_value"></span>
                      </div>
                      <div className="api_log_detail api_log_detail_skeleton">
                        <span>{props.lang == "english" ? "Duration" : "Duración"}</span>
                        <span className="api_log_detail_value"></span>
                      </div>
                      <div className="api_log_detail api_log_detail_vert api_log_detail_skeleton">
                          <span>{props.lang == "english" ? "Request Body" : "Cuerpo de la Petición"}</span>
                          <div className="api_log_param">
                            <div className="api_log_detail">
                                <span className="api_log_deatil_name"></span>
                                <span className="api_log_detail_value"></span>
                            </div>
                            <div className="api_log_detail">
                                <span className="api_log_deatil_name"></span>
                                <span className="api_log_detail_value"></span>
                            </div>
                          </div>
                      </div>
                      <div className="api_log_detail api_log_detail_vert api_log_detail_skeleton">
                        <span>{props.lang == "english" ? "Request Response" : "Respuesta de la Petición"}</span>
                        <div className="api_code api_code_skeleton"></div>
                      </div>
                    </>
                    :
                    <>
                            <div className="api_log_detail">
                                <span>{props.lang == "english" ? "Status" : "Estado"}</span>
                                <span className={props.selectedLog.Status == "200 OK" ? "api_log_status" : "api_log_status api_log_status_err"}>{props.selectedLog.Status}</span>
                            </div>
                            <div className="api_log_detail">
                                <span>{props.lang == "english" ? "Date and time" : "Fecha y hora"}</span>
                                <span className="api_log_detail_value">{formatDateTime(props.selectedLog.CreationDate)}</span>
                            </div>
                            <div className="api_log_detail">
                                <span>{props.lang == "english" ? "Duration" : "Duración"}</span>
                                <span className="api_log_detail_value">{props.selectedLog.Latency} ms</span>
                            </div>
                            <div className="api_log_detail api_log_detail_vert">
                                <span>{props.lang == "english" ? "Request Body" : "Cuerpo de la Petición"}</span>
                                <div className="api_log_param">
                                    {
                                        JSON.parse(props.selectedLog.JsonRequest).map(req =>
                                        <div className="api_log_detail">
                                            <span>{req.name}</span>
                                            <span className="api_log_detail_value">{req.value}</span>
                                        </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="api_log_detail api_log_detail_vert">
                                <span>Request Response</span>
                                <div className="api_code">
                    <div className="api_code_background">
                        <CodeBlock
                        text={formatJson(props.selectedLog.JsonResponse)}
                        language='json'
                        showLineNumbers={true}
                        theme={monokai}
                        wrapLines={true}
                        
                        showInlineLineNumbers={false}
                        wrapLongLines={true}
                        lineProps={{ style: { flexWrap: 'wrap' } }}
                        />
                    </div>
                    </div>
                            </div>
                    </>
                }
                </div>
                    </>
            }
        </div>
    </div>

  );
}

export default ApiDocumentation;