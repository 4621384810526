import React from "react";

function PasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M225.087-67q-32.677 0-55.948-23.27-23.27-23.27-23.27-55.948v-423.825q0-32.911 23.27-56.347 23.271-23.437 55.948-23.437h58.695v-83.564q0-82.434 57.166-140.174 57.166-57.74 139.022-57.74t139.052 57.74q57.196 57.74 57.196 140.174v83.564h58.695q32.91 0 56.347 23.437 23.436 23.436 23.436 56.347v423.825q0 32.678-23.436 55.948Q767.823-67 734.913-67H225.087zm0-79.218h509.826v-423.825H225.087v423.825zM480.168-281.13q31.832 0 54.332-22.032Q557-325.193 557-356.13q0-30-22.668-54.5t-54.5-24.5q-31.832 0-54.332 24.5t-22.5 55q0 30.5 22.668 52.5t54.5 22zM363.001-649.827h233.998v-83.434q0-49.964-33.792-84.112-33.793-34.148-82.956-34.148-49.164 0-83.207 34.148-34.043 34.148-34.043 84.112v83.434zM225.087-146.218v-423.825 423.825z"></path>
    </svg>
  );
}

export default PasswordIcon;
