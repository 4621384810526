import React, { useState, useEffect } from "react";

import Navbar from "../Navbar/Navbar";

import "./payments.css";
import PaymentsPlans from "./PaymentsPlans";
import PaymentsHistory from "./PaymentsHistory";

import ReactGA from "react-ga4";

function Payments(props) {

  const [tabHeader, setTabHeader] = useState("active"); // active - canceled - history

  const [availablePlans, setAvailablePlans] = useState([]);
  const [availablePlansLoad, setAvailablePlansLoad] = useState(true);
  
  const [historyList, setHistoryList] = useState([]);
  const [historyListLoad, setHistoryListLoad] = useState(true);

  useEffect(() => {
    ReactGA.send({ hitType: "pageView", page: "/payments", title: "Payments" });
  })

  return (
    <div className="body" style={{background: "#fcfcfe"}}>
      <div className="navbar">
        <Navbar navActive="payments" lang={props.lang} />
        
        <div className="title_search">
          <div className="title_search_info">
            <h1>{props.lang == "english" ? "Payments" : "Pagos"}</h1>
            <p>{props.lang == "english" ? "Choose the perfect plan for your needs and stay connected. View your active and inactive plans, along with recent payment history below. We make managing your subscriptions easy." : "Elige el plan perfecto para tus necesidades y manténte conectado. A continuación podrás consultar tus planes activos e inactivos, junto con tu historial de pagos recientes. Te facilitamos la gestión de tus suscripciones."}</p>
            <div className="tabs tabs_page_header">
              <span className={"tab" + (tabHeader == "active" ? " active" : "")} onClick={() => setTabHeader("active")}>{props.lang == "english" ? "Active plans" : "Planes activos"}</span>
              <span className={"tab" + (tabHeader == "canceled" ? " active" : "")} onClick={() => setTabHeader("canceled")}>{props.lang == "english" ? "Inactive plans" : "Planes inactivos"}</span>
              <span className={"tab" + (tabHeader == "history" ? " active" : "")} onClick={() => setTabHeader("history")}>{props.lang == "english" ? "Recent movements" : "Movimientos recientes"}</span>
              <div className="tab_controll" style={
                props.lang == "english" ?
                (
                  tabHeader == "active" ? {left: 4.5} :
                  tabHeader == "canceled" ? {left: 140} :
                  {left: 300}  
                )
                :
                (
                  tabHeader == "active" ? {left: 11} :
                  tabHeader == "canceled" ? {left: 161} :
                  {left: 338}  
                )
              }></div>
            </div>
          </div>
        </div>
      </div>
        {
          tabHeader == "history"
          ?
          <PaymentsHistory
            clientId={props.clientId}
            historyList={historyList}
            setHistoryList={setHistoryList}
            historyListLoad={historyListLoad}
            setHistoryListLoad={setHistoryListLoad}
            lang={props.lang}
          />
          :
          <div className="wrapper">
            <PaymentsPlans
              tabHeader={tabHeader}
              setTabHeader={setTabHeader}
              clientId={props.clientId}
              availablePlans={availablePlans}
              setAvailablePlans={setAvailablePlans}
              availablePlansLoad={availablePlansLoad}
              setAvailablePlansLoad={setAvailablePlansLoad}
              lang={props.lang}
            />
          </div>  
        }
    </div>
  );
}

export default Payments;