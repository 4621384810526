import React, {useState, useEffect} from 'react'
import axios from "axios";

import Search from "../../icons/search";

import "./admin_api_balances.css"

function AdminApiBalances(props) {

    const [apiBalances, setApiBalances] = useState([]);
    const [apiBalancesLoad, setApiBalancesLoad] = useState(true);

    const [balanceInput, setBalanceInput] = useState({});

    const [searchEmail, setSearchEmail] = useState("");

    const handleBalanceChange = (value, id) => {
        console.log(value, id)
      const balanceInputNew = { ...balanceInput }
      balanceInputNew[id] = value.replace(/\D/g, '');
      setBalanceInput(balanceInputNew)
    }

    const [updateBalancesLoading, setUpdateBalancesLoading] = useState(false);
    const updateBalances = () => {

        setUpdateBalancesLoading(true)
      axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-balances/update", {
        updateObj: balanceInput
      })
      .then( async (res) => {

        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-balances")
        .then((res) => {
            setApiBalancesLoad(false);
            setApiBalances(res.data.calls)
            setUpdateBalancesLoading(false)
            setBalanceInput({})
          })

      })
      .catch( async (err) => {

        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-balances")
        .then((res) => {
            setApiBalancesLoad(false);
            setApiBalances(res.data.calls)
            setUpdateBalancesLoading(false)
            setBalanceInput({})
          })

      })
    }

    useEffect(() => {
        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-balances")
        .then((res) => {
            setApiBalancesLoad(false);
            setApiBalances(res.data.calls)
        })
    }, []);


  return (
<>
      {
        !apiBalancesLoad ?
        <div className="card_list api_card_list api_prices_container api_balances_container">
        <table className="table_balances">
            <tr className="table_balances_row">
                <th className="table_balances_item table_balances_item_4">Email</th>
                <th className="table_balances_item table_balances_item_4">Full Name</th>
                <th className="table_balances_api_container">
                    <table>
                        <tr className="table_balances_apis">
                            <td className="table_balances_item table_balances_item_2_4">API Name</td>
                            <td className="table_balances_item"><span>Balance</span></td>
                        </tr>
                    </table>
                </th>
            </tr>
        {
            apiBalances
            .filter(apiBalance =>
                apiBalance.Email.toLowerCase().includes(searchEmail.toLowerCase())
            )
            .map(apiBalance => (
                <tr className="table_balances_row">
                    <td className="table_balances_item table_balances_item_4">{apiBalance.Email}</td>
                    <td className="table_balances_item table_balances_item_4">{apiBalance.Name + " " + (apiBalance.SecondLastname ? apiBalance.SecondLastname + " " : "") + apiBalance.FirstLastname}</td>
                    <td className="table_balances_api_container">
                        <table>
                            {
                                apiBalance.Apis.map(apiBalanceApi => (
                                    <tr className="table_balances_apis">
                                        <td className="table_balances_item table_balances_item_2_4">{apiBalanceApi.ApiName}</td>
                                        <td className="table_balances_item">
                                            <div className="table_balances_item_input">
                                               <input type="text" onChange={(e) => handleBalanceChange(e.target.value, apiBalanceApi.CallApiClientId)} value={ balanceInput[apiBalanceApi.CallApiClientId] ? balanceInput[apiBalanceApi.CallApiClientId] : apiBalanceApi.NumberCall}/>
                                               <div className="input_bar_focus"></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </table>
                    </td>
                </tr>
            ))
        }
        </table>
        {
          !updateBalancesLoading
          ?
          <div className="btn api_prices_btn" onClick={() => updateBalances()}>
            <span>Update Balances</span>
          </div>
          :
          <div className="btn api_prices_btn api_prices_btn_loading">
            <span>Update Balances</span>
          </div>
        }
        <div className="api_balances_input_search api_balances_input_search_left">
            <Search />
            <input className="api_balances_search" type="text" placeholder="Search by email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)}/>
        </div>
      </div>
        :
        <>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
        </>
      }

    </>
  )
}

export default AdminApiBalances