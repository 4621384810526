import CheckIcon from "../../../icons/check"
import LoadingIcon from "../../../icons/loading";
import SuccessIcon from "../../../icons/success";
import ErrorIcon from "../../../icons/error";

function Step5(props) {

    const handleEndpointData = (index, path, value) => {
        const newEndpoints = [...props.endpoints];
        newEndpoints[index] = {
          ...newEndpoints[index],
          [path]: value,
        };
        props.setEndpoints(newEndpoints);
    }

    const handleEndpointParam = (index, endpointIndex, path, value) => {
        const newEndpoints = [...props.endpoints];
        const endpointParams = [...newEndpoints[endpointIndex].parameters];
        endpointParams[index] = {
          ...endpointParams[index],
          [path]: value,
        };
        newEndpoints[endpointIndex] = {
          ...newEndpoints[endpointIndex],
          parameters: endpointParams,
        };
        props.setEndpoints(newEndpoints);
    }

    const addParameter = (endpointIndex) => {
        const newParameter = {
          name: "",
          description: "",
          type: "PATH",
          dataType: "String",
          required: false,
          isArray: false,
        };
        const newEndpoints = [...props.endpoints];
        if (!newEndpoints[endpointIndex].parameters) {
          newEndpoints[endpointIndex].parameters = [newParameter];
        } else {
          newEndpoints[endpointIndex].parameters.push(newParameter);
        }      
        props.setEndpoints(newEndpoints);
    };

    const removeParameter = (endpointIndex, parameterIndex) => {
        const newEndpoints = [...props.endpoints];
        if (newEndpoints[endpointIndex].parameters) {
          newEndpoints[endpointIndex].parameters.splice(parameterIndex, 1);
          props.setEndpoints(newEndpoints);
        }
    };

    const addEndpoint = () => {
        const newEndpoint = {
          name: "",
          description: "",
          path: "",
          httpmethod: "GET",
          parameters: [
            {
              name: "",
              description: "",
              type: "PATH",
              dataType: "String",
              required: false,
              isArray: false,
            },
          ],
        };
        const newEndpoints = [...props.endpoints];
        newEndpoints.push(newEndpoint);
        props.setEndpoints(newEndpoints);
    };

    const removeEndpoint = (index) => {
        const newEndpoints = [...props.endpoints];
        newEndpoints.splice(index, 1);
        props.setEndpoints(newEndpoints);
    };

    return (
        <div className="add_new_api_container">
            {
                props.endpoints.map((endpoint, endpointIndex) => (
                <div className="card add_api_card">
                    <h3>Endpoint information</h3>
                    <div className="add_api_endpoint_flex">
                        <div className="input_container">
                            <label>HTTP Method</label>
                            <select onChange={(e) => handleEndpointData(endpointIndex, "httpmethod", e.target.value)}>
                                {endpoint.httpmethod == "GET" ? <option value="GET" selected>GET</option> : <option value="GET">GET</option>}
                                {endpoint.httpmethod == "POST" ? <option value="POST" selected>POST</option> : <option value="POST">POST</option>}
                                {endpoint.httpmethod == "PUT" ? <option value="PUT" selected>PUT</option> : <option value="PUT">PUT</option>}
                                {endpoint.httpmethod == "DELETE" ? <option value="DELETE" selected>DELETE</option> : <option value="DELETE">DELETE</option>}
                            </select>
                        </div>    
                        <div className="input_container">
                            <label>Name</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={endpoint.name} onChange={(e) => handleEndpointData(endpointIndex, "name", e.target.value)}/>
                                {
                                    props.endpoints.length != 1
                                    &&
                                    <div className="btn btn_error btn_primary" onClick={() => removeEndpoint(endpointIndex)}>
                                        <span>Delete</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div> 
                    <div className="input_container">
                        <label>Description</label>
                        <textarea value={endpoint.description} onChange={(e) => handleEndpointData(endpointIndex, "description", e.target.value)}/>
                    </div>
                    <div className="input_container">
                        <label>Path</label>
                        <input type="text" value={endpoint.path} onChange={(e) => handleEndpointData(endpointIndex, "path", e.target.value)}/>
                    </div>
                    <h3>Parameters</h3>
                    {   
                        endpoint.parameters.map((param, paramIndex) => (
                        <div className="add_api_parameters_container">
                            <div className="input_container">
                                <label>Name</label>
                                <div className="input_container_inside_flex">
                                    <input type="text" value={param.name} onChange={(e) => handleEndpointParam(paramIndex, endpointIndex, "name", e.target.value)}/>
                                    {
                                        endpoint.parameters.length != 1
                                        &&
                                        <div className="btn btn_error btn_primary" onClick={() => removeParameter(endpointIndex)}>
                                            <span>Delete</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="input_container">
                                <label>Description</label>
                                <textarea value={param.description} onChange={(e) => handleEndpointParam(paramIndex, endpointIndex, "description", e.target.value)}/>
                            </div>
                            <div className="add_api_param_flex">
                                <div className="input_container">
                                    <label>Type</label>
                                    <select onChange={(e) => handleEndpointParam(paramIndex, endpointIndex, "type", e.target.value)}>
                                        {endpoint.type == "PATH" ? <option value="PATH" selected>PATH</option> : <option value="PATH">PATH</option>}
                                        {endpoint.type == "HEADER" ? <option value="HEADER" selected>HEADER</option> : <option value="HEADER">HEADER</option>}
                                        {endpoint.type == "BODY" ? <option value="BODY" selected>BODY</option> : <option value="BODY">BODY</option>}
                                        {endpoint.type == "PARAM" ? <option value="PARAM" selected>PARAM</option> : <option value="PARAM">PARAM</option>}
                                    </select>
                                </div>
                                <div className="input_container">
                                    <label>DataType</label>
                                    <select onChange={(e) => handleEndpointParam(paramIndex, endpointIndex, "dataType", e.target.value)}>
                                        {endpoint.type == "String" ? <option value="String" selected>String</option> : <option value="String">String</option>}
                                        {endpoint.type == "Integer" ? <option value="Integer" selected>Integer</option> : <option value="Integer">Integer</option>}
                                        {endpoint.type == "Long" ? <option value="Long" selected>Long</option> : <option value="Long">Long</option>}
                                        {endpoint.type == "Boolean" ? <option value="Boolean" selected>Boolean</option> : <option value="Boolean">Boolean</option>}
                                        {endpoint.type == "Double" ? <option value="Double" selected>Double</option> : <option value="Double">Double</option>}
                                        {endpoint.type == "Json" ? <option value="Json" selected>Json</option> : <option value="Json">Json</option>}
                                    </select>
                                </div>
                                <div className="input_container">
                                    <label></label>
                                    <div className={"input_checkbox_container" + (param.required ? " active" : "")} onClick={() => handleEndpointParam(paramIndex, endpointIndex, "required", !param.required)}>
                                        <div className="input_radio">
                                            { param.required && <CheckIcon /> }
                                        </div>
                                        <span>Required</span>
                                    </div>           
                                </div>
                                <div className="input_container">
                                    <label></label>
                                    <div className={"input_checkbox_container" + (param.isArray ? " active" : "")} onClick={() => handleEndpointParam(paramIndex, endpointIndex, "isArray", !param.isArray)}>
                                        <div className="input_radio">
                                            { param.isArray && <CheckIcon /> }
                                        </div>
                                        <span>IsArray</span>
                                    </div>           
                                </div>
                            </div>
                        </div>
                        ))
                    }
                    <div className="btn btn_link" onClick={() => addParameter(endpointIndex)}>
                        <span>Add new parameter</span>
                    </div>
                </div>
                ))
            }
            <div className="btn btn_link" onClick={() => addEndpoint()}>
                <span>Add new endpoint</span>
            </div>
            <div className="add_api_footer_action">
                <div className="btn" onClick={() => props.handleBack()}>
                    <span>Back</span>
                </div>
                {
                    props.addApiError != "default" &&
                        <div className={"btn btn_icon btn_indicator btn_primary" + (
                            props.addApiError == "loading" ? " btn_loading":
                            props.addApiError == "success" ? " btn_success" :
                            " btn_error"
                        )}>
                            {
                                props.addApiError == "loading" ? <LoadingIcon /> :
                                props.addApiError == "success" ? <SuccessIcon /> :
                                <ErrorIcon />
                            }
                        </div>
                }
                <div className={"btn btn_primary" + (["loading"].includes(props.addApiError) ? " btn_loading" : "")} onClick={() => ["default", "success"].includes(props.addApiError) ? props.handleNext() : null}>
                    <span>Create API</span>
                </div>
            </div>
        </div>
    );
}

export default Step5;