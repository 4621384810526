import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M378-222L130-470l68-68 180 180 383-383 68 68-451 451z"></path>
    </svg>
  );
}

export default CheckIcon;
