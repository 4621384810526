import React, { useState, useEffect } from "react";

import axios from "axios";
import HistoryIcon from "../../icons/history";
import { Link } from "react-router-dom";

import "./payments.css";

function PaymentsHistory(props) {

  useEffect(() => {
    if (props.historyListLoad) {
      try {
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/payments/history`,
          {clientId: props.clientId}
        )
        .then(res => {
          console.log(
            res.data.reduce((grouped, item) => {
              const date = item.TransactionDate.slice(0, 10);
              if (!grouped[date]) {
                grouped[date] = [];
              }
              grouped[date].push(item);
              return grouped;
            }, {})
          );
          props.setHistoryList(
            res.data.reduce((grouped, item) => {
              const date = item.TransactionDate.slice(0, 10);
              if (!grouped[date]) {
                grouped[date] = [];
              }
              grouped[date].push(item);
              return grouped;
            }, {})
          );
          props.setHistoryListLoad(false);
        })
      } catch (error) {}    
    }
  }, []);

  const formatDate = (dateString) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
      
    const monthsSpanish = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
  
    const date = new Date(dateString);
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(yesterday.getDate() - 1);
  
    if (date.toDateString() === currentDate.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      const day = date.getDate();
      const month = props.lang == "english" ? months[date.getMonth()] : monthsSpanish[date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    }
  } 
    
  const extractTimeFromDate = (dateTimeString) => {
      const dateObj = new Date(dateTimeString);
      const hours = dateObj.getUTCHours();
      const minutes = dateObj.getUTCMinutes();
    
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
      return `${formattedHours}:${formattedMinutes}`;
  }   

  const formatSentences = (sentence) => {
    // Check if the sentence matches the first type
    const matchType1 = sentence.match(/Has consumido una llamada gratuita del endpoint (.+?) de tu plan (.+)$/);

    // Check if the sentence matches the second type
    const matchType2 = sentence.match(/Te has suscrito al plan gratuito del API (.+)$/);

    if (matchType1 && matchType1.length === 3) {
      // Type 1 sentence
      const changingWord1 = matchType1[1];
      const changingWord2 = matchType1[2];
      const formattedSentence = (
        <div>
          Has consumido una llamada gratuita del endpoint <span>{changingWord1}</span> de tu plan <span>{changingWord2}</span>
        </div>
      );
      return formattedSentence;
    } else if (matchType2 && matchType2.length === 2) {
      // Type 2 sentence
      const changingWord = matchType2[1];
      const formattedSentence = (
        <div>
          Te has suscrito al plan gratuito del API <span>{changingWord}</span>
        </div>
      );
      return formattedSentence;
    }

    // If the sentence doesn't match either type, return it as is
    return sentence;
  }

  return (
    <>
    <div className="wrapper_flex">    
      <div className="flex_layout">
        <div className="white_side white_side_large"></div>
        <div className="transparent_side"></div>
      </div>
      <div className="history_list">

        {
          props.historyListLoad
          ?
          <>
            <div className="history_title_skeleton"></div>
            <div className="history_item history_item_skeleton">
              <span className="history_icon"></span>
              <div className="history_wrapper"></div>
            </div>
            <div className="history_item history_item_skeleton">
              <span className="history_icon"></span>
              <div className="history_wrapper"></div>
            </div>
            <div className="history_item history_item_skeleton history_item_last">
              <span className="history_icon"></span>
              <div className="history_wrapper"></div>
            </div>
            <div className="history_title_skeleton"></div>
            <div className="history_item history_item_skeleton">
              <span className="history_icon"></span>
              <div className="history_wrapper"></div>
            </div>
            <div className="history_item history_item_skeleton history_item_last">
              <span className="history_icon"></span>
              <div className="history_wrapper"></div>
            </div>
          </>
          :
          Object.keys(props.historyList).length == 0
          ?
          <div className="history_empty_container">
              <div className="plan_empty_icon">
                <HistoryIcon />
              </div>
              <h3>{props.lang == "english" ? "No Recent Movement" : "Ningún movimiento reciente"}</h3>
              <p>{props.lang == "english" ? "There hasn't been any recent activity in your account. Explore the marketplace." : "No ha habido actividad reciente en tu cuenta. Explora el mercado."}</p>
              <Link className="btn btn_primary" to="/marketplace">
                <span>{props.lang == "english" ? "Marketplace" : "Marketplace"}</span>
              </Link>
            </div>
          :
          Object.keys(props.historyList).reverse().map(historyDate =>
            <>
            <h4 className="history_title">{formatDate(historyDate)}</h4>
            {
              props.historyList[historyDate].reverse().map((history, historyIndex) => 
                <div className={"history_item" + (historyIndex == props.historyList[historyDate].length-1 ? " history_item_last" : "")}>
                  <span className="history_icon"></span>
                  <div className="history_wrapper">
                    <div className="history_content">
                      <p>{props.lang == "english" ? formatSentences(history.Description) : history.Description}</p>
                    </div>
                    <div className="history_time">{extractTimeFromDate(history.TransactionDate)}</div> 
                    { history.Ammount != 0 && <div className="history_price">{history.Ammount} MXN</div> }
                  </div>
                </div>  
              )          
            }
            </> 
          )
        }
      </div>
    </div>
    </>
  );
}

export default PaymentsHistory;


