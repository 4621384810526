import React from "react";

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M419-415l-79-79q-13-13-32.111-13-19.111 0-33.889 14-13 14-13 33t14 32l110 111q14.455 14 33.727 14Q438-303 453-317l227-228q15-14 15-32.611 0-18.611-15-33.389-14-13-33.611-12.5Q626.778-623 613-610L419-415zm61.138 360Q392-55 314.513-88.084q-77.488-33.083-135.417-91.012T88.084-314.375Q55-391.724 55-479.862 55-569 88.084-646.487q33.083-77.488 90.855-134.969 57.772-57.482 135.195-91.013Q391.557-906 479.779-906q89.221 0 166.827 33.454 77.605 33.453 135.012 90.802 57.407 57.349 90.895 134.877Q906-569.34 906-480q0 88.276-33.531 165.747-33.531 77.471-91.013 135.278-57.481 57.808-134.831 90.891Q569.276-55 480.138-55z"></path>
    </svg>
  );
}

export default SuccessIcon;
