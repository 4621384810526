import React, { useState, useEffect } from "react";

import "./admin.css";

import AdminAuth from "./AdminAuth"
import AdminTasks from "./AdminTasks"
import AdminAddApi from "./AdminAddApi"
import AdminApiPrices from "./AdminApiPrices";
import AdminApiCalls from "./AdminApiCalls";
import AdminApiBalances from "./AdminApiBalances";
import AdminUsers from "./AdminUsers";
import AdminLogs from "./AdminLogs";

import LogoFull from "../../icons/logo_full";
import ListIcon from "../../icons/list";
import LogoutIcon from "../../icons/logout";
import AddApiIcon from "../../icons/add_api";
import ApiPricesIcon from "../../icons/api_prices";
import ApiCallsIcon from "../../icons/api_calls";
import ApiBalanceIcon from "../../icons/api_balance";
import UsersIcon from "../../icons/users";
import LogsIcon from "../../icons/logs";

import GrainGIF from "../../img/grain.gif";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

function Admin(props) {

  const navigate = useNavigate();

  const [isConnectedLoading, setIsConnectedLoading] = useState(true);

  const [isConnected, setIsConnected] = useState(false);

  const [tabs, setTabs] = useState("all-tasks"); // all-tasks - add-api - api-prices - api-balances -- users

  useEffect(() => {
    axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/check",
        { clientId: props.clientId }
    )
    .then(res => {
      if (res.data.isAdmin) {
        setIsConnectedLoading(false);
      } else {
        navigate("/");
      }
    })
    .catch((err) => {
      navigate("/");
    }) 
  }, []);

  return (
    isConnectedLoading
    ?
    <></>
    :
    <>
      {!isConnected && <AdminAuth setIsConnected={setIsConnected}/> }
      <div className="body admin_body" style={{background: "#fcfcfe"}}>
        <div className="navbar">

          <div className="auth_side_backgorund">
            <div className="auth_side_overlay" style={{backgroundImage: "url('" + GrainGIF + "')"}}></div>
            <div className="auth_side_gradient">
              <div className="auth_side_gradient_circle1"></div>
              <div className="auth_side_gradient_circle2"></div>
              <div className="auth_side_gradient_circle3"></div>
            </div>
          </div> 
          <div className="wrapper" style={{borderBottom: 0}}>
            <Link to="/" className="logo_container">
              <LogoFull />  
            </Link>        
            
            <Link to="/" className="btn btn_glass logout_btn btn_icon">
                <LogoutIcon />
              </Link>
          </div>

          <div className="title_search">
            <div className="tabs_admin">
              {
                isConnected ?
                <>
                  <div className={"tab" + (tabs == "all-tasks" ? " active" : "")} onClick={() => setTabs("all-tasks")}>
                    <ListIcon />
                    <span>All Tasks</span>
                  </div>              
                  <div className={"tab" + (tabs == "add-api" ? " active" : "")} onClick={() => setTabs("add-api")}>
                    <AddApiIcon />
                    <span>Add New API</span>
                  </div>              
                  <div className={"tab" + (tabs == "api-prices" ? " active" : "")} onClick={() => setTabs("api-prices")}>
                    <ApiPricesIcon />
                    <span>API Prices</span>
                  </div>              
                  <div className={"tab" + (tabs == "api-calls" ? " active" : "")} onClick={() => setTabs("api-calls")}>
                    <ApiCallsIcon />
                    <span>API Calls</span>
                  </div>              
                  <div className={"tab" + (tabs == "api-balances" ? " active" : "")} onClick={() => setTabs("api-balances")}>
                    <ApiBalanceIcon />
                    <span>API Balances</span>
                  </div>             
                  <div className={"tab" + (tabs == "users" ? " active" : "")} onClick={() => setTabs("users")}>
                    <UsersIcon />
                    <span>Users</span>
                  </div>              
                  <div className={"tab" + (tabs == "logs" ? " active" : "")} onClick={() => setTabs("logs")}>
                    <LogsIcon />
                    <span>SEON Logs</span>
                  </div>              
                </>
                :
                <>
                  <div className="tab_admin_skeleton"></div>
                  <div className="tab_admin_skeleton"></div>
                  <div className="tab_admin_skeleton"></div>
                </>
              }
            </div>
          </div>
        </div>
        <div className="wrapper">
          {
            tabs == "all-tasks" &&
            <AdminTasks
              isConnected={isConnected}
            />
          }
          {
            tabs == "add-api" &&
            <AdminAddApi
              isConnected={isConnected}
            />
          }
          {
            tabs == "api-prices" &&
            <AdminApiPrices
              isConnected={isConnected}
            />
          }
          {
            tabs == "api-calls" &&
            <AdminApiCalls
              isConnected={isConnected}
            />
          }
          {
            tabs == "api-balances" &&
            <AdminApiBalances
              isConnected={isConnected}
            />
          }
          {
            tabs == "users" &&
            <AdminUsers
              isConnected={isConnected}
            />
          }
          {
            tabs == "logs" &&
            <AdminLogs
              isConnected={isConnected}
            />
          }
        </div>
      </div>
    </>
  );
}

export default Admin;