import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import axios from "axios";

import "./api.css";

import ApiDocumentation from "./ApiDocumentation";
import ApiLogs from "./ApiLogs";
import ApiAnalytics from "./ApiAnalytics";
import ApiPlans from "./ApiPlans";
import ApiInvoicePopup from "./ApiInvoicePopup";

import Navbar from "../Navbar/Navbar";

import CodeIcon from "../../icons/code";
import CalendarPicker from "../../icons/calendar_picker"

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import ReactGA from "react-ga4";

function Api(props) {
  
  let { apiId } = useParams();

  const [tabHeader, setTabHeader] = useState("documentation"); // documentation - plans - analytics - logs

  const [apiInfo, setApiInfo] = useState({}); // .Name .Description .Active
  const [apiInfoLoad, setApiInfoLoad] = useState(true); // .Name .Description .Active

  // Api Documentation
  const [apiDocumentationInfo, setApiDocumentationInfo] = useState();
  const [apiDocumentationInfoLoad, setApiDocumentationInfoLoad] = useState(true);
  const [publicApiKey, setPublicApiKey] = useState("");

  const [openLanguageDrawer, setOpenLanguageDrawer] = useState(false);
  const [selectedDevLanguage, setSelectedDevLanguage] = useState({language: "", method: ""})

  // Api Plans
  const [apiPlansInfo, setApiPlansInfo] = useState([
    {
        "Name": "Probar",
        "Price": null,
        "CallLimit": null
    },
    {
        "Name": "Inicial",
        "Price": null,
        "CallLimit": null
    },
    {
        "Name": "Medio",
        "Price": null,
        "CallLimit": null
    },
    {
        "Name": "Avanzado",
        "Price": null,
        "CallLimit": null
    }
  ]);
  const [apiPlansInfoLoad, setApiPlansInfoLoad] = useState(true);
  const [unlimitedValidity, setUnlimitedValidity] = useState(false);
  const [unlimitedPerc, setUnlimitedPerc] = useState(0);

  // Api Logs
  const [logs, setlogs] = useState({});
  const [logsLoad, setLogsLoad] = useState(true);

  const [selectedLog, setSelectedLog] = useState({});
  const [selectedLogId, setSelectedLogId] = useState({});
  const [selectedLogLoad, setSelectedLogLoad] = useState(true);
  const [isSelectedLogEmpty, setIsSelectedLogEmpty] = useState(true);

  // Api Analytics
  const [analytics, setAnalytics] = useState({});
  const [analyticsLoad, setAnalyticsLoad] = useState(true);
  const [analyticsInterval, setAnalyticsInterval] = useState("monthly"); // weekly - monthly - yearly
  const categoriesObj = {
    weekly: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    monthly: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    yearly: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  }
  const [seriesLatencyWeekly, setSeriesLatencyWeekly] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [seriesLatencyMonthly, setSeriesLatencyMonthly] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [seriesLatencyYearly, setSeriesLatencyYearly] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [seriesConsumptionWeekly, setSeriesConsumptionWeekly] = useState([
    { name: 'Successful calls', data: [0, 0, 0, 0, 0, 0, 0] },
    { name: 'Failed calls', data: [0, 0, 0, 0, 0, 0, 0] }
  ]);
  const [seriesConsumptionMonthly, setSeriesConsumptionMonthly] = useState(
    [
      { name: 'Successful calls', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
      { name: 'Failed calls', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
    ]
  );  
  const [seriesConsumptionYearly, setSeriesConsumptionYearly] = useState(
    [
      { name: 'Successful calls', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
      { name: 'Failed calls', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
    ]
  );
  const [analyticsDate, setAnalyticsDate] = useState(new Date());

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
  
    const monthNames = 
    props.lang == "english" ?
    [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ] 
    :
    [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
  
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
  
    return formattedDate;
  }

  useEffect(() => {

    const dev_lang = localStorage.getItem("dev_lang");
    if (dev_lang) {
      setSelectedDevLanguage(JSON.parse(dev_lang));
    } else {
      localStorage.setItem("dev_lang", JSON.stringify(
        {language: "NodeJs", method: "Axios"}
      ));   
      setSelectedDevLanguage({language: "NodeJs", method: "Axios"})
    }

    try {

      const getApiBody = {
        clientId: props.clientId,
        apiId: apiId
      }

      axios.post(
        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api`,
        getApiBody
      )
      .then(res => {
        setApiInfo(res.data);
        console.log(res.data);
        setPublicApiKey(res.data.PublicApiKey)
        setApiInfoLoad(false);
        ReactGA.send({ hitType: "API", page: "/api/" + apiId, title: res.data.Name });
      })
    } catch (error) {}  
  }, []);

  // Api Invoice Popup
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isSuccess = queryParams.get('success') === 'true';
    if (isSuccess) {
      setShowInvoicePopup(true);
    }
  }, [])

  return (
    <div className="body" style={
      tabHeader == "plans" ? {} :
      openLanguageDrawer ? {background: "#fcfcfe", overflow: "hidden"}
      : {background: "#fcfcfe"}
    }>
      <div className="navbar">
        <Navbar lang={props.lang}/>
        
        <div className="title_search">
          <div className="title_search_info">
            { !apiInfoLoad ? <h1>{apiInfo.Name}</h1> : <div className="api_title_skeleton"></div> }
            { !apiInfoLoad ? <p>{apiInfo.Description}</p> : <><div className="api_p_skeleton"></div><div className="api_p_skeleton"></div></> }
            <div className="tabs tabs_page_header">
              {
                apiInfoLoad ?
                <>
                  <div className="api_tab_skeleton"></div>
                  <div className="api_tab_skeleton"></div>
                  <div className="api_tab_skeleton"></div>
                </>
                :
                <>
                  <span className={"tab" + (tabHeader == "documentation" ? " active" : "")} onClick={() => {setTabHeader("documentation");setOpenLanguageDrawer(false);}}>{props.lang == "english" ? "Documentation" : "Documentación"}</span>
                  <span className={"tab" + (tabHeader == "plans" ? " active" : "")} onClick={() => {setTabHeader("plans");setOpenLanguageDrawer(false);}}>{props.lang == "english" ? "Plans" : "Planes"}</span>
                  {
                    apiInfo.Active && 
                    <>
                      <span className={"tab" + (tabHeader == "analytics" ? " active" : "")} onClick={() => {setTabHeader("analytics");setOpenLanguageDrawer(false);}}>{props.lang == "english" ? "Analytics" : "Analítica"}</span>
                      <span className={"tab" + (tabHeader == "logs" ? " active" : "")} onClick={() => {setTabHeader("logs");setOpenLanguageDrawer(false);}}>{props.lang == "english" ? "Logs" : "Registros"}</span>
                    </>
                  }
                  <div className="tab_controll" style={
                    props.lang == "english" ?
                    (
                      tabHeader == "documentation" ? {left: 15} :
                      tabHeader == "plans" ? {left: 133.5} :
                      tabHeader == "analytics" ? {left: 231} :
                      {left: 326}  
                    )
                    :
                    (
                      tabHeader == "documentation" ? {left: 16.5} :
                      tabHeader == "plans" ? {left: 141.5} :
                      tabHeader == "analytics" ? {left: 242} :
                      {left: 352}  
                    )
                  }></div>
                </>
              }
              <div className="tabs_header_side">
                {
                  tabHeader == "documentation" && 
                  <div className="btn btn_glass btn_w_icon" onClick={() => setOpenLanguageDrawer(true)}>
                    <CodeIcon />
                    <div className="btn_wrapper">
                      <span>{props.lang == "english" ? "Language" : "Idioma"}</span>
                      <span>{selectedDevLanguage.language} - {selectedDevLanguage.method}</span>
                    </div>
                  </div>
                }
                {
                  tabHeader == "plans" &&
                  <>
                    <span className="tabs_header_label">{props.lang == "english" ? "Validity :" : "Validez :"}</span>
                    <div className="checkbox_btns">
                      <div className={"checkbox_btn" + (!unlimitedValidity ? " active" : "")} onClick={() => setUnlimitedValidity(false)}>{props.lang == "english" ? "1 month" : "1 mes"}</div>
                      <div className={"checkbox_btn" + (unlimitedValidity ? " active" : "")} onClick={() => setUnlimitedValidity(true)}>{props.lang == "english" ? "Unlimited" : "Sin límites"}</div>
                    </div>
                  </>
                }
                {
                  tabHeader == "analytics" &&
                  <>
                    <div className="checkbox_btns">
                      <div className={"checkbox_btn" + (analyticsInterval == "weekly" ? " active" : "")} onClick={() => setAnalyticsInterval("weekly")}>{props.lang == "english" ? "Weekly" : "Semanal"}</div>
                      <div className={"checkbox_btn" + (analyticsInterval == "monthly" ? " active" : "")} onClick={() => setAnalyticsInterval("monthly")}>{props.lang == "english" ? "Monthly" : "Mensual"}</div>
                      <div className={"checkbox_btn" + (analyticsInterval == "yearly" ? " active" : "")} onClick={() => setAnalyticsInterval("yearly")}>{props.lang == "english" ? "Yearly" : "Anual"}</div>
                    </div>
                    <DatePicker
                      onChange={setAnalyticsDate}
                      value={analyticsDate}
                      clearIcon={<></>}
                      calendarIcon={
                        <>
                          <CalendarPicker />
                          <span>{formatDate(analyticsDate)}</span>
                        </>
                      }
                      locale={props.lang == "english" ? "en" : "es"}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        tabHeader == "plans" ?
        <ApiPlans      
          apiId={apiId}
          apiPlansInfo={apiPlansInfo}
          setApiPlansInfo={setApiPlansInfo}
          apiPlansInfoLoad={apiPlansInfoLoad}
          setApiPlansInfoLoad={setApiPlansInfoLoad}
          clientId={props.clientId}
          unlimitedValidity={unlimitedValidity}
          lang={props.lang}
          unlimitedPerc={unlimitedPerc}
          setUnlimitedPerc={setUnlimitedPerc}
          apiName={apiInfo.Name}
          />
        :
        tabHeader == "analytics" ?
        <ApiAnalytics
          analytics={analytics}
          setAnalytics={setAnalytics}
          analyticsLoad={analyticsLoad}
          setAnalyticsLoad={setAnalyticsLoad}
          apiId={apiId}
          clientId={props.clientId}
          categoriesObj={categoriesObj}
          analyticsInterval={analyticsInterval}
          seriesLatencyWeekly={seriesLatencyWeekly}
          seriesLatencyMonthly={seriesLatencyMonthly}
          seriesLatencyYearly={seriesLatencyYearly}
          seriesConsumptionWeekly={seriesConsumptionWeekly}
          seriesConsumptionMonthly={seriesConsumptionMonthly}
          seriesConsumptionYearly={seriesConsumptionYearly}
          setSeriesConsumptionWeekly={setSeriesConsumptionWeekly}
          setSeriesConsumptionMonthly={setSeriesConsumptionMonthly}
          setSeriesConsumptionYearly={setSeriesConsumptionYearly}
          setSeriesLatencyWeekly={setSeriesLatencyWeekly}
          setSeriesLatencyMonthly={setSeriesLatencyMonthly}
          setSeriesLatencyYearly={setSeriesLatencyYearly}
          lang={props.lang}
          analyticsDate={analyticsDate}
        />
        : 
        <div className="wrapper_flex">
          <div className="flex_layout">
            <div className="white_side"></div>
            <div className="transparent_side"></div>
          </div>
          {
            tabHeader == "documentation"
            ?
            <ApiDocumentation
              apiId={apiId}
              apiDocumentationInfo={apiDocumentationInfo}
              setApiDocumentationInfo={setApiDocumentationInfo}
              apiDocumentationInfoLoad={apiDocumentationInfoLoad}
              setApiDocumentationInfoLoad={setApiDocumentationInfoLoad}
              openLanguageDrawer={openLanguageDrawer}
              setOpenLanguageDrawer={setOpenLanguageDrawer}
              selectedDevLanguage={selectedDevLanguage}
              setSelectedDevLanguage={setSelectedDevLanguage}
              lang={props.lang}
              publicApiKey={publicApiKey}
              setPublicApiKey={setPublicApiKey}
              />
            :
            <ApiLogs
              apiId={apiId}
              clientId={props.clientId}
              apiName={apiInfo.Name}

              logs={logs}
              setlogs={setlogs}
              logsLoad={logsLoad}
              setLogsLoad={setLogsLoad}
              selectedLog={selectedLog}
              setSelectedLog={setSelectedLog}
              selectedLogId={selectedLogId}
              setSelectedLogId={setSelectedLogId}
              selectedLogLoad={selectedLogLoad}
              setSelectedLogLoad={setSelectedLogLoad}
              isSelectedLogEmpty={isSelectedLogEmpty}
              setIsSelectedLogEmpty={setIsSelectedLogEmpty}

              lang={props.lang}

              />}
        </div>

      }
      {
        showInvoicePopup &&
        <ApiInvoicePopup
          setShowInvoicePopup={setShowInvoicePopup}
          apiId={apiId}
          clientId={props.clientId}
        />
      }
    </div>
  );
}

export default Api;