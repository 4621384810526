
function Step4(props) {
    return (
        <div className="add_new_api_container">
            <div className="add_new_api_plans_flex">
                <div className="card add_api_card">
                    <h3>Free Trial</h3>
                    <div className="input_container">
                        <label>Price</label>
                        <div className="input_container_inside_flex">
                            <input type="text" value="0" disabled/>
                            <span>MXN</span>
                        </div>
                    </div>
                    <div className="input_container">
                        <label>Calls Count</label>
                        <input type="text" value={props.freeTrialCount} onChange={(e) => props.setFreeTrialCount(parseInt(e.target.value))}/>
                    </div>
                    <div className="input_container">
                        <label>Stripe ID</label>
                        <input type="text" disabled/>
                    </div>
                    <div className="input_container">
                        <label>Description</label>
                        <textarea value={props.freeTrialDesc} onChange={(e) => props.setFreeTrialDesc(e.target.value)}/>
                    </div>
                </div>
                <div className="card add_api_card">
                    <h3>Initial Plan</h3>
                    <div className="input_container">
                        <label>Price</label>
                        <div className="input_container_inside_flex">
                            <input type="text" value={props.initialPrice} onChange={(e) => props.setInitialPrice(parseInt(e.target.value))}/>
                            <span>MXN</span>
                        </div>
                    </div>
                    <div className="input_container">
                        <label>Calls Count</label>
                        <input type="text" value={props.initialCount} onChange={(e) => props.setInitialCount(parseInt(e.target.value))}/>
                    </div>
                    <div className="input_container">
                        <label>Stripe ID</label>
                        <input type="text" value={props.initialStripe} onChange={(e) => props.setInitialStripe(e.target.value)}/>
                    </div>
                    <div className="input_container">
                        <label>Description</label>
                        <textarea value={props.initialDesc} onChange={(e) => props.setInitialDesc(e.target.value)}/>
                    </div>
                </div>
                <div className="card add_api_card">
                    <h3>Medium Plan</h3>
                    <div className="input_container">
                        <label>Price</label>
                        <div className="input_container_inside_flex">
                            <input type="text" value={props.mediumPrice} onChange={(e) => props.setMediumPrice(parseInt(e.target.value))}/>
                            <span>MXN</span>
                        </div>
                    </div>
                    <div className="input_container">
                        <label>Calls Count</label>
                        <input type="text" value={props.mediumCount} onChange={(e) => props.setMediumCount(parseInt(e.target.value))}/>
                    </div>
                    <div className="input_container">
                        <label>Stripe ID</label>
                        <input type="text" value={props.mediumStripe} onChange={(e) => props.setMediumStripe(e.target.value)}/>
                    </div>
                    <div className="input_container">
                        <label>Description</label>
                        <textarea value={props.mediumDesc} onChange={(e) => props.setMediumDesc(e.target.value)}/>
                    </div>
                </div>
                <div className="card add_api_card">
                    <h3>Advanced Plan</h3>
                    <div className="input_container">
                        <label>Price</label>
                        <div className="input_container_inside_flex">
                            <input type="text" value={props.advancedPrice} onChange={(e) => props.setAdvancedPrice(parseInt(e.target.value))}/>
                            <span>MXN</span>
                        </div>
                    </div>
                    <div className="input_container">
                        <label>Calls Count</label>
                        <input type="text" value={props.advancedCount} onChange={(e) => props.setAdvancedCount(parseInt(e.target.value))}/>
                    </div>
                    <div className="input_container">
                        <label>Stripe ID</label>
                        <input type="text" value={props.advancedStripe} onChange={(e) => props.setAdvancedStripe(e.target.value)}/>
                    </div>
                    <div className="input_container">
                        <label>Description</label>
                        <textarea value={props.advancedDesc} onChange={(e) => props.setAdvancedDesc(e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="add_api_footer_action">
                <div className="btn" onClick={() => props.handleBack()}>
                    <span>Back</span>
                </div>
                <div className="btn btn_primary" onClick={() => props.handleNext()}>
                    <span>Next</span>
                </div>
            </div>
        </div>
    );
}

export default Step4;