import React from "react";

function ApiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M270.097-115.935q-79.901 0-137.032-57.169-57.13-57.168-57.13-136.827 0-68.504 40.195-120.058 40.196-51.555 105.305-67.555v65.631q-36.674 14-59.772 47.111t-23.098 74.88q0 54.161 38.402 92.759t92.977 38.598q54.773 0 93.132-38.419 38.359-38.418 38.359-93.016v-31.435h247.087q8.355-9 19.251-14.5 10.897-5.5 22.227-5.5 21.757 0 36.596 14.839 14.839 14.839 14.839 36.483 0 20.844-14.839 36.196-14.839 15.352-36.596 15.352-11.33 0-22.227-5.5-10.896-5.5-19.251-14.5H462.181q-14.833 70.195-68.215 116.413-53.383 46.217-123.869 46.217zm419.903 0q-48.478 0-90.294-22.239-41.815-22.239-68.532-60.391h89.587q14.949 10 32.746 15 17.797 5 36.493 5 54.797 0 93.116-38.402t38.319-92.977q0-54.773-38.319-93.132T690-441.435q-19.717 0-36.196 4.859-16.478 4.859-31.53 14.098L494.043-638.804q-20.239-4.239-32.858-18.022-12.62-13.783-12.62-33.19 0-21.441 15.352-36.43t36.076-14.989q21.724 0 36.583 14.929 14.859 14.93 14.859 36.543 0 3.963-.5 7.343-.5 3.381-2.5 10.381l100.608 172.174q10.761-2 22.019-3 11.257-1 18.938-1 80.687 0 137.376 56.539 56.689 56.539 56.689 137.609 0 79.591-56.689 136.787Q770.687-115.935 690-115.935zm-419.887-142.63q-20.844 0-36.196-15.319-15.352-15.319-15.352-36.116 0-18.585 12.62-32.39 12.619-13.806 31.858-18.806L371.891-544.37q-30.956-29.239-48.456-66.95-17.5-37.712-17.5-78.695 0-80.789 57.211-137.42 57.212-56.63 136.931-56.63 77.719 0 133.854 53.689Q690.065-776.687 694.065-700h-62.63q-3-51.717-40.894-86.576-37.894-34.859-90.457-34.859-54.562 0-93.041 38.319-38.478 38.319-38.478 93.04 0 42.892 25.098 76.701 25.098 33.81 63.815 47.81L317.435-328.801q2 5.972 3 10.406 1 4.433 1 8.35 0 20.842-14.839 36.161-14.839 15.319-36.483 15.319z"></path>
    </svg>
  );
}

export default ApiIcon;
