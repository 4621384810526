import React from "react";

function SortDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 -960 960 960"
    >
      <path d="M360-200v-80h264L160-744l56-56 464 464v-264h80v400H360z"></path>
    </svg>
  );
}

export default SortDownIcon;
