import React, { useState, useEffect } from "react";

import "./api.css";
import CloseIcon from "../../icons/close";
import SelectedIcon from "../../icons/selected";

function ApiDrawer(props) {

    const [languageSelect, setLanguageSelect] = useState([
        {language: "C#", method: "HttpClient"},
        {language: "C#", method: "RestSharp"},
        {language: "cURL", method: "cURL"},
        {language: "Dart", method: "http"},
        {language: "Go", method: "Native"},
        {language: "HTTP", method: "HTTP"},
        {language: "Java", method: "OkHttp"},
        {language: "Java", method: "Unirest"},
        {language: "JavaScript", method: "Fetch"},
        {language: "JavaScript", method: "jQuery"},
        {language: "JavaScript", method: "XHR"},
        {language: "C", method: "libcurl"},
        {language: "NodeJs", method: "Axios"},
        {language: "NodeJs", method: "Native"},
        {language: "NodeJs", method: "Request"},
        {language: "NodeJs", method: "Unirest"},
        {language: "Objective-C", method: "NSURLSession"},
        {language: "OCaml", method: "Cohttp"},
        {language: "PHP", method: "cURL"},
        {language: "PHP", method: "Guzzle"},
        {language: "PHP", method: "HTTP_Request2"},
        {language: "PHP", method: "pecl_http"},
        {language: "PowerShell", method: "RestMethod"},
        {language: "Python", method: "http.client"},
        {language: "Python", method: "Requests"},
        {language: "R", method: "httr"},
        {language: "R", method: "RCurl"},
        {language: "Ruby", method: "Net::HTTP"},
        {language: "Shell", method: "Httpie"},
        {language: "Shell", method: "wget"},
        {language: "Swift", method: "URLSession"}
    ]);
 
    const selectLanguage = ( lang, method ) => {
        props.setSelectedDevLanguage({language: lang, method: method});
        localStorage.setItem("dev_lang", JSON.stringify(
            {language: lang, method: method}
        ));      
        props.setOpenLanguageDrawer(false);
    }
    
  return (
    <div className={"drawer_container" + (props.openLanguageDrawer ? " active" : "")}>
        <div className="drawer_background" onClick={() => props.setOpenLanguageDrawer(false)}></div>
        <div className="drawer_wrapper">
            <div className="drawer_head">
                <h2>{props.lang == "english" ? "Language" : "Idioma"}</h2>
                <span className="drawer_close" onClick={() => props.setOpenLanguageDrawer(false)}>
                    <CloseIcon />
                </span>
            </div>
            <div className="drawer_select_list">
                {
                    languageSelect.map(language =>
                        <div
                            className={"drawer_select_item" +
                                ((props.selectedDevLanguage.language == language.language && props.selectedDevLanguage.method == language.method) ? " active" : "")
                            }
                            onClick={() => selectLanguage(language.language, language.method)}
                            >
                            <span>{language.language}</span>
                            <span>{language.method}</span>
                            <SelectedIcon />
                        </div>    
                    )
                }
            </div>
        </div>
    </div>
  );
}

export default ApiDrawer;