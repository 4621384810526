import React from "react";

function SquareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M114.022-114.022v-732.196h732.196v732.196H114.022zm68.13-68.13h595.696v-595.696H182.152v595.696z"></path>
    </svg>
  );
}

export default SquareIcon;
