import React from "react";

function OpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 -960 960 960"
    >
      <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200zm188-212l-56-56 372-372H560v-80h280v280h-80v-144L388-332z"></path>
    </svg>
  );
}

export default OpenIcon;
