import React, { useState, useEffect } from "react";

import GrainGIF from "../../img/grain.gif";
import LogoWhite from "../../img/logo_white.png";
import Password from "../../icons/password";
import LoginIcon from "../../icons/login";

function AdminAuth(props) {

  const [secretCode, setSecretCode] = useState("");
  const [secretCodeError, setSecretCodeError] = useState(false);

  const handleEnterPress = (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        getToAdmin();
      }
  }

  const getToAdmin = () => {
    if (secretCode == "scorce/2023.1") {
      props.setIsConnected(true);
    } else {
      setSecretCodeError(true);
    }
  }

  return (
    <div className="admin_auth_container">
      <div className="auth_side">
        <div className="auth_side_backgorund">
            <div className="auth_side_overlay" style={{backgroundImage: "url('" + GrainGIF + "')"}}></div>
            <div className="auth_side_gradient">
            <div className="auth_side_gradient_circle1"></div>
            <div className="auth_side_gradient_circle2"></div>
            <div className="auth_side_gradient_circle3"></div>
            </div>
            <div className="auth_side_text">
                <div className="auth_side_word">DEVELOP</div>
                <div className="auth_side_word">MENT THAT</div>
                <div className="auth_side_word">MATTERS</div>
            </div>
            </div> 
            <div className="auth_side_content">
            <div className="auth_side_login">
              <div className="auth_illustration_api_categ">
                <div className="auth_illu_source">
                  <div className="auth_illu_source_logo" style={{backgroundImage: "url('" + LogoWhite +"')"}}></div>
                </div>
                <div className="auth_side_login_content">
                  <h1>Admin Panel</h1>
                </div>
                <div className="input_admin_container">
                  <Password/>
                  <input type="password" className="admin_input" onChange={(e) => {setSecretCode(e.target.value);setSecretCodeError(false)}} onKeyUp={(e) => handleEnterPress(e)} />
                  <div className={"admin_btn" + (secretCodeError ? " admin_btn_error" : "")} onClick={() => getToAdmin()}><LoginIcon /></div>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
  );
}

export default AdminAuth;