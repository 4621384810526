import React, { useState, useEffect } from "react";

import "./coming-soon.css";

import Navbar from "../Navbar/Navbar";

import { Link } from "react-router-dom";
import Workflow from "../../icons/workflow";
import ReactGA from "react-ga4";

function ComingSoon(props) {

  useEffect(() => {
    ReactGA.send({ hitType: "pageView", page: "/workflow", title: "Workflow" });
  })

  return (
    <div className="body coming_soon_body" style={{background: "#fcfcfe"}}>
        <div className="navbar">
        <Navbar navActive="workflow" lang={props.lang} />
        </div>
        <div className="coming_soon_container">
            
        <div className="history_empty_container">
            <div className="plan_empty_icon">
                <Workflow />
            </div>
            <h3>{props.lang == "english" ? "Coming Soon" : "Muy Pronto"}</h3>
            <p>{props.lang == "english" ? "Our new workflow interface is on the horizon, offering seamless API integration and automation. Stay tuned to merge APIs, create workflows, and trigger actions effortlessly. Elevate your productivity with us." : "Nuestra nueva interfaz de flujo de trabajo está en el horizonte, ofreciendo una integración API y automatización sin fisuras. Permanezca atento para fusionar API, crear flujos de trabajo y desencadenar acciones sin esfuerzo. Aumente su productividad con nosotros."}</p>
            <Link className="btn btn_primary" to="/">
                <span>{props.lang == "english" ? "Marketplace" : "Marketplace"}</span>
            </Link>
            </div>
        </div>
    </div>
  );

}

export default ComingSoon;
