import React from "react";

function ListIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M270-291h60v-60h-60v60zm0-159h60v-60h-60v60zm0-160h60v-60h-60v60zm162 319h244v-60H432v60zm0-159h244v-60H432v60zm0-160h244v-60H432v60zM95-95v-771h771v771H95zm94-94h582v-582H189v582zm0 0v-582 582z"></path>
    </svg>
  );
}

export default ListIcon;
