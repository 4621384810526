import React from "react";

function KYCIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 1"
      viewBox="0 0 57.88 57.88"
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.72 19.5c0 4.17 3.38 7.56 7.56 7.56s7.56-3.38 7.56-7.56-3.38-7.56-7.56-7.56-7.56 3.38-7.56 7.56M11.94 45.94v-3.78a7.56 7.56 0 017.56-7.56h7.56a7.56 7.56 0 017.56 7.56v3.78M36.5 12.19c4.04 1.04 6.48 5.15 5.45 9.19a7.564 7.564 0 01-5.45 5.45M45.94 45.94v-3.78a7.558 7.558 0 00-5.67-7.27"
      ></path>
    </svg>
  );
}

export default KYCIcon;
