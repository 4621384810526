import { useState, useEffect } from "react";

import CheckIcon from "../../../icons/check"

function Step2(props) {

    const handleName = ( value ) => {
        props.setName(value);
        props.setNameError(false);
    }
    const handleDescription = ( value ) => {
        props.setDescription(value);
        props.setDescriptionError(false);
    }
    const handleHost = ( value ) => {
        props.setHost(value);
        props.setHostError(false);
    }
    const handleImage = ( value ) => {
        props.setImage(value);
        props.setImageError(false);
    }

    return (
    <div className="add_new_api_container">
        <div className="card add_api_card">
            <div className={"input_container" + (props.nameError ? " input_container_error" : "")}>
                <label>Name</label>
                <input type="text" value={props.name} onChange={(e) => handleName(e.target.value)}/>
            </div>
            <div className={"input_container" + (props.descriptionError ? " input_container_error" : "")}>
                <label>Description</label>
                <textarea  value={props.description} onChange={(e) => handleDescription(e.target.value)}/>
            </div>
            <div className={"input_container" + (props.hostError ? " input_container_error" : "")}>
                <label>Host</label>
                <input type="text" value={props.host} onChange={(e) => handleHost(e.target.value)}/>
            </div>
            <div className={"input_container" + (props.imageError ? " input_container_error" : "")}>
                <label>Image</label>
                <input type="text"  value={props.image} onChange={(e) => handleImage(e.target.value)}/>
            </div>
            <div className="inputs_flexbox">
                <div className="input_container">
                    <label>Status</label>
                    <div className="radio_items_container">
                        <div className={"input_checkbox_container input_radio_container" + (props.status ? " active" : "")} onClick={() => props.setStatus(true)}>
                        <div className={"input_radio" + (props.status ? " active" : "")} />
                            <span>Active</span>
                        </div>           
                        <div className={"input_checkbox_container input_radio_container" + (!props.status ? " active" : "")} onClick={() => props.setStatus(false)}>
                            <div className={"input_radio" + (!props.status ? " active" : "")} />
                            <span>Disabled</span>
                        </div>           
                    </div>
                </div>
                <div className="input_container">
                    <label>Certification</label>
                    <div className={"input_checkbox_container" + (props.certification ? " active" : "")} onClick={() => props.setCertification(!props.certification)}>
                        <div className="input_radio">
                            { props.certification && <CheckIcon /> }
                        </div>
                        <span>Require a certification</span>
                    </div>           
                </div>
            </div>
        </div>
        <div className="add_api_footer_action">
            <div className="btn" onClick={() => props.handleBack()}>
                <span>Back</span>
            </div>
            <div className="btn btn_primary" onClick={() => props.handleNext()}>
                <span>Next</span>
            </div>
        </div>
    </div>
    );
  }
  
  export default Step2;
  