import React, { useState, useEffect } from "react";

import GrainGIF from "../../../img/grain.gif";
import Search from "../../../icons/search";
import ArrowBack from "../../../icons/arrow_back";

import LogoWhite from "../../../img/logo_white.png";
import LogoFullWhite from "../../../img/logo_full_white.png";

import CardCopomex from "../../../img/card_copomex.png";
import CardAutoApi from "../../../img/card_autoapi.png";
import CardCurp from "../../../img/card_curp.png";
import CardFullEmail from "../../../img/card_full_email.png";
import CardFullFraud from "../../../img/card_full_fraud.png";
import CardMoffin from "../../../img/card_moffin.png";
import CardNubariumCfdi from "../../../img/card_nubarium_cfdi.png";
import CardNubariumIne from "../../../img/card_nubarium_ine.png";
import CardVisoor from "../../../img/card_visoor.png";

import LogoAnimation from "../../../img/logo_home.gif";

import AuthentificationIcon from "../../../icons/authentification"
import KYCIcon from "../../../icons/kyc"
import KYBIcon from "../../../icons/kyb"
import IdentifidadDigitalIcon from "../../../icons/identifidad_digital"
import PrevencionFraudIcon from "../../../icons/prevencion_fraud"


function SideAuth(props) {

  return (
    <>
        <div className="auth_side_backgorund">
            <div className="auth_side_overlay" style={{backgroundImage: "url('" + GrainGIF + "')"}}></div>
            <div className="auth_side_gradient">
            <div className="auth_side_gradient_circle1"></div>
            <div className="auth_side_gradient_circle2"></div>
            <div className="auth_side_gradient_circle3"></div>
            </div>
            <div className="auth_side_text">
                <div className="auth_side_word">DEVELOP</div>
                <div className="auth_side_word">MENT THAT</div>
                <div className="auth_side_word">MATTERS</div>
            </div>
            </div> 
            <div className="auth_side_content">
            <div className="auth_side_register">
            <div className="auth_side_header">
                <div className="login_back" onClick={() => {props.setFormLeft("login");props.setFormShowed("left");}}>
                <ArrowBack />
                <span>{props.lang == "english" ? "Login" : "Iniciar sesión"}</span>
                </div>
                <div className="auth_side_logo">
                </div>
            </div>
            <div className="auth_side_register_content">
                <div className="auth_side_register_illustration">
                    <div className="auth_side_illu">
                        <div className="logo_animation" style={{backgroundImage: "url('" + LogoAnimation + "')"}}></div>
                    </div>
                </div>
                <h2>
                <span className={props.carousel != 0 ? " auth_side_illu_hidden_left" : ""}><span>{props.lang == "english" ? "A single API that easily and securely connects to the best APIs in LATAM" : "Una única API que conecta de forma fácil y segura a las mejores APIs de LATAM"}</span></span>
                <span className={props.carousel == 0 ? " auth_side_illu_hidden_right" : props.carousel == 2 ? " auth_side_illu_hidden_left" : ""}><span>{props.lang == "english" ? "Get free trials and monitor API performance on a single dashboard" : "Obtén pruebas gratis y monitorea el performance de las APIs en un único dashboard"}</span></span>
                <span className={props.carousel != 2 ? " auth_side_illu_hidden_right" : ""}><span>{props.lang == "english" ? "No forced deadlines, quick integration, and an extra layer of security" : "Sin plazos forzosos, rápida integración y con una capa extra de seguridad"}</span></span>
                </h2>
                <div className="auth_side_carousel_container">
                {
                    [0, 1, 2].map(id => <div
                        className={"auth_side_carousel" + (props.carousel == id ? " active" : "")}
                        onClick={() => props.setCarousel(id)}
                    ></div>
                    )
                }
                </div>
            </div>            
            <div className="login_footer">
                <p className="copyright">© 2023 Scorce S.A.P.I. de C.V.</p>
                <p className="language">{props.lang == "english" ? "Language: " : "Idioma: "}<span onClick={() => {props.setLang("spanish"); localStorage.setItem("lang", "spanish");}}>{props.lang == "english" ? "Spanish" : "Español"}</span> | <span onClick={() => {props.setLang("english"); localStorage.setItem("lang", "english");}}>{props.lang == "english" ? "English" : "Inglés"}</span></p>
            </div>
            </div>
            <div className="auth_side_login">
            <div className="auth_illustration_api_categ">
                <div className="auth_illu_source">
                <div className="auth_illu_source_logo" style={{backgroundImage: "url('" + LogoWhite +"')"}}></div>
                </div>
                <div className="auth_illu_paths">
                <div className="auth_illu_path_1">
                    <div className="auth_illu_path"></div>
                </div>
                <div className="auth_illu_path_1 auth_illu_reverse">
                    <div className="auth_illu_path"></div>
                </div>
                <div className="auth_illu_path_2">
                    <div className="auth_illu_path"></div>
                </div>
                <div className="auth_illu_path_2 auth_illu_reverse">
                    <div className="auth_illu_path"></div>
                </div>
                <div className="auth_illu_path_3"></div>
                </div>
                <div className="auth_illu_categories">
                <div className="auth_illu_category">
                    <div className="auth_illu_category_item">
                       <AuthentificationIcon /> 
                    </div>
                    <span>{props.lang == "english" ? "Authentification" : "Autenticación"}</span>
                </div>    
                <div className="auth_illu_category">
                    <div className="auth_illu_category_item">
                        <KYCIcon />
                    </div>
                    <span>{props.lang == "english" ? "KYC" : "KYC"}</span>
                </div>
                <div className="auth_illu_category">
                    <div className="auth_illu_category_item">
                        <KYBIcon />
                    </div>
                    <span>{props.lang == "english" ? "KYB" : "KYB"}</span>
                </div>
                <div className="auth_illu_category">
                    <div className="auth_illu_category_item">
                       <IdentifidadDigitalIcon /> 
                    </div>
                    <span>{props.lang == "english" ? "Digital Identification" : "Identidad Digital"}</span>
                </div>
                <div className="auth_illu_category">
                    <div className="auth_illu_category_item">
                        <PrevencionFraudIcon />
                    </div>
                    <span>{props.lang == "english" ? "Fraud Prevention" : "Prevención de Fraude"}</span>
                </div>
                </div>
            </div>
            <div className="auth_side_login_content">
                <h1>{props.lang == "english" ? "Connect easily and securely to the best APIs in LATAM" : "Conéctate de forma fácil y segura a las mejores APIs de LATAM"}</h1>
                {/* <p>{props.lang == "english" ? "Save time on integrations, obtain free trials and monitor the performance of the contracted APIs from a single dashboard." : "Ahorra tiempo en las integraciones, obtén pruebas gratuitas y supervisa el rendimiento de las APIs contratadas desde un solo panel."}</p> */}
                <div className="auth_side_login_create">
                {props.lang == "english" ? "D'ont have an account ?" : "¿No tienes una cuenta?"}<span onClick={() => props.setFormShowed("right")}>{props.lang == "english" ? "Sign up" : "Regístrate"}</span>
                </div>
            </div>
            <div className="auth_side_login_footer">
                <a target="_blank" href="https://www.scorce.io/terminos-y-condiciones">{props.lang == "english" ? "Terms Conditions" : "Términos y Condiciones"}</a>
                <a target="_blank" href="https://www.scorce.io/politica-de-privacidad">{props.lang == "english" ? "Privacy Policy" : "Política de Privacidad"}</a>
            </div>
            </div>
        </div>
    </>
    );
}

export default SideAuth;