import React, { useState, useEffect } from "react";

import PlusIcon from "../../icons/plus";
import LoadingIcon from "../../icons/loading";
import ErrorIcon from "../../icons/error";

import { CodeBlock, monokai } from "react-code-blocks";
import beautify from 'json-beautify';

import axios from "axios";

function SandboxDev(props) {

    const [headerLength, setHeaderLength] = useState(0);
    const [paramsLength, setParamsLength] = useState(1);

    const [endpointId, setEndpointId] = useState("");

    const [params, setParams] = useState([{}]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [result, setResult] = useState("");

    const handleParams = (index, value, type) => {
        let newParams = params;
        newParams[index] = {...newParams[index]};
        newParams[index][type] = value;
        setParams(newParams);
    }

    const sendRequest = () => {

        setError(false);

        if (endpointId.trim().length == 0 ) {
            setError(true);
            return;
        }

        let paramsArray = params.map(param => {
            let newParam = param;
            if (!param.name) {
                newParam.name = "";
            }
            if (!param.value) {
                newParam.value = "";
            }
            return newParam;
        })

        setLoading(true);
        setResult("");
        try {    
            const requestBody = {
                clientId: props.clientId,
                endpointId: endpointId,
                params: paramsArray
            };     
            axios.post(
                `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/send-request`,
                requestBody
            )
            .then(res => {
                setResult(JSON.stringify(res));
                setLoading(false);
                console.log(res);
            })
        } catch (error) {
            setResult(JSON.stringify(error));
            setLoading(false);
            console.log(error);
        } 
    }

    const formatJson = (jsonString) => {
        try {
          const parsedJson = JSON.parse(jsonString);
          const formattedJson = beautify(parsedJson, null, 2, 80);
          return formattedJson;
        } catch (error) {
          return jsonString;
        }
      }

    return (
        <div className="wrapper sandbox_dev_wrapper">
            <div className="two_side_input">
                <div className="label_side">
                <label>{props.lang == "english" ? "Ruta para consumir" : "Route of consume"}</label>
                </div>
                <div className="input_side">
                <div className="input_container input_container_prefix">
                    <span className="sandbox_dev_method">POST</span>
                    <input type="text" value="https://services.scorce.io/api/apis/consume"/>
                </div>
                </div>
            </div>
            <div className="two_side_input">
                <div className="label_side">
                <label>HEADER</label>
                </div>
            <div className="input_side input_side_padding">
                {
                    headerLength > 0 &&
                    <div className="input_container">
                    {
                        Array.from({ length: headerLength }, (_, index) => (
                            <div className="input_two_container">
                                <input type="text" placeholder="name"/>
                                <input type="text" placeholder="value"/>
                            </div>
                        ))
                    }
                    </div>    
                }
                <div className="btn btn_link btn_w_icon" onClick={() => setHeaderLength(headerLength+1)}>
                    <PlusIcon />
                    <span>Add new header</span>
                </div>
            </div>
            </div>
            <div className="two_side_input">
                <div className="label_side">
                    <label>BODY</label>
                </div>
                <div className="input_side input_side_padding">
                <div className="input_container">
                    <label>{props.lang == "english" ? "Endpoint ID" : "ID del Endpoint"}</label>
                    <input type="text" placeholder={endpointId} onChange={(e) => setEndpointId(e.target.value)}/>
                </div>
                <div className="input_container">
                    <label>{props.lang == "english" ? "Parameters" : "Parámetros"}</label>
                    {
                        Array.from({ length: paramsLength }, (_, index) => (
                            <div className="input_two_container">
                                <input type="text" placeholder="name" onChange={(e) => handleParams(index, e.target.value, "name")}/>
                                <input type="text" placeholder="value" onChange={(e) => handleParams(index, e.target.value, "value")}/>
                            </div>
                        ))
                    }
                </div>
                <div className="btn btn_link btn_w_icon" onClick={() => setParamsLength(paramsLength+1)}>
                    <PlusIcon />
                    <span>Add new parameter</span>
                </div>
            </div>
            </div>
            <div className="two_side_submit">
                {
                    (loading || error) &&
                    // <div className="btn btn_icon btn_indicator btn_primary btn_loading">
                    //     <LoadingIcon />
                    // </div>

                    <>
                        <div className={"btn btn_indicator btn_primary" +
                                (loading ? " btn_loading btn_icon" : error ? " btn_error" : "")
                            }>
                            { loading ? <LoadingIcon /> :
                             error ?
                                <>
                                <ErrorIcon />
                                <span>{props.lang == "english" ? "The EndPoint ID field is required." : "El campo EndPoint ID es obligatorio."}</span>
                                </>
                                : <></>
                            }
                        </div>
                    </>


                }
                <div
                    className={"btn btn_primary" + (loading ? " btn_loading" : "")}
                    onClick={() => !loading ? sendRequest() : null}
                >
                    <span>{props.lang == "english" ? "Send request" : "Enviar solicitud"}</span>
                </div>
            </div>
            {
                result &&
                <>
                    <h3>Request Response</h3>
                    <div className="api_code_background">
                        <CodeBlock
                            text={formatJson(result)}
                            language='json'
                            showLineNumbers={true}
                            theme={monokai}
                            wrapLines={true}
                            
                            showInlineLineNumbers={false}
                            wrapLongLines={true}
                            lineProps={{ style: { flexWrap: 'wrap' } }}
                        />
                    </div>                
                </>
            }
        </div>
    )
}

export default SandboxDev;