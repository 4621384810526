import React, { useState, useEffect } from "react";

import Navbar from "../Navbar/Navbar";

import "./contact.css";

function Contact(props) {

  return (
    <div className="body" style={{background: "#fcfcfe"}}>
        <div className="navbar">
            <Navbar navActive="contact" lang={props.lang} />
            <div className="title_search">
                <div className="title_search_info title_search_info_center">
                    <h1>Contact</h1>
                    <p>Couldn't find what you were looking for? Contact the support team.<br/>Use the form to contact us for expert assistance.</p>
                </div>
            </div>
        </div>
        <div className="contact_form">
            <div className="input_container">
                <label>Subject</label>
                <input type="text"/>
            </div>
            <div className="input_container">
                <label>Description</label>
                <textarea />
            </div>
            <div className="contact_action">
                <div className="btn btn_primary">
                    <span>Send</span>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Contact;