import React, { useState, useEffect } from "react";

import axios from "axios";

import Step1 from "./AdminAddApiSteps/Step1";
import Step2 from "./AdminAddApiSteps/Step2";
import Step3 from "./AdminAddApiSteps/Step3";
import Step4 from "./AdminAddApiSteps/Step4";

import "./admin_add_api.css";
import Step5 from "./AdminAddApiSteps/Step5";

function AdminAddApi(props) {

    const [step, setStep] = useState(1);

    const [addApiError, setAddApiError] = useState("default"); // default - loading - error - success

    const handleNext = () => {
      if (step == 1 && providerId.length > 0) {
        setStep(step+1);
      } else {
        console.log("no provider id")
      }
  
      if (step == 2 ) {
        let checkErrors = true;

        if (name.length == 0) {
            setNameError(true);
            checkErrors = false;
        }
        if (description.length == 0) {
            setDescriptionError(true);
            checkErrors = false;
        }
        if (host.length == 0) {
            setHostError(true);
            checkErrors = false;
        }
        if (image.length == 0) {
            setImageError(true);
            checkErrors = false;
        }
        if (checkErrors) {
          setStep(step+1);
        }
      }

      if (step == 3 || step == 4) {
        setStep(step+1);
      }

    }
  
    const handleBack = () => {
        setStep(step-1);
    }

    const addApi = () => {
        console.log(            {
            name: name,
            description: description,
            host: host,
            image: image,
            status: status ? "ACTIVE" : "DISABLED",
            certification: certification,
            providerId: providerId,

            categories: categorySelected,
            countries: countrySelected,

            plan0Count:freeTrialCount,
            plan0Description: freeTrialDesc,
            plan1Price:initialPrice,
            plan1Count:initialCount,
            plan1Description: initialDesc,
            plan1Stripe:initialStripe,
            plan2Price:mediumPrice,
            plan2Count:mediumCount,
            plan2Description: mediumDesc,
            plan2Stripe:mediumStripe,
            plan3Price:advancedPrice,
            plan3Description: advancedDesc,
            plan3Count:advancedCount,
            plan3Stripe:advancedStripe,
            plan0Desc: freeTrialDesc,
            plan1Desc: initialDesc,
            plan2Desc: mediumDesc,
            plan3Desc: advancedDesc,

            endpoints: endpoints
        });
        setAddApiError("loading");
        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/add-api",
            {
                name: name,
                description: description,
                host: host,
                image: image,
                status: status ? "ACTIVE" : "DISABLED",
                certification: certification,
                providerId: providerId,

                categories: categorySelected,
                countries: countrySelected,

                plan0Count:freeTrialCount,
                plan0Description: freeTrialDesc,
                plan1Price:initialPrice,
                plan1Count:initialCount,
                plan1Description: initialDesc,
                plan1Stripe:initialStripe,
                plan2Price:mediumPrice,
                plan2Count:mediumCount,
                plan2Description: mediumDesc,
                plan2Stripe:mediumStripe,
                plan3Price:advancedPrice,
                plan3Description: advancedDesc,
                plan3Count:advancedCount,
                plan3Stripe:advancedStripe,
                plan0Desc: freeTrialDesc,
                plan1Desc: initialDesc,
                plan2Desc: mediumDesc,
                plan3Desc: advancedDesc,

                endpoints: endpoints
            }
        )
        .then(res => {
            console.log(res);
            setAddApiError("success");
        })
        .catch((err) => {
            console.log(err);
            setAddApiError("error")
        })   
    }
  
    // step1
    const [providerId, setProviderId] = useState("");
    const [providerArray, setProviderArray] = useState([]);
    const getProviderId = (prodiverId) => {
      setProviderId(prodiverId)
    }
  
    // step2
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [host, setHost] = useState("");
    const [image, setImage] = useState("");
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [hostError, setHostError] = useState("");
    const [imageError, setImageError] = useState("");
    const [status, setStatus] = useState(true);
    const [certification, setCertification] = useState(false);
  
    // step3
    const [categoryArray, setCategoryArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [categorySelected, setCategorySelected] = useState([]);
    const [countrySelected, setCountrySelected] = useState([]);

    // step4
    const [freeTrialCount, setFreeTrialCount] = useState(0);
    const [initialPrice, setInitialPrice] = useState(0);
    const [initialCount, setInitialCount] = useState(0);
    const [mediumPrice, setMediumPrice] = useState(0);
    const [mediumCount, setMediumCount] = useState(0);
    const [advancedPrice, setAdvancedPrice] = useState(0);
    const [advancedCount, setAdvancedCount] = useState(0);
    const [initialStripe, setInitialStripe] = useState("");
    const [mediumStripe, setMediumStripe] = useState("");
    const [advancedStripe, setAdvancedStripe] = useState("");
    const [freeTrialDesc, setFreeTrialDesc] = useState("");
    const [initialDesc, setInitialDesc] = useState("");
    const [mediumDesc, setMediumDesc] = useState("");
    const [advancedDesc, setAdvancedDesc] = useState("");

    // step5
    const [endpoints, setEndpoints] = useState([{
        name: "",
        description: "",
        path: "",
        httpmethod: "GET",
        parameters: [
            {
                name: "",
                description: "",
                type: "PATH",
                dataType: "String",
                required: false,
                isArray: false
            }
        ]
    }]);
  
    return (
        <div className="admin_add_api_flex">
        <div className="steps_indicator">
            <div className={"step_indicator" + (step == 1 ? " active" : "")}>
                <div className="step_icon">1</div>
                <div className="step_indicator_info">
                    <h4>Step 1</h4>
                    <p>Creación el proveedor con su información</p>
                </div>
            </div>
            <div className={"step_indicator" + (step == 2 ? " active" : "")}>
                <div className="step_icon">2</div>
                <div className="step_indicator_info">
                    <h4>Step 2</h4>
                    <p>Creación de Ficha para el Marketplace</p>
                </div>
            </div>
            <div className={"step_indicator" + (step == 3 ? " active" : "")}>
                <div className="step_icon">3</div>
                <div className="step_indicator_info">
                    <h4>Step 3</h4>
                    <p>Configuración de catálogos y países de la API</p>
                </div>
            </div>
            <div className={"step_indicator" + (step == 4 ? " active" : "")}>
                <div className="step_icon">4</div>
                    <div className="step_indicator_info">
                    <h4>Step 4</h4>
                    <p>Configuración de planes</p>
                </div>
            </div>
            <div className={"step_indicator" + (step == 5 ? " active" : "")}>
                <div className="step_icon">5</div>
                <div className="step_indicator_info">
                    <h4>Step 5</h4>
                    <p>Configuración de documentación</p>
                </div>
            </div>
        </div>
          { step == 1 &&
          <Step1
            getProviderId={getProviderId}
            handleNext={handleNext}
            providerId={providerId}
            providerArray={providerArray}
            setProviderArray={setProviderArray}
          /> }
          { step == 2 && <Step2 providerId={providerId}
                                handleNext={handleNext}
                                handleBack={handleBack}
                                name={name}
                                setName={setName}
                                description={description}
                                setDescription={setDescription}
                                host={host}
                                setHost={setHost}
                                image={image}
                                setImage={setImage}
                                status={status}
                                setStatus={setStatus}
                                certification={certification}
                                setCertification={setCertification}
                                nameError={nameError}
                                descriptionError={descriptionError}
                                hostError={hostError}
                                imageError={imageError}
                                setNameError={setNameError}
                                setDescriptionError={setDescriptionError}
                                setHostError={setHostError}
                                setImageError={setImageError}
          /> }
          {
            step == 3 && <Step3
                          name={name}
                          description={description}
                          host={host}
                          image={image}
                          status={status}
                          certification={certification}
                          handleBack={handleBack}
                          categoryArray={categoryArray}
                          setCategoryArray={setCategoryArray}
                          countryArray={countryArray}
                          setCountryArray={setCountryArray}
                          categorySelected={categorySelected}
                          setCategorySelected={setCategorySelected}
                          countrySelected={countrySelected}
                          setCountrySelected={setCountrySelected}
                          providerId={providerId}
                          handleNext={handleNext}
            />
          }
          {
            step == 4 && <Step4
                handleBack={handleBack}
                handleNext={handleNext}
                freeTrialCount={freeTrialCount}
                setFreeTrialCount={setFreeTrialCount}
                initialPrice={initialPrice}
                setInitialPrice={setInitialPrice}
                initialCount={initialCount}
                setInitialCount={setInitialCount}
                mediumPrice={mediumPrice}
                setMediumPrice={setMediumPrice}
                mediumCount={mediumCount}
                setMediumCount={setMediumCount}
                advancedPrice={advancedPrice}
                setAdvancedPrice={setAdvancedPrice}
                advancedCount={advancedCount}
                setAdvancedCount={setAdvancedCount}
                initialStripe={initialStripe}
                setInitialStripe={setInitialStripe}
                mediumStripe={mediumStripe}
                setMediumStripe={setMediumStripe}
                advancedStripe={advancedStripe}
                setAdvancedStripe={setAdvancedStripe}
                freeTrialDesc={freeTrialDesc}
                setFreeTrialDesc={setFreeTrialDesc}
                initialDesc={initialDesc}
                setInitialDesc={setInitialDesc}
                mediumDesc={mediumDesc}
                setMediumDesc={setMediumDesc}
                advancedDesc={advancedDesc}
                setAdvancedDesc={setAdvancedDesc}
            />
          }
          {
            step == 5 && <Step5
                handleBack={handleBack}
                handleNext={addApi}
                endpoints={endpoints}
                setEndpoints={setEndpoints}
                addApiError={addApiError}
            />
          }
        </div>
      );
}

export default AdminAddApi;