import React from "react";

function KYBIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 1"
      viewBox="0 0 57.88 57.88"
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.58 46.28V19.75l18.36-8.16 18.36 8.16v26.53"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M30.98 29.96h8.16v16.32H18.73V34.04h12.24"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M30.98 46.28V27.92c0-1.13-.91-2.04-2.04-2.04h-4.08c-1.13 0-2.04.91-2.04 2.04v6.12"
      ></path>
    </svg>
  );
}

export default KYBIcon;
