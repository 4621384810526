const apiDocGenerate = {
    cURLcURL: (apiDoc, publicApiKey) => {
      const params = apiDoc.EndPointParams.map((apiParam, apiParamIndex) => {
        return `${apiParamIndex !== 0 ? '    ' : ''}--form '${apiParam.ParamName}=${apiParam.ParamDescription}'`;
      }).join(' \\\n');
  
      return `curl --location --request POST 'https://services.scorce.io/api/apis/consume' \\
  --header 'PublicApiKey: ${publicApiKey}' \\
  ${params}`;
    },
  
    NodeJsAxios: (apiDoc, publicApiKey) => {
      const params = apiDoc.EndPointParams.map(apiParam => {
        return `{"name":"${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"`;
      }).join(',\n      ');
  
      return `const axios = require('axios');
  const data = {
    idEndPoint: "${apiDoc.EndPointId}",
    params: [
      ${params}
    ]
  };
  
  const config = {
    method: 'post',
    url: 'https://services.scorce.io/api/apis/consume',
    headers: { 
      'PublicApiKey': '${publicApiKey}'
    },
    data: data
  };
  
  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });`;
    },

    CSharpHttpClient: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
            return `new { name = "${apiParam.ParamName}", value = "${apiParam.ParamDescription}" }`;
          }).join(",\n    ");
    
        return `using System.Net.Http;
    using System.Threading.Tasks;
    using Newtonsoft.Json;
    using System.Text;
    
    var client = new HttpClient();
    var request = new HttpRequestMessage {
        Method = HttpMethod.Post,
        RequestUri = new Uri("https://services.scorce.io/api/apis/consume"),
        Headers = {
            { "PublicApiKey", "${publicApiKey}" },
        },
        Content = new StringContent(JsonConvert.SerializeObject(new {
            idEndPoint = "${apiDoc.EndPointId}",
            params = new[] {
                ${params}
            }
        }), Encoding.UTF8, "application/json")
    };
    
    var response = await client.SendAsync(request);
    response.EnsureSuccessStatusCode();
    var body = await response.Content.ReadAsStringAsync();
    Console.WriteLine(body);`;
      },
    
      CSharpRestSharp: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
            return `new { name = "${apiParam.ParamName}", value = "${apiParam.ParamDescription}" }`;
          }).join(",\n    ");
    
        return `var client = new RestClient("https://services.scorce.io/api/apis/consume");
    var request = new RestRequest(Method.POST);
    request.AddHeader("PublicApiKey", "${publicApiKey}");
    request.RequestFormat = DataFormat.Json;
    request.AddJsonBody(new {
        idEndPoint = "${apiDoc.EndPointId}",
        params = new List<Parameter> {
            ${params}
        }
    });
    IRestResponse response = client.Execute(request);
    Console.WriteLine(response.Content);`;
      },
    
      Darthttp: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{"name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `import 'package:http/http.dart' as http;
    import 'dart:convert';
    
    var url = Uri.parse('https://services.scorce.io/api/apis/consume');
    var headers = {
      'PublicApiKey': '${publicApiKey}',
      'Content-Type': 'application/json'
    };
    var body = jsonEncode({
      'idEndPoint': '${apiDoc.EndPointId}',
      'params': [${params}]
    });
    
    var response = await http.post(url, headers: headers, body: body);
    print('Response status: \${response.statusCode}');
    print('Response body: \${response.body}');`;
      },
    
      GoNative: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{"name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `package main
    
    import (
        "bytes"
        "encoding/json"
        "net/http"
    )
    
    func main() {
        apiUrl := "https://services.scorce.io/api/apis/consume"
        data := map[string]interface{}{
            "idEndPoint": "${apiDoc.EndPointId}",
            "params": []interface{}{${params}}
        }
        jsonData, _ := json.Marshal(data)
    
        req, _ := http.NewRequest("POST", apiUrl, bytes.NewBuffer(jsonData))
        req.Header.Set("PublicApiKey", "${publicApiKey}")
        req.Header.Set("Content-Type", "application/json")
    
        client := &http.Client{}
        resp, _ := client.Do(req)
        defer resp.Body.Close()
        // Handle response
    }`;
      },
    
      ObjectiveCNSURLSession: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `@{@"name": @"${apiParam.ParamName}", @"value": @"${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `NSURLSession *session = [NSURLSession sharedSession];
    NSURL *url = [NSURL URLWithString:@"https://services.scorce.io/api/apis/consume"];
    NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:url];
    request.HTTPMethod = @"POST";
    [request setValue:@"${publicApiKey}" forHTTPHeaderField:@"PublicApiKey"];
    [request setValue:@"application/json" forHTTPHeaderField:@"Content-Type"];
    
    NSDictionary *body = @{
        @"idEndPoint": @"${apiDoc.EndPointId}",
        @"params": @[${params}]
    };
    request.HTTPBody = [NSJSONSerialization dataWithJSONObject:body options:0 error:nil];
    
    NSURLSessionDataTask *dataTask = [session dataTaskWithRequest:request
        completionHandler:^(NSData *data, NSURLResponse *response, NSError *error) {
            // Handle response
        }];
    [dataTask resume];`;
      },
    
      HTTPHTTP: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}": "${apiParam.ParamDescription}"`;
        }).join(', ');
    
        return `POST /api/apis/consume HTTP/1.1
    Host: services.scorce.io
    PublicApiKey: ${publicApiKey}
    Content-Type: application/json
    
    {
      "idEndPoint": "${apiDoc.EndPointId}",
      "params": [
        ${params}
      ]
    }`;
      },

      JavaOkHttp: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `new Pair<>("${apiParam.ParamName}", "${apiParam.ParamDescription}")`;
        }).join(",\n");
    
        return `OkHttpClient client = new OkHttpClient().newBuilder().build();
    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{\\n  \\"idEndPoint\\": \\"${apiDoc.EndPointId}\\",\\n  \\"params\\": [" + ${params} + "]\\n}");
    Request request = new Request.Builder()
      .url("https://services.scorce.io/api/apis/consume")
      .method("POST", body)
      .addHeader("PublicApiKey", "${publicApiKey}")
      .addHeader("Content-Type", "application/json")
      .build();
    Response response = client.newCall(request).execute();`;
      },
    
      JavaUnirest: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `\\"${apiParam.ParamName}\\": \\"${apiParam.ParamDescription}\\"`;
        }).join(", ");
    
        return `HttpResponse<String> response = Unirest.post("https://services.scorce.io/api/apis/consume")
      .header("PublicApiKey", "${publicApiKey}")
      .header("Content-Type", "application/json")
      .body("{\\"idEndPoint\\": \\"${apiDoc.EndPointId}\\", \\"params\\": [{" + ${params} + "}]}")
      .asString();`;
      },
    
      JavaScriptFetch: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{"name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `fetch("https://services.scorce.io/api/apis/consume", {
      method: "POST",
      headers: {
        "PublicApiKey": "${publicApiKey}",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        idEndPoint: "${apiDoc.EndPointId}",
        params: [${params}]
      })
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));`;
      },
    
      JavaScriptjQuery: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{"name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `$.ajax({
      url: "https://services.scorce.io/api/apis/consume",
      type: "POST",
      headers: {
        "PublicApiKey": "${publicApiKey}",
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        idEndPoint: "${apiDoc.EndPointId}",
        params: [${params}]
      }),
      success: function(response) {
        console.log(response);
      },
      error: function(xhr, status, error) {
        console.error(error);
      }
    });`;
      },
    
      JavaScriptXHR: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{"name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://services.scorce.io/api/apis/consume", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("PublicApiKey", "${publicApiKey}");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.responseText);
      }
    };
    var data = JSON.stringify({
      "idEndPoint": "${apiDoc.EndPointId}",
      "params": [${params}]
    });
    xhr.send(data);`;
      },
    
      Clibcurl: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `\\"${apiParam.ParamName}\\": \\"${apiParam.ParamDescription}\\"`;
        }).join(", ");
    
        return `CURL *curl;
    CURLcode res;
    curl_global_init(CURL_GLOBAL_ALL);
    curl = curl_easy_init();
    if(curl) {
        const char *data = "{\\"idEndPoint\\": \\"${apiDoc.EndPointId}\\", \\"params\\": [{" + ${params} + "}]}";
    
        struct curl_slist *headers = NULL;
        headers = curl_slist_append(headers, "Content-Type: application/json");
        headers = curl_slist_append(headers, "PublicApiKey: ${publicApiKey}");
        curl_easy_setopt(curl, CURLOPT_URL, "https://services.scorce.io/api/apis/consume");
        curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
        curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
    
        res = curl_easy_perform(curl);
        if(res != CURLE_OK)
            fprintf(stderr, "curl_easy_perform() failed: %s\\n", curl_easy_strerror(res));
    
        curl_easy_cleanup(curl);
        curl_slist_free_all(headers);
    }
    curl_global_cleanup();`;
      },

      RRCurl: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `list(name = "${apiParam.ParamName}", value = "${apiParam.ParamDescription}")`;
        }).join(", ");
    
        return `library(RCurl)
    httpheader <- c('PublicApiKey' = '${publicApiKey}', 'Content-Type' = 'application/json')
    body <- list(
      idEndPoint = "${apiDoc.EndPointId}",
      params = list(${params})
    )
    response <- postForm("https://services.scorce.io/api/apis/consume",
                         .opts = list(postfields = toJSON(body), httpheader = httpheader, ssl.verifypeer = FALSE))
    print(response)`;
      },
    
      Rhttr: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `list(name = "${apiParam.ParamName}", value = "${apiParam.ParamDescription}")`;
        }).join(", ");
    
        return `library(httr)
    response <- POST("https://services.scorce.io/api/apis/consume",
                     add_headers(PublicApiKey = '${publicApiKey}'),
                     body = list(
                       idEndPoint = "${apiDoc.EndPointId}",
                       params = list(${params})
                     ), 
                     encode = "json")
    content(response)`;
      },
    
      PHPcURL: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}" => "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `<?php
    $curl = curl_init();
    curl_setopt_array($curl, [
        CURLOPT_URL => "https://services.scorce.io/api/apis/consume",
        CURLOPT_RETURNTRANSFER => true,
        CURLOPT_CUSTOMREQUEST => "POST",
        CURLOPT_POSTFIELDS => json_encode([
            "idEndPoint" => "${apiDoc.EndPointId}",
            "params" => [${params}]
        ]),
        CURLOPT_HTTPHEADER => [
            "PublicApiKey: ${publicApiKey}",
            "Content-Type: application/json"
        ],
    ]);
    $response = curl_exec($curl);
    curl_close($curl);
    echo $response;
    ?>`;
      },
    
      PHPGuzzle: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}" => "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `<?php
    use GuzzleHttp\\Client;
    
    $client = new Client();
    $response = $client->request('POST', 'https://services.scorce.io/api/apis/consume', [
        'headers' => [
            'PublicApiKey' => '${publicApiKey}',
            'Content-Type' => 'application/json'
        ],
        'json' => [
            'idEndPoint' => '${apiDoc.EndPointId}',
            'params' => [${params}]
        ]
    ]);
    echo $response->getBody();
    ?>`;
      },
    
      PHPHTTPRequest2: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}" => "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `<?php
    require_once 'HTTP/Request2.php';
    $request = new HTTP_Request2();
    $request->setUrl('https://services.scorce.io/api/apis/consume');
    $request->setMethod(HTTP_Request2::METHOD_POST);
    $request->setConfig(array(
      'follow_redirects' => TRUE
    ));
    $request->setHeader(array(
      'PublicApiKey' => '${publicApiKey}',
      'Content-Type' => 'application/json'
    ));
    $request->setBody(json_encode(array(
      'idEndPoint' => '${apiDoc.EndPointId}',
      'params' => array(${params})
    )));
    try {
      $response = $request->send();
      if ($response->getStatus() == 200) {
        echo $response->getBody();
      }
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`;
      },
    
      PHPpeclhttp: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}" => "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `<?php
    $httpRequest = new HttpRequest();
    $httpRequest->setUrl('https://services.scorce.io/api/apis/consume');
    $httpRequest->setMethod(HTTP_METH_POST);
    $httpRequest->setHeaders(array(
      'PublicApiKey' => '${publicApiKey}',
      'Content-Type' => 'application/json'
    ));
    $httpRequest->setBody(json_encode(array(
      'idEndPoint' => '${apiDoc.EndPointId}',
      'params' => array(${params})
    )));
    
    try {
      $response = $httpRequest->send();
      if ($response->getResponseCode() == 200) {
        echo $response->getBody();
      }
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`;
      },

      OCamlCohttp: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `("name", "${apiParam.ParamName}"), ("value", "${apiParam.ParamDescription}")`;
        }).join("; ");
    
        return `open Lwt
    open Cohttp
    open Cohttp_lwt_unix
    
    let body = Cohttp_lwt.Body.of_string (
        "{\\n\\"idEndPoint\\": \\"${apiDoc.EndPointId}\\", \\n\\"params\\": [{" + ${params} + "}]\\n}"
    )
    
    let headers = Header.init ()
      |> fun h -> Header.add h "PublicApiKey" "${publicApiKey}"
      |> fun h -> Header.add h "Content-Type" "application/json"
    
    Client.post ~body ~headers (Uri.of_string "https://services.scorce.io/api/apis/consume")
    >>= fun (resp, body) -> Cohttp_lwt.Body.to_string body
    >>= fun body -> Lwt_io.printl body`;
      },
    
      PowerShellRestMethod: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}": "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `$headers = @{
        "PublicApiKey" = "${publicApiKey}"
        "Content-Type" = "application/json"
    }
    
    $body = @{
        idEndPoint = "${apiDoc.EndPointId}"
        params = @(${params})
    } | ConvertTo-Json
    
    $response = Invoke-RestMethod -Uri "https://services.scorce.io/api/apis/consume" -Method Post -Headers $headers -Body $body
    $response | ConvertTo-Json`;
      },
    
      Pythonhttpclient: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}": "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `import http.client
    import json
    
    conn = http.client.HTTPSConnection("services.scorce.io")
    payload = json.dumps({
        "idEndPoint": "${apiDoc.EndPointId}",
        "params": [${params}]
    })
    
    headers = {
        "PublicApiKey": "${publicApiKey}",
        "Content-Type": "application/json"
    }
    
    conn.request("POST", "/api/apis/consume", payload, headers)
    res = conn.getresponse()
    data = res.read()
    print(data.decode("utf-8"))`;
      },
    
      PythonRequests: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}": "${apiParam.ParamDescription}"`;
        }).join(", ");
    
        return `import requests
    import json
    
    url = "https://services.scorce.io/api/apis/consume"
    payload = json.dumps({
        "idEndPoint": "${apiDoc.EndPointId}",
        "params": [${params}]
    })
    headers = {
        "PublicApiKey": "${publicApiKey}",
        "Content-Type": "application/json"
    }
    
    response = requests.request("POST", url, headers=headers, data=payload)
    print(response.text)`;
      },
    
      RubyNetHTTP: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{"name" => "${apiParam.ParamName}", "value" => "${apiParam.ParamDescription}"}`;
        }).join(", ");
    
        return `require 'net/http'
    require 'uri'
    require 'json'
    
    uri = URI.parse("https://services.scorce.io/api/apis/consume")
    header = {
        'PublicApiKey' => '${publicApiKey}',
        'Content-Type' => 'application/json'
    }
    body = {
        :idEndPoint => "${apiDoc.EndPointId}",
        :params => [${params}]
    }.to_json
    
    http = Net::HTTP.new(uri.host, uri.port)
    http.use_ssl = true
    request = Net::HTTP::Post.new(uri.request_uri, header)
    request.body = body
    
    response = http.request(request)
    puts response.body`;
      },

      NodeJsNative: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{ "name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}" }`;
        }).join(", ");
    
        return `const https = require('https');
    
    const data = JSON.stringify({
      idEndPoint: "${apiDoc.EndPointId}",
      params: [${params}]
    });
    
    const options = {
      hostname: 'services.scorce.io',
      port: 443,
      path: '/api/apis/consume',
      method: 'POST',
      headers: {
        'PublicApiKey': '${publicApiKey}',
        'Content-Type': 'application/json'
      }
    };
    
    const req = https.request(options, (res) => {
      let data = '';
      res.on('data', (chunk) => {
        data += chunk;
      });
      res.on('end', () => {
        console.log(data);
      });
    });
    
    req.on('error', (e) => {
      console.error(e);
    });
    
    req.write(data);
    req.end();`;
      },
    
      NodeJsRequest: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{ "name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}" }`;
        }).join(", ");
    
        return `const request = require('request');
    
    const options = {
      method: 'POST',
      url: 'https://services.scorce.io/api/apis/consume',
      headers: {
        'PublicApiKey': '${publicApiKey}',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idEndPoint: "${apiDoc.EndPointId}",
        params: [${params}]
      })
    };
    
    request(options, function (error, response, body) {
      if (error) throw new Error(error);
      console.log(body);
    });`;
      },
    
      NodeJsUnirest: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `{ "name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}" }`;
        }).join(", ");
    
        return `const unirest = require('unirest');
    
    unirest('POST', 'https://services.scorce.io/api/apis/consume')
      .headers({
        'PublicApiKey': '${publicApiKey}',
        'Content-Type': 'application/json'
      })
      .send(JSON.stringify({
        idEndPoint: "${apiDoc.EndPointId}",
        params: [${params}]
      }))
      .end(function (res) {
        if (res.error) throw new Error(res.error);
        console.log(res.raw_body);
      });`;
      },
    
      ShellHttpie: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}=${apiParam.ParamDescription}"`;
        }).join(" ");
    
        return `http POST https://services.scorce.io/api/apis/consume PublicApiKey:${publicApiKey} ${params}`;
      },
    
      Shellwget: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `"${apiParam.ParamName}=${apiParam.ParamDescription}"`;
        }).join("&");
    
        return `wget --method POST --body-data '{ "idEndPoint": "${apiDoc.EndPointId}", "params": [${params}] }' --header 'PublicApiKey: ${publicApiKey}' --header 'Content-Type: application/json' --output-document - https://services.scorce.io/api/apis/consume`;
      },
    
      SwiftURLSession: (apiDoc, publicApiKey) => {
        const params = apiDoc.EndPointParams.map(apiParam => {
          return `["name": "${apiParam.ParamName}", "value": "${apiParam.ParamDescription}"]`;
        }).join(", ");
    
        return `import Foundation
    
    var semaphore = DispatchSemaphore (value: 0)
    
    let parameters = "{\\"idEndPoint\\": \\"${apiDoc.EndPointId}\\", \\"params\\": [${params}]}"
    let postData = parameters.data(using: .utf8)
    
    var request = URLRequest(url: URL(string: "https://services.scorce.io/api/apis/consume")!,timeoutInterval: Double.infinity)
    request.addValue("${publicApiKey}", forHTTPHeaderField: "PublicApiKey")
    request.addValue("application/json", forHTTPHeaderField: "Content-Type")
    
    request.httpMethod = "POST"
    request.httpBody = postData
    
    let task = URLSession.shared.dataTask(with: request) { data, response, error in 
      guard let data = data else {
        print(String(describing: error))
        semaphore.signal()
        return
      }
      print(String(data: data, encoding: .utf8)!)
      semaphore.signal()
    }
    
    task.resume()
    semaphore.wait()`;
      }

  };

  
  
  export default apiDocGenerate;
  