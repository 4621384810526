import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import UserProfileIcon from "../../icons/user_profile";
import UploadIcon from "../../icons/upload";
import DeleteIcon from "../../icons/delete";
import UndoIcon from "../../icons/undo";

import LoadingIcon from "../../icons/loading";
import SuccessIcon from "../../icons/success";
import ErrorIcon from "../../icons/error";



function ProfileInfo(props) {

  const [avatarSelected, setAvatarSelected] = useState("");
  const [avatarLoadError, setAvatarLoadError] = useState("");
  const avatarInputRef = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [middleNameError, setMiddleNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [updateInfoBtn, setUpdateInfoBtn] = useState("default"); // default - loading - success - error

  useEffect(() => {
    if (!props.profileInfoLoad) {
      try {
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/profile/get-info`,
          { clientId: props.user.userId }
        )
        .then(res => {
          props.setProfileInfo(res.data);
          props.setprofileInfoLoad(true);
          setFirstName(res.data.Name);
          setMiddleName(res.data.SecondLastname);
          setLastName(res.data.FirstLastname);
          setPhone(res.data.Telephone);
        })
      } catch (error) {}  
    } else {
      setFirstName(props.profileInfo.Name);
      setMiddleName(props.profileInfo.SecondLastname);
      setLastName(props.profileInfo.FirstLastname);
      setPhone(props.profileInfo.Telephone);
    }
  }, []);

  const handleAvatarUpload = (e) => {
    console.log("a");
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      // Validate file format and size
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        if (file.size > 100000 && file.size < 800000) {
          reader.onload = (event) => {
            setAvatarSelected(event.target.result);
            setAvatarLoadError('');
          };
          reader.readAsDataURL(file);
        } else {
          // setAvatarLoadError('File size should be between 100KB and 800KB.');
          setAvatarLoadError("size");
        }
      } else {
        // setAvatarLoadError('Invalid file format. Please select a PNG or JPEG image.');
        setAvatarLoadError("type");
      }
    }
  }

  const clearAvatarInput = () => {
    avatarInputRef.current.value = null;
    setAvatarSelected('');
    setAvatarLoadError('');
  };

  const deleteAvatar = () => {
    avatarInputRef.current.value = null;
    setAvatarSelected('none');
    setAvatarLoadError('');
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(false);
  }

  const handleMiddleName = (e) => {
    setMiddleName(e.target.value);
    setMiddleNameError(false);
  }

  const handleLastName = (e) => {
    setLastName(e.target.value);
    setLastNameError(false);
  }

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setPhoneError(false);
  }

  const handleEnterPress = (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        updateProfileInfoFunction();
      }
  }

  const updateProfileInfoFunction = () => {
    let checkErrors = true;
    const nameRegex = /^[A-Za-z\u00C0-\u024F\u0400-\u04FF\u0500-\u052F\s'-.]+$/;
    const phoneNumberRegex = /^[\d\s()+-]*\d[\d\s()+-]*$/;

    if (firstName.length == 0 || !nameRegex.test(firstName)) {
      setFirstNameError(true);
      checkErrors = false;
    }

    if (middleName != "" && !nameRegex.test(middleName)) {
      setMiddleNameError(true);
      checkErrors = false;
    }

    if (lastName.length == 0 || !nameRegex.test(lastName)) {
      setLastNameError(true);
      checkErrors = false;
    }

    if (phone.length == 0 || !phoneNumberRegex.test(phone)) {
      setPhoneError(true);
      checkErrors = false;
    }

    if (checkErrors) {
      try {
        const userInfo = {
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          phone: phone,
          avatar: avatarSelected,
          clientId: props.user.userId
        };

        setUpdateInfoBtn("loading");
  
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/profile/update-info`,
          userInfo
        )
          .then(res => {
            console.log(res.data);
            if (res.data == "success") {
                setUpdateInfoBtn("success");
                const usernameCookie = middleName != null ? firstName + " " + middleName + " " + lastName : firstName + " " + lastName;
                const avatarCookie = avatarSelected == "none" ? "" : avatarSelected == "" ? props.user.avatar : avatarSelected;
                localStorage.setItem("user", JSON.stringify({
                  userId: props.user.userId,
                  userKey: props.user.userKey,
                  username: usernameCookie,
                  avatar: avatarCookie
                }));
                props.setUser({
                  userId: props.user.userId,
                  userKey: props.user.userKey,
                  username: usernameCookie,
                  avatar: avatarCookie
                });
                clearAvatarInput();
            } else {
              setUpdateInfoBtn("error");
            }
          })
      } catch (error) {
        setUpdateInfoBtn("error");
      }
    }


  }

  return (
    <div className="wrapper">
      <h1 className="wrapper_titler">{props.lang == "english" ? "General information" : "Información general"}</h1>
      <p>{props.lang == "english" ? "Update your basic details below to keep your profile information accurate and up to date." : "Actualice sus datos básicos a continuación para mantener la información de su perfil exacta y actualizada."}</p>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Profile picture" : "Foto de perfil"}</label>
        </div>
        <div className="input_side">
          <div className="profile_img_upload_container">
            <div
              className={"profil_img_preview" + (((!props.avatar && avatarSelected == "") || avatarSelected == "none") ? " profil_img_preview_empty" : "")}
              style={
                avatarSelected == "none" ? {} :
                avatarSelected ? {backgroundImage: "url('" + avatarSelected +"')"} :
                props.avatar ? {backgroundImage: "url('" + props.avatar +"')"} : {}
              }
              >
                
              {((!props.avatar && avatarSelected == "") || avatarSelected == "none") && <UserProfileIcon />}
            </div>
            <input type="file" ref={avatarInputRef} id="avatar"  accept=".jpg, .jpeg, .png" onChange={(e) => handleAvatarUpload(e)}/>
            <div for="avatar" className="profile_img_upload_wrapper">
              <div className="profile_img_upload_btns">
                <div
                  className="profile_img_upload_btn"
                  id="undo_profile_img"
                  onClick={() => avatarSelected ? clearAvatarInput() : null}
                  style={avatarSelected ? {} : {opacity: 0}}
                  >
                  <UndoIcon />
                </div>
                <label for="avatar" className="profile_img_upload_btn" id="upload_profile_img">
                  <UploadIcon />
                </label>
                <div
                  className="profile_img_upload_btn"
                  id="delete_profile_img"
                  onClick={() => ((props.avatar || avatarSelected) && avatarSelected != "none") ? deleteAvatar() : null}
                  style={((props.avatar || avatarSelected) && avatarSelected != "none") ? {} : {opacity: 0}}
                  >
                  <DeleteIcon />
                </div>
              </div>
              <div><label for="avatar">{props.lang == "english" ? "Click to upload" : "Haga clic para cargar"}</label>{props.lang == "english" ? " o arrastrar y soltar" : ""}</div>
              <div>
                <span className={avatarLoadError == "type" ? "avatar_error" : ""}>PNG, JPG</span>
                <span className={avatarLoadError == "size" ? "avatar_error" : ""}>({props.lang == "english" ? "100KB to 800KB" : "De 100 KB a 800 KB"})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Full name" : "Nombre y apellidos"}</label>
        </div>
        <div className="input_side input_side_padding">
        <div className={"input_container" + (firstNameError ? " input_container_error" : "")}>
            <label>{props.lang == "english" ? "First name" : "Nombre"}</label>
            {
              props.profileInfoLoad
              ? <input type="text" onKeyUp={(e) => handleEnterPress(e)} value={firstName} onChange={(e) => handleFirstName(e)}/>
              : <div className="input_skeleton"></div>
            }
          </div>
          <div className={"input_container" + (middleNameError ? " input_container_error" : "")}>
            <label>{props.lang == "english" ? "Middle name" : "Segundo Apellido"}</label>
            {
              props.profileInfoLoad
              ? <input type="text" onKeyUp={(e) => handleEnterPress(e)} value={middleName} onChange={(e) => handleMiddleName(e)}/>
              : <div className="input_skeleton"></div>
            }
          </div>
          <div className={"input_container" + (lastNameError ? " input_container_error" : "")}>
            <label>{props.lang == "english" ? "Last name" : "Primer Apellido"}</label>
            {
              props.profileInfoLoad
              ? <input type="text" onKeyUp={(e) => handleEnterPress(e)} value={lastName} onChange={(e) => handleLastName(e)}/>
              : <div className="input_skeleton"></div>
            }
          </div>
        </div>
      </div>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Contact" : "Contacto"}</label>
        </div>
        <div className="input_side input_side_padding">
          <div className="input_container">
            <label>{props.lang == "english" ? "Email address" : "E-mail"}</label>
            {
              props.profileInfoLoad
              ? <input type="text" value={props.profileInfo.Email} disabled/>
              : <div className="input_skeleton"></div>
            }
          </div>
          <div className={"input_container" + (phoneError ? " input_container_error" : "")}>
            <label>{props.lang == "english" ? "Phone number" : "Número de teléfono"}</label>
            {
              props.profileInfoLoad
              ? <input type="text" onKeyUp={(e) => handleEnterPress(e)} value={phone} onChange={(e) => handlePhone(e)}/>
              : <div className="input_skeleton"></div>
            }
          </div>
        </div>
      </div>
      <div className="two_side_submit">
        {
            updateInfoBtn != "default" &&
                <div className={"btn btn_indicator btn_primary" + (
                    updateInfoBtn == "loading" ? " btn_loading btn_icon":
                    updateInfoBtn == "success" ? " btn_success btn_icon" :
                    " btn_error"
                )}>
                    {
                        updateInfoBtn == "loading" ? <LoadingIcon /> :
                        updateInfoBtn == "success" ? <SuccessIcon /> :
                        <ErrorIcon />
                    }
                    {
                      updateInfoBtn == "error" &&
                      <span>{props.lang == "english" ? "An error occurred. Please try again later." : "Se ha producido un error. Vuelva a intentarlo más tarde."}</span>
                    }
                </div>
        }
        <div className={"btn btn_primary" + (updateInfoBtn == "loading" ? " btn_loading" : "")} onClick={() => updateInfoBtn != "loading" ? updateProfileInfoFunction() : null}>
          <span>{props.lang == "english" ? "Update information" : "Modificar información"}</span>
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;