import React, { useState, useEffect } from "react";

import { useNavigate, useLocation  } from "react-router-dom";

import Navbar from "../Navbar/Navbar";

import ProfileInfo from "./ProfileInfo";
import ProfileBilling from "./ProfileBilling";
import ProfilePassword from "./ProfilePassword";

import UserProfileIcon from "../../icons/user_profile";
import LogoutIcon from "../../icons/logout";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import "./profile.css";

import ReactGA from "react-ga4";

function Profile(props) {

  useEffect(() => {
    ReactGA.send({ hitType: "pageView", page: "/profile", title: "Profile" });
  })

  const navigate = useNavigate();
  const location = useLocation();

  const [tabHeader, setTabHeader] = useState("info"); // info - billing - password

  const logout = async () => {  
    localStorage.removeItem("user");
    props.setUser({userId: "", username: "", userKey: "", avatar: ""});
    props.setIsConnected(false);
    window.location.reload(false);
  }

  // ProfileInfo
  const [profileInfoLoad, setprofileInfoLoad] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});

  // ProfileBilling
  const [profileBillingLoad, setProfileBillingLoad] = useState(false);
  const [profileBilling, setProfileBilling] = useState({});

  useEffect(() => {
    // Check if the navigation state has 'tab' set to 'billing'
    if (location.state?.tab === "billing") {
        setTabHeader("billing");
    } else {
        setTabHeader("info"); // Default or reset to 'info'
    }
  }, [location.state]);

  return (
    <div className="body" style={{background: "#fcfcfe"}}>
      <div className="navbar">
        <Navbar navActive="profile" lang={props.lang} />        

        <div className="title_search">
          <div className="title_search_info">
            <div className="title_search_flex">
              <div
                className={!props.user.avatar ? "title_search_img_container" : "title_search_avatar_container"}
                style={props.user.avatar ? {backgroundImage: "url('" + props.user.avatar +"')"} : {}}
              >
                {
                  !props.user.avatar &&
                  <div className="title_search_img" >
                    <UserProfileIcon />
                  </div>
                }
              </div>
              <div className="title_search_text">
                <h1>{props.user.username}</h1>
                <div className="profile_key">
                  <span className="profile_key_text">
                    <span>{props.lang == "english" ? "Public API Key" : "Clave API pública"}</span>
                    {props.user.userKey}
                  </span>
                  <CopyToClipboard text={props.user.userKey}>
                    <span className="profile_key_copy disable_select">{props.lang == "english" ? "Copy" : "Copiar"}</span>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="tabs tabs_page_header">
              <span className={"tab" + (tabHeader == "info" ? " active" : "")} onClick={() => setTabHeader("info")}>{props.lang == "english" ? "General information" : "Información general"}</span>
              <span className={"tab" + (tabHeader == "billing" ? " active" : "")} onClick={() => setTabHeader("billing")}>{props.lang == "english" ? "Billing information" : "Información de facturación"}</span>
              <span className={"tab" + (tabHeader == "password" ? " active" : "")} onClick={() => setTabHeader("password")}>{props.lang == "english" ? "Password" : "Contraseña"}</span>
              <div className="tab_controll" style={
                props.lang == "english" ?
                (
                  tabHeader == "info" ? {left: 30} :
                  tabHeader == "billing" ? {left: 206} :
                  {left: 346.5}  
                )
                :
                (
                  tabHeader == "info" ? {left: 30.5} :
                  tabHeader == "billing" ? {left: 236.5} :
                  {left: 414.5}  
                )
              }></div>
              <div className="tabs_header_side">
                <div className="checkbox_btns">
                  <div className={"checkbox_btn" + (props.lang == "spanish" ? " active" : "")} onClick={() => {props.setLang("spanish"); localStorage.setItem("lang", "spanish");}}>{props.lang == "english" ? "Spanish" : "Español"}</div>
                  <div className={"checkbox_btn" + (props.lang == "english" ? " active" : "")} onClick={() => {props.setLang("english"); localStorage.setItem("lang", "english");}}>{props.lang == "english" ? "English" : "Inglés"}</div>
                </div>
                <div className="btn btn_glass logout_btn btn_icon" onClick={() => logout()}>
                  <LogoutIcon />
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      {
        tabHeader == "info" ?
          <ProfileInfo
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            profileInfoLoad={profileInfoLoad}
            setprofileInfoLoad={setprofileInfoLoad}
            user={props.user}
            setUser={props.setUser}
            avatar={props.user.avatar}
            lang={props.lang}
          />
        :
        tabHeader == "billing" ?
          <ProfileBilling
            profileBilling={profileBilling}
            setProfileBilling={setProfileBilling}
            profileBillingLoad={profileBillingLoad}
            setProfileBillingLoad={setProfileBillingLoad}
            clientId={props.user.userId}
            lang={props.lang}
          />
        :
          <ProfilePassword
            clientId={props.user.userId}
            lang={props.lang}
          />
      }
    </div>
  );
}

export default Profile;