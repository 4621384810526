import React from "react";

function AddApiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 -960 960 960"
    >
      <path d="M120-120v-720h320v80H200v560h560v-240h80v320H120zm520-400v-120H520v-80h120v-120h80v120h120v80H720v120h-80z"></path>
    </svg>
  );
}

export default AddApiIcon;
