import React from "react";

function ArrowBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M400-80L0-480l400-400 56 57-343 343 343 343-56 57z"></path>
    </svg>
  );
}

export default ArrowBack;
