import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 -960 960 960"
    >
      <path d="M216-160l-56-56 464-464H360v-80h400v400h-80v-264L216-160z"></path>
    </svg>
  );
}

export default Icon;
