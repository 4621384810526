import React from "react";

function FilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M382-190v-94h196v94H382zM218-434v-93h524v93H218zM95-677v-94h771v94H95z"></path>
    </svg>
  );
}

export default FilterIcon;
