import React, { useState, useEffect } from "react";

import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";

import Auth from "./components/Auth/Auth";
import Marketplace from "./components/Marketplace/Marketplace";
import Payments from "./components/Payments/Payments";
import Api from "./components/Api/Api";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Profile/Profile";
import Sandbox from "./components/Sandbox/Sandbox";
import Sitemap from "./components/Sitemap";

import Admin from "./components/Admin/Admin";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import Contact from "./components/Contact/Contact";

import ReactGA from "react-ga4";

ReactGA.initialize("G-HQYTH3BVV5");

function App() {

  const [isConnected, setIsConnected] = useState(false);
  const [isConnectedLoad, setIsConnectedLoad] = useState(false);
  const [user, setUser] = useState({userId: "", username: "", userKey: "", avatar: ""});

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [lang, setLang] = useState();

  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) {
      setLang(language);
    } else {
      localStorage.setItem("lang", "spanish");
      setLang("spanish");
    }

    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setIsConnected(true);
      setIsConnectedLoad(true);
      setUser(JSON.parse(loggedInUser));
    } else {
      setIsConnectedLoad(true);
      if (["/sandbox", "/payments", "/api", "/profile", "/workflow"].includes(pathname)) {
        navigate("/");
      }  
    }

  }, []);

  return (
    <Routes>
      {
        !isConnectedLoad ?
          <Route path="*" element={<></>} />
        : isConnected ?
        <>
          <Route path="payments" element={<Payments clientId={user.userId} lang={lang}/>} />
          <Route path="api" lang={lang}>
            <Route path=":apiId" element={<Api clientId={user.userId}  lang={lang}/>} />
          </Route>
          <Route path="profile" element={<Profile user={user} setIsConnected={setIsConnected} setUser={setUser} lang={lang} setLang={setLang}/>} />  
          <Route path="sandbox" element={<Sandbox lang={lang} clientId={user.userId} />} />  
          <Route path="workflow" element={<ComingSoon lang={lang}/>} />  
          <Route path="contact" element={<Contact lang={lang}/>} />  
          <Route path="admin" element={<Admin clientId={user.userId} />} />
          <Route path="" element={<Marketplace clientId={user.userId} lang={lang} />} />
          {/* <Route path="dashboard" element={<Dashboard user={user}/>} /> */}
        </>
        :
        <>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="" element={<Auth setIsConnected={setIsConnected} setUser={setUser} lang={lang} setLang={setLang}/>} />
        </>
      }

    </Routes>
  );
}

export default App;