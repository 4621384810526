import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import VisbleIcon from "../../../icons/visible";
import NotVisibleIcon from "../../../icons/not_visible";
import LoadingIcon from "../../../icons/loading";
import SuccessIcon from "../../../icons/success";
import ErrorIcon from "../../../icons/error";
import ArrowBackAltIcon from "../../../icons/arrow_back_alt";

import Logo from "../../../icons/logo";

import ArrowBack from "../../../icons/arrow_back";
import VerificationInput from "react-verification-input";

function ForgotPassword(props) {

    const [section, setSection] = useState("email"); // email - key - password

    const [emailError, setEmailError] = useState(false);
    const [forgotPasswordBtn, setForgotPasswordBtn] = useState("default"); // default - loading - success - error

    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [codeBtn, setCodeBtn] = useState("default"); // default - loading - success - error - error-count
    const [codeAttempts, setCodeAttempts] = useState(3);
    const keyInputRef = useRef(null);

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
  
    const [passwordBtn, setPasswordBtn] = useState("default"); // default - loading - success - error
  
    const [passwordRequirements, setPasswordRequirements] = useState({
      lowerCase: false,
      upperCase: false,
      number: false,
      specialCharacter: false,
      characterLength: false
    });
  
    const [credentialId, setCredentialId] = useState("");
    const handleEmail = (e) => {
        props.setLoginEmail(e.target.value);
        setEmailError(false);
        if (forgotPasswordBtn == "error") {
            setForgotPasswordBtn("default");
        }
    }

    const handleCode = (e) => {
        setCode(e);
        setCodeError(false);
        if (codeBtn == "error") {
            setCodeBtn("default");
        }
    }

    const handlePassword = (e) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);
        setPasswordError(false);
        
        const hasLowerCase = /[a-z]/.test(passwordValue);
        const hasUpperCase = /[A-Z]/.test(passwordValue);
        const hasNumber = /\d/.test(passwordValue);
        const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-="']/.test(passwordValue);
        const hasValidLength = passwordValue.length >= 8;
    
        setPasswordRequirements({
          lowerCase: hasLowerCase,
          upperCase: hasUpperCase,
          number: hasNumber,
          specialCharacter: hasSpecialCharacter,
          characterLength: hasValidLength
        });
    
        if (["success","error"].includes(passwordBtn)) {
          setPasswordBtn("default");
        }
      }

    const handleEnterPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (section == "email") {
                forgotPasswordFunction();
            }
            if (section == "key") {
                keyCheckFunction();
            }
            if (section == "password") {
                passwordFunction();
            }
        }
    }

    const forgotPasswordFunction = () => {
        let checkErrors = true;

        if (props.loginEmail.trim().length < 3 || !props.loginEmail.includes("@")) {
            setEmailError(true);
            checkErrors = false;
        }

        if (checkErrors) {
            try {
      
                setForgotPasswordBtn("loading");
          
                axios.post(
                  `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/forgot-password`,
                  {email: props.loginEmail}
                )
                  .then(res => {
                    if (res.data.success) {
                        setForgotPasswordBtn("success");
                        setSection("key");
                    } else {
                        setForgotPasswordBtn("error");
                    }
                  })
      
              } catch (error) {
                setForgotPasswordBtn("error");
            }
        }
    }

    const keyCheckFunction = () => {

        let checkErrors = true;
        if (code.length != 6) {
            setCodeError(true);
            checkErrors= false;
        }

        if (checkErrors) {
            try {
      
                setCodeBtn("loading");
          
                axios.post(
                  `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/check-forgot-password-token`,
                  {key: code, email: props.loginEmail}
                )
                  .then(res => {
                    console.log(res.data)
                    if (res.data.success) {
                        setCodeBtn("success");
                        setSection("password");
                        setCredentialId(res.data.credentialId)
                    } else if (res.data.err == "Try end") {
                        setCodeBtn("error-count");
                        setCodeAttempts(0)
                    } else if (res.data.err == "Code false") {
                        setCodeBtn("error");
                        setCodeAttempts(codeAttempts-1)
                    } else {
                        setCodeBtn("error");
                    }

                    if (!res.data.success) {
                        keyInputRef.current.focus();
                    }
                  })
      
              } catch (error) {
                setCodeBtn("error");
            }
        }
    }

    
    const passwordFunction = () => {

        const allRequirementsMet = Object.values(passwordRequirements).every(Boolean);

        let checkErrors = true;
        
        if (!allRequirementsMet) {
        setPasswordError(true);
        checkErrors = false;
        }

        if (checkErrors) {
        try {

            setPasswordBtn("loading");

            axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/set-forgot-password`,
            {password: password, credentialId: credentialId}
            ).then(res => {
                console.log(res.data)
            if (res.data.userId) {
                setPasswordBtn("success");
                localStorage.setItem("user", JSON.stringify({
                    userId: res.data.userId,
                    userKey: res.data.userKey,
                    username: res.data.username,
                    avatar: res.data.avatar
                }));
                props.setIsConnected(true);
                props.setUser({
                    userId: res.data.userId,
                    userKey: res.data.userKey,
                    username: res.data.username,
                    avatar: res.data.avatar
                });
                window.location.reload(true);
            } else if (res.data.err){
                setPasswordBtn("error");
            }
            })

        } catch (error) {
            setPasswordBtn("error");
        }
        }
    }

  return (
    <div className="login_form">
        <div className="logo_container">
        <Logo />
        </div>
        <div className="forgot_password_wrapper">
        <div className="login_back" onClick={() => {props.setFormLeft("login");props.setFormShowed("left");setSection("email")}}>
            <ArrowBack />
            <span>{props.lang == "english" ? "Login" : "Iniciar sesión"}</span>
        </div>
        {
            section == "email" &&
            <>
                <div className="login_form_title">
                    <h1>{props.lang == "english" ? "Forgot password?" : "¿Olvidó su contraseña?"}</h1>
                    <p>{props.lang == "english" ? "No worries, we'll send you reset instructions." : "No se preocupe, le enviaremos instrucciones para restablecerla."}</p>
                </div>
                <div className={"input_container" + (emailError ? " input_container_error" : "")}>
                    <label>{props.lang == "english" ? "Email address" : "E-mail"}</label>
                    <input type="text" onChange={(e) => handleEmail(e)} onKeyUp={(e) => handleEnterPress(e)} value={props.loginEmail}/>
                </div>
                <div className="login_action">
                            
                    {
                        forgotPasswordBtn != "default" &&
                            <div className={"btn btn_icon btn_indicator btn_primary" + (
                                forgotPasswordBtn == "loading" ? " btn_loading":
                                forgotPasswordBtn == "success" ? " btn_success" :
                                " btn_error"
                            )}>
                                {
                                    forgotPasswordBtn == "loading" ? <LoadingIcon /> :
                                    forgotPasswordBtn == "success" ? <SuccessIcon /> :
                                    <ErrorIcon />
                                }
                            </div>
                    }
                    <div className={"btn btn_primary" + (["loading", "success"].includes(forgotPasswordBtn) ? " btn_loading" : "")} onClick={() => forgotPasswordBtn == "default" ? forgotPasswordFunction() : null}>
                        <span>{props.lang == "english" ? "Reset Password" : "Restablecer contraseña"}</span>
                    </div>
                </div>   
            </>
        }
        {
            section == "key" &&
            <>
                <div className="login_form_title">
                    <h1>{props.lang == "english" ? "Password reset" : "Restablecimiento de contraseña"}</h1>
                    <p>{props.lang == "english" ? "We sent a code to " : "Hemos enviado un código a "}<span>{props.loginEmail}</span></p>
                </div>
                <div className={"input_container key_input_container_" + code.length + (codeError ? " input_container_error" : "")}>
                    <span className={"input_info" + (codeBtn == "error-count" ? " error_info" : "")}>{props.lang == "english" ? "Attempts remaining: " : "Intentos restantes: "}{codeAttempts}</span>
                    <VerificationInput ref={keyInputRef} autoFocus={true} placeholder="" length={6} onChange={(e) => handleCode(e)} onKeyUp={(e) => handleEnterPress(e)} value={code} />
                </div>
                <div className="login_action">
                            
                    {
                        codeBtn != "default" &&
                            <div className={"btn btn_icon btn_indicator btn_primary" + (
                                codeBtn == "loading" ? " btn_loading":
                                codeBtn == "success" ? " btn_success" :
                                " btn_error"
                            )}>
                                {
                                    codeBtn == "loading" ? <LoadingIcon /> :
                                    codeBtn == "success" ? <SuccessIcon /> :
                                    <ErrorIcon />
                                }
                            </div>
                    }
                    <div className={"btn btn_primary" + (["loading", "success","error-count"].includes(codeBtn) ? " btn_loading" : "")} onClick={() => codeBtn == "default" ? keyCheckFunction() : null}>
                        <span>{props.lang == "english" ? "Continue" : "Continuar"}</span>
                    </div>
                </div>   
            </>
        }

        {
            section == "password" &&
            <>
                <div className="login_form_title">
                    <h1>{props.lang == "english" ? "Set new password" : "Establecer nueva contraseña"}</h1>
                    <p>{props.lang == "english" ? "Almost done. Enter you password and you're all set." : "Casi terminado. Introduzca su contraseña y estará listo."}</p>
                </div>
                <div className={"input_container" + (passwordError ? " input_container_error" : "")}>
                    <label>{props.lang == "english" ? "Password" : "Contraseña"}</label>
                    <input type={passwordVisible ? "text" :"password"} onKeyUp={(e) => handleEnterPress(e)} value={password} onChange={(e) => handlePassword(e)}/>
                    {
                        passwordVisible
                        ? <div className="password_visible_btn disable_select" onClick={() => setPasswordVisible(false)}><NotVisibleIcon /></div>
                        : <div className="password_visible_btn disable_select" onClick={() => setPasswordVisible(true)}><VisbleIcon /></div>
                    }
                </div>
                <div className="password_verification">
                    <div className={"password_verification_item" + ( passwordRequirements.lowerCase ? " password_verification_valid" : "")}>
                    <div className="password_verification_icon_wrapper">
                        <div className="password_verification_icon"></div>
                    </div>
                      <span>{props.lang == "english" ? "one lowercase letter" : "una letra minúscula"}</span>
                    </div>
                    <div className={"password_verification_item" + ( passwordRequirements.upperCase ? " password_verification_valid" : "")}>
                    <div className="password_verification_icon_wrapper">
                        <div className="password_verification_icon"></div>
                    </div>
                      <span>{props.lang == "english" ? "one uppercase letter" : "una letra mayúscula"}</span>
                    </div>
                    <div className={"password_verification_item" + ( passwordRequirements.number ? " password_verification_valid" : "")}>
                    <div className="password_verification_icon_wrapper">
                        <div className="password_verification_icon"></div>
                    </div>
                      <span>{props.lang == "english" ? "one number" : "un número"}</span>
                    </div>
                    <div className={"password_verification_item" + ( passwordRequirements.specialCharacter ? " password_verification_valid" : "")}>
                    <div className="password_verification_icon_wrapper">
                        <div className="password_verification_icon"></div>
                    </div>
                      <span>{props.lang == "english" ? "one special character" : "un carácter especial"}</span>
                    </div>
                    <div className={"password_verification_item" + ( passwordRequirements.characterLength ? " password_verification_valid" : "")}>
                    <div className="password_verification_icon_wrapper">
                        <div className="password_verification_icon"></div>
                    </div>
                      <span>{props.lang == "english" ? "8 character minimum" : "8 caracteres mínimo"}</span>
                    </div>
                </div>
                <div className="login_action">
                    {
                        passwordBtn != "default" &&
                        <div className={"btn btn_icon btn_indicator btn_primary" + (
                            passwordBtn == "loading" ? " btn_loading":
                            passwordBtn == "success" ? " btn_success" :
                            " btn_error"
                        )}>
                            {
                                passwordBtn == "loading" ? <LoadingIcon /> :
                                passwordBtn == "success" ? <SuccessIcon /> :
                                <ErrorIcon />
                            }
                        </div>
                    }
                    <div className={"btn btn_primary" + (passwordBtn == "loading" ? " btn_loading" : "")} onClick={() => passwordBtn != "loading" ? passwordFunction() : null}>
                    <span>{props.lang == "english" ? "Reset password" : "Restablecer contraseña"}</span>
                    </div>
                </div> 
            </>
        }
        <p className="login_already_account">
            {props.lang == "english" ? "D'ont have an account ?" : "¿No tienes una cuenta?"}
            <span>{props.lang == "english" ? "Sign up" : "Registrarse"}</span>
        </p>
        </div>
        <div className="login_footer">
        <p className="copyright">© 2023 Scorce S.A.P.I. de C.V.</p>
        <p className="language">{props.lang == "english" ? "Language: " : "Idioma: "}<span onClick={() => {props.setLang("spanish"); localStorage.setItem("lang", "spanish");}}>{props.lang == "english" ? "Spanish" : "Español"}</span> | <span onClick={() => {props.setLang("english"); localStorage.setItem("lang", "english");}}>{props.lang == "english" ? "English" : "Inglés"}</span></p>
        </div>
    </div>
    );
}

export default ForgotPassword;