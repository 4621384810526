import React, { useState, useEffect } from "react";

import axios from "axios";

import "./api.css";

import Chart from "react-apexcharts";

function ApiAnalytics(props) {

  const [optionsLatency, setOptionsLatency] = useState({
    chart: {
      height: 165,
      type: 'area',
      toolbar: {
        show: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: ["#2139da"],
      width: 2
    },
    xaxis: {
      tooltip: {
        enabled: false
      },
      tickAmount: 12,
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        offsetX: -15,
      },
    },
    grid: {
      padding: {
        left: -5,
      },
    },
    fill: {
      colors: ["#2139da"],
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.55,
        stops: [0, 100]
     }
    },
    markers: {
      size: 0,
      colors: ["#2139da"],
      strokeColor: "#2139da",
      strokeWidth: 2,
      hover: {
        size: 3,
      }
    },
    tooltip: {
      shared: false,
      x: {
        show: false,
        format: 'dd/MM/yy HH:mm'
      },
    },
  });
  const [optionsConsumption, setOptionsConsumption] = useState({
    chart: {
      height: 410,
      type: 'bar',
      toolbar: {
        show: false,
      },
      stacked: true
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      labels: {
        offsetX: -15,
      },
    },
    grid: {
      padding: {
        left: -5,
      },
    },
    // yaxis: {
    //   reversed: true,
    // },
    fill: {
      // colors: ["#b0aaf8", "#beb9ff"],
      colors: ["#2139da", "#f34343"],
    },
    tooltip: {
      shared: false,
      x: {
        show: false,
        format: 'dd/MM/yy HH:mm'
      },
    },
    legend: {
      show: false, // Set show to false to hide the legend
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: "red",
      width: 2,
      dashArray: 0, 
    },
    states: {
      hover: {
        filter: "none",
      },
      active: {
        filter: "none",
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        borderRadiusWhenStacked: "last",
        columnWidth: "50%"
      }
    }
  },);
  const [callUsed, setCallUsed] = useState({
    weekly: 0,
    monthly: 0,
    yearly: 0
  });

  useEffect(() => {
    try {
      axios.post(
        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/analytics`,
        {apiId: props.apiId, clientId: props.clientId}
      )
      .then(res => {
      console.log(res.data);
       props.setAnalytics(res.data);
       props.setAnalyticsLoad(false);
       handleAnalyticsData(res.data.requests);
       handleAnalyticsLatency(res.data.requests)
      })
    } catch (error) {}  
  }, []);

  const handleAnalyticsData = (data) => {
    
    // Get the current date
    const currentDate = props.analyticsDate;

    // Calculate the start and end dates for the current week, month, and year
    const currentWeekStartDate = new Date(currentDate);
    currentWeekStartDate.setDate(currentDate.getDate() - currentDate.getDay());
    const currentWeekEndDate = new Date(currentWeekStartDate);
    currentWeekEndDate.setDate(currentWeekStartDate.getDate() + 7);

    const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const currentMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const currentYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
    const currentYearEndDate = new Date(currentDate.getFullYear(), 11, 31);

    // Create the result object with placeholders for weekly, monthly, and yearly data
    const result = {
        weekly: [
            { name: 'Successful calls', data: Array(7).fill(0) },
            { name: 'Failed calls', data: Array(7).fill(0) }
        ],
        monthly: [
            { name: 'Successful calls', data: Array(currentMonthEndDate.getDate()).fill(0) },
            { name: 'Failed calls', data: Array(currentMonthEndDate.getDate()).fill(0) }
        ],
        yearly: [
            { name: 'Successful calls', data: Array(12).fill(0) },
            { name: 'Failed calls', data: Array(12).fill(0) }
        ]
    };

    // Iterate through the data and update the counts
    data.forEach(item => {
        const creationDate = new Date(item.CreationDate);
        
        // Check if the date falls within the current week
        if (creationDate >= currentWeekStartDate && creationDate <= currentWeekEndDate) {
            if (item.Status === '200 OK') {
                result.weekly[0].data[creationDate.getDay()]++;
            } else {
                result.weekly[1].data[creationDate.getDay()]++;
            }
        }

        // Check if the date falls within the current month
        if (creationDate >= currentMonthStartDate && creationDate <= currentMonthEndDate) {
            if (item.Status === '200 OK') {
                result.monthly[0].data[creationDate.getDate() - 1]++;
            } else {
                result.monthly[1].data[creationDate.getDate() - 1]++;
            }
        }

        // Check if the date falls within the current year
        if (creationDate >= currentYearStartDate && creationDate <= currentYearEndDate) {
            if (item.Status === '200 OK') {
                result.yearly[0].data[creationDate.getMonth()]++;
            } else {
                result.yearly[1].data[creationDate.getMonth()]++;
            }
        }
    });

    props.setSeriesConsumptionWeekly(result.weekly);
    props.setSeriesConsumptionMonthly(result.monthly);
    props.setSeriesConsumptionYearly(result.yearly);

    setCallUsed({
      weekly: result.weekly[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + result.weekly[1].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      monthly: result.monthly[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + result.monthly[1].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
      yearly: result.yearly[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0) + result.yearly[1].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    })

  }

  const handleAnalyticsLatency = (data) => {
    // Get the current date
    const currentDate = new Date();

    // Calculate the start and end dates for the current week, month, and year
    const currentWeekStartDate = new Date(currentDate);
    currentWeekStartDate.setDate(currentDate.getDate() - currentDate.getDay());
    const currentWeekEndDate = new Date(currentWeekStartDate);
    currentWeekEndDate.setDate(currentWeekStartDate.getDate() + 7);

    const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const currentMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const currentYearStartDate = new Date(currentDate.getFullYear(), 0, 1);
    const currentYearEndDate = new Date(currentDate.getFullYear(), 11, 31);

    // Create arrays for weekly, monthly, and yearly average latency values
    const weeklyLatency = Array(7).fill(0);
    const weeklyCount = Array(7).fill(0);

    const monthlyLatency = Array(currentMonthEndDate.getDate()).fill(0);
    const monthlyCount = Array(currentMonthEndDate.getDate()).fill(0);

    const yearlyLatency = Array(12).fill(0);
    const yearlyCount = Array(12).fill(0);

    // Iterate through the data and update the average latency values
    data.forEach(item => {
        const creationDate = new Date(item.CreationDate);

        // Check if the date falls within the current week
        if (creationDate >= currentWeekStartDate && creationDate <= currentWeekEndDate) {
            const dayOfWeek = creationDate.getDay();
            const latency = Number(item.Latency);
            weeklyLatency[dayOfWeek] += latency;
            weeklyCount[dayOfWeek]++;
        }

        // Check if the date falls within the current month
        if (creationDate >= currentMonthStartDate && creationDate <= currentMonthEndDate) {
            const dayOfMonth = creationDate.getDate();
            const latency = Number(item.Latency);
            monthlyLatency[dayOfMonth - 1] += latency;
            monthlyCount[dayOfMonth - 1]++;
        }

        // Check if the date falls within the current year
        if (creationDate >= currentYearStartDate && creationDate <= currentYearEndDate) {
            const monthOfYear = creationDate.getMonth();
            const latency = Number(item.Latency);
            yearlyLatency[monthOfYear] += latency;
            yearlyCount[monthOfYear]++;
        }
    });

    // Calculate the average latency values for the week, month, and year
    for (let i = 0; i < 7; i++) {
        if (weeklyCount[i] > 0) {
            weeklyLatency[i] /= weeklyCount[i];
        }
    }

    for (let i = 0; i < currentMonthEndDate.getDate(); i++) {
        if (monthlyCount[i] > 0) {
            monthlyLatency[i] /= monthlyCount[i];
        }
    }

    for (let i = 0; i < 12; i++) {
        if (yearlyCount[i] > 0) {
            yearlyLatency[i] /= yearlyCount[i];
        }
    }

    props.setSeriesLatencyWeekly(weeklyLatency);
    props.setSeriesLatencyMonthly(monthlyLatency);
    props.setSeriesLatencyYearly(yearlyLatency);
  }

  return (
    
    <div className="wrapper analytics_wrapper">
      {
        props.analyticsLoad
        ?
        <>
          <div className="card card_skeleton">
          </div>
          <div className="anayltics_flex">
            <div className="card_flex">
              <div className="card card_skeleton" style={{height: 114}}></div>
              <div className="card card_skeleton" style={{height: 114}}></div>
            </div>
            <div className="card analytics_error_rate_card card_skeleton" style={{height: 86}}></div>
            <div className="card card_skeleton" style={{height: 250}}></div>
          </div>
        </>
        :
        <>
        <div className="card">
          <div className="card_title">
            <h2>{props.lang == "english" ? "Call Consumption" : "Consumo de llamadas"}</h2>
            <div className="graph_guide">
              <div className="graph_quide_item">
                <span style={{backgroundColor: "#bcc3f4", border: "2px solid #4f62e2"}}></span>
                <span>{props.lang == "english" ? "Successful Calls" : "Llamadas exitosas"}</span>
              </div> 
              <div className="graph_quide_item">
                <span style={{backgroundColor: "#fcc6c2", border: "2px solid #f66a60"}}></span>
                <span>{props.lang == "english" ? "Failed Calls" : "Llamadas fallidas"}</span>
              </div> 
            </div>
          </div>
          {
                props.analyticsInterval == "weekly" &&
                <Chart
                  options={{...optionsConsumption, xaxis: {...optionsConsumption.xaxis, categories: props.categoriesObj[props.analyticsInterval]}}}
                  series={props.seriesConsumptionWeekly}
                  type="bar"
                  height={410}
                />
              }
              {
                props.analyticsInterval == "monthly" &&
                <Chart
                  options={{...optionsConsumption, xaxis: {...optionsConsumption.xaxis, categories: props.categoriesObj[props.analyticsInterval], tickAmount: 18}, plotOptions: {bar: {...optionsConsumption.plotOptions.bar, borderRadius: 2}}}}
                  series={props.seriesConsumptionMonthly}
                  type="bar"
                  height={410}
                />
              }       
              {
                props.analyticsInterval == "yearly" &&
                <Chart
                  options={{...optionsConsumption, xaxis: {...optionsConsumption.xaxis, categories: props.categoriesObj[props.analyticsInterval]}}}
                  series={props.seriesConsumptionYearly}
                  type="bar"
                  height={410}
                />
              }
            <div id="call_consumption_graph"  style={{display: "none"}}>
              <div className="y_axis">
                <span>5</span>
                <span>4</span>
                <span>3</span>
                <span>2</span>
                <span>1</span>
                <span>0</span>
              </div>
              <div className="graph_body">
                <div className="background_lines">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="bars" style={{display: "none"}}>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>                      
                      <span></span>
                    </span>
                  </div>
                  <div className="bar">
                    <span className="bar_succ_call"></span>
                    <span className="bar_err_call">
                      <span></span>
                      <span></span>                                            
                    </span>
                  </div>
                </div>
                <div className="x_axis">
                  <span>Monday</span>
                  <span>Tuesday</span>
                  <span>Wednesday</span>
                  <span>Thursday</span>
                  <span>Friday</span>
                  <span>Saturday</span>
                  <span>Sunday</span>
                </div>
              </div>
            </div>
        </div>
        <div className="anayltics_flex">
          <div className="card_flex">
            <div className="card">
              <h3>{props.lang == "english" ? "Calls Remaining" : "Llamadas restantes"}</h3>
              <span className="analytics_call_count">{props.analytics.count}</span>
            </div>
            <div className="card">
              <h3>{props.lang == "english" ? "Calls Used" : "Llamadas utilizadas"}</h3>
              <span className="analytics_call_count">{callUsed[props.analyticsInterval]}</span>
            </div>
          </div>            
            <div className="card analytics_error_rate_card">
              <div className="analytics_error_rate_title">
                <h3>{props.lang == "english" ? "Error Occurrence" : "Ocurrencia de errores"}</h3>
                <span>
                  {
                    props.analytics.requests.length != 0 ? parseInt(
                      (
                        (
                          props.analytics.requests
                          .filter(req => req.Status != "200 OK")
                          .length
                        ) * 100
                      ) / props.analytics.requests.length    
                    )
                    : "0"
                  }%
                </span>
              </div>
              <div className="analytics_error_rate">
                <div className="analytics_error_rate_bar" style={{ width: (
                  props.analytics.requests.length != 0 ? parseInt(
                    (
                      (
                        props.analytics.requests
                        .filter(req => req.Status != "200 OK")
                        .length
                      ) * 100
                    ) / props.analytics.requests.length    
                  )
                  : "0"
                )+"%" }}/>
              </div>
            </div>
            <div className="card">
              <h2>{props.lang == "english" ? "Latency" : "Latencia"}</h2>
              {
                props.analyticsInterval == "weekly" &&
                <Chart
                  options={{...optionsLatency, xaxis: {...optionsLatency.xaxis, categories: props.categoriesObj[props.analyticsInterval]}}}
                  series={[{
                    name: 'Latency',
                    data: props.seriesLatencyWeekly
                  }]}
                  type="area"
                  height={165}
                />
              }
              {
                props.analyticsInterval == "monthly" &&
                <Chart
                  options={{...optionsLatency, xaxis: {...optionsLatency.xaxis, categories: props.categoriesObj[props.analyticsInterval]}}}
                  series={[{
                    name: 'Latency',
                    data: props.seriesLatencyMonthly
                  }]}
                  type="area"
                  height={165}
                />
              }              
              {
                props.analyticsInterval == "yearly" &&
                <Chart
                  options={{...optionsLatency, xaxis: {...optionsLatency.xaxis, categories: props.categoriesObj[props.analyticsInterval]}}}
                  series={[{
                    name: 'Latency',
                    data: props.seriesLatencyYearly
                  }]}
                  type="area"
                  height={165}
                />
              }
              <div id="lantecy_graph" style={{display: "none"}}>
                <div className="y_axis">
                  <span>3</span>
                  <span>2</span>
                  <span>1</span>
                  <span>0</span>
                </div>
                <div className="graph_body">
                  <div className="background_lines">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="bars" style={{display: "none"}}>
                    <div className="bar">
                      <span className="point"></span>
                      <span className="bar_line"></span>
                    </div>
                    <div className="bar">
                      <span className="point" style={{marginBottom: "60px"}}></span>
                      <span className="bar_line"></span>
                    </div>
                    <div className="bar">
                      <span className="point" style={{marginBottom: "23px"}}></span>
                      <span className="bar_line"></span>
                    </div>
                    <div className="bar">
                      <span className="point" style={{marginBottom: "89px"}}></span>
                      <span className="bar_line"></span>
                    </div>
                    <div className="bar">
                      <span className="point"></span>
                      <span className="bar_line"></span>
                    </div>
                    <div className="bar">
                      <span className="point"></span>
                      <span className="bar_line"></span>
                    </div>
                    <div className="bar">
                      <span className="point"></span>
                      <span className="bar_line"></span>
                    </div>
                  </div>
                  <div className="x_axis">
                    <span>Mon</span>
                    <span>Tue</span>
                    <span>Wed</span>
                    <span>Thu</span>
                    <span>Fri</span>
                    <span>Sat</span>
                    <span>Sun</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </>
      }
    </div>

  );

}

export default ApiAnalytics;
