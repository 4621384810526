import React, { useState, useEffect } from "react";

import axios from "axios";

import { CodeBlock, monokai } from "react-code-blocks";
import apiDocGenerate from "./apiDocGenerate";

import "./api.css";
import ApiDrawer from "./ApiDrawer";

import {CopyToClipboard} from 'react-copy-to-clipboard';

function ApiDocumentation(props) {

  const [codeSection, setCodeSection] = useState("body"); // body + request + response

  useEffect(() => {
    try {
      axios.post(
        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/documentation`,
        {apiId: props.apiId, clientId: props.clientId}
      )
      .then(res => {
        console.log(res.data);
       props.setApiDocumentationInfo(res.data);
       props.setApiDocumentationInfoLoad(false);
      })
    } catch (error) {}  
  }, []);

  const getApiDocumentation = (apiDoc) => {
    const { language, method } = props.selectedDevLanguage;
    const key = `${language.replace("#","Sharp")}${method.replace("_","").replace(".","").replace("::","")}`;
    const generateScript = apiDocGenerate[key];

    console.log(key);
    console.log(generateScript);

    return generateScript ? generateScript(apiDoc, props.publicApiKey) : '';
  };

  return (
    <>
    <div className="flex_content_container">
    {
      props.apiDocumentationInfoLoad 
      ?
      <div className="flex_content">
        <div className="flex_right_side">
          <div className="api_name api_name_skeleton">
            <span></span>
            <span></span>
          </div>
          <div className="api_description_skeleton">
            <span></span>
            <span></span>
          </div>
          <div className="api_endpoint_id api_endpoint_id_skeleton">
            <span></span>
            <span></span>
          </div>
          <span className="api_documentation_h5_skeleton"></span>
          <div class="api_documentation_param_skeleton"></div>
          <div class="api_documentation_param_skeleton"></div>
          <div class="api_documentation_param_skeleton"></div>
        </div>
        <div className="flex_left_side">
          <div className="tabs tabs_skeleton">
            <span className="tab tab_skeleton"></span>
            <span className="tab tab_skeleton"></span>
            <span className="tab tab_skeleton"></span>
          </div>
          <div className="api_code api_code_skeleton"></div>
        </div>
      </div>
      :
      props.apiDocumentationInfo.map(apiDoc => 
      <div className="flex_content">
        <div className="flex_right_side">
          <div className="api_name">
            <span>POST</span>
            <span>{apiDoc.EndPointName}</span>
          </div>
          <p className="api_description">{apiDoc.EndPointDescription}</p>
          <div className="api_endpoint_id">
            <span>{props.lang == "english" ? "EndPointId" : "Id. de EndPoint"}</span>
            <CopyToClipboard text={apiDoc.EndPointId}>
              <span className="btn">{apiDoc.EndPointId}</span>
            </CopyToClipboard>
          </div>
          <h5>{props.lang == "english" ? "Parameters" : "Parámetros"}</h5>
          {
            apiDoc.EndPointParams.map(apiParam => 
            <div className="api_param">
              <div className="api_param_name">
                <span>{apiParam.ParamName}</span>
              </div>
              <div className="api_param_desc">
                <span>{apiParam.ParamType}
                {
                  apiParam.ParamRequired
                  ? <span className="active">{props.lang == "english" ? "Required" : "Requerido"}</span>
                  : <span>{props.lang == "english" ? "Opcional" : "Opcional"}</span>
                }
                </span>
                <p>{apiParam.ParamDescription}</p>
              </div>
            </div>  
            )
          }
          <div className="api_box_border"></div>
        </div>
        <div className="flex_left_side">
          <div className="tabs">
            <span onClick={() => setCodeSection("body")} className={"tab" + (codeSection == "body" ? " active" : "")}>{props.lang == "english" ? "Request Body" : "Cuerpo de la Petición"}</span>
            <span onClick={() => setCodeSection("request")} className={"tab" + (codeSection == "request" ? " active" : "")}>{props.lang == "english" ? "Request Sample" : "Ejemplo de Petición"}</span>
            <span onClick={() => setCodeSection("response")} className={"tab" + (codeSection == "response" ? " active" : "")}>{props.lang == "english" ? "Response Sample" : "Ejemplo de Respuesta"}</span>
          </div>
          <div className="api_code">
            <div className="api_code_background">
              {
                codeSection == "body"
                ?
                <CodeBlock
                  text={getApiDocumentation(apiDoc)}
                  language='typescript'
                  showLineNumbers={true}
                  theme={monokai}
                />
                :
                codeSection == "request" ?
                <CodeBlock
                text={
                `{
  "idEndPoint": "${apiDoc.EndPointId}",
  "params": [
    ${apiDoc.EndPointParams.map((apiParam, apiParamIndex) => `${(apiParamIndex == 0 ? "" : `
    `)}{
      "name": "${apiParam.ParamName}",
      "value": "${apiParam.ParamDescription}"
    }`)}
  ]
}`
                }
                language='typescript'
                showLineNumbers={true}
                theme={monokai}
              />
              :
              <CodeBlock
                text={apiDoc.ResponseSample ? apiDoc.ResponseSample : ""}
                language='typescript'
                showLineNumbers={true}
                theme={monokai}
              />
              }
            </div>
          </div>
        </div>
      </div>

      )
    }
  </div>
  <ApiDrawer
    selectedDevLanguage={props.selectedDevLanguage}
    setSelectedDevLanguage={props.setSelectedDevLanguage} 
    openLanguageDrawer={props.openLanguageDrawer}
    setOpenLanguageDrawer={props.setOpenLanguageDrawer}
  />
  </>
  );
}

export default ApiDocumentation;