import React, { useState, useEffect } from "react";

import "./admin.css";

import SquareIcon from "../../icons/square";
import CheckboxIcon from "../../icons/checkbox";
import DraftIcon from "../../icons/draft";
import DoneIcon from "../../icons/done";
import ProgressIcon from "../../icons/progress";
import PendingIcon from "../../icons/pending";

function AdminTask(props) {

  return (
      <>
        {
          props.isConnected ?
          <>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Authentification</h1>
            <div className="task_progress">
              <p><span>2</span>/8 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "100%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_done"><DoneIcon />Done</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Authentification interface</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Login</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Register</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Login using Google</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Login using Github</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Forgot password</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Set password for new users</span>
              </div>
              <div className="task_list_item active">
               <CheckboxIcon />
               <span>Remember me</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Profile</h1>
            <div className="task_progress">
              <p><span>7</span>/7 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "100%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_done"><DoneIcon />Done</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Profile interface</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Edit general information</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Edit billing information</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Password reset</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Update avatar</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Copy Public API key</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Marketplace</h1>
            <div className="task_progress">
              <p><span>6</span>/6 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "100%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_done"><DoneIcon />Done</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Marketplace interface</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Available APIs</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Active APIs</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Search</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Category filter</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Country filter</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>APIs</h1>
            <div className="task_progress">
              <p><span>5</span>/5 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "100%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_done"><DoneIcon />Done</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>API interface</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>API documentation</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>API plans</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>API analytics</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>API logs</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Payments</h1>
            <div className="task_progress">
              <p><span>5</span>/5 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "100%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_done"><DoneIcon />Done</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Payments interface</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Active plans</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Inactive plans</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Recent movements</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Stripe integration</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Sandbox</h1>
            <div className="task_progress">
              <p><span>3</span>/3 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "100%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_done"><DoneIcon />Done</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Sandbox interface</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Standard mode</span>
              </div>
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Developer mode</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card" style={{display: "none"}}>
          <div class="task_card_header">
            <h1>Dashboard</h1>
            <div className="task_progress">
              <p><span>1</span>/5 tasks</p>
              <div className="task_bar">
                <div className="task_bar_fill" style={{width: "20%"}}></div>
              </div>
            </div> 
            <span className="task_card_label task_card_label_draft"><DraftIcon />Draft</span>
          </div>
          <div className="task_body">
            <div className="task_list_check">
              <div className="task_list_item active">
              <CheckboxIcon />
              <span>Dashboard interface</span>
              </div>
              <div className="task_list_item">
              <SquareIcon />
              <span>Active APIs</span>
              </div>
              <div className="task_list_item">
              <SquareIcon />
              <span>API Usage Details</span>
              </div>
              <div className="task_list_item">
              <SquareIcon />
              <span>Most called API</span>
              </div>
              <div className="task_list_item">
              <SquareIcon />
              <span>Calendar filter</span>
              </div>
            </div>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Admin</h1>
            <span className="task_card_label task_card_label_draft"><DraftIcon />Draft</span>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Mobile</h1>
            <span className="task_card_label task_card_label_draft"><DraftIcon />Draft</span>
          </div>
        </div>
        <div className="task_card">
          <div class="task_card_header">
            <h1>Worfklow</h1>
            <span className="task_card_label task_card_label_draft"><DraftIcon />Draft</span>
          </div>
        </div>
        </>
          :
          <>
            <div className="task_card_skeleton"></div>
            <div className="task_card_skeleton"></div>
            <div className="task_card_skeleton"></div>
          </>
        }

      </>
  );
}

export default AdminTask;