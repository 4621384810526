import React, { useState, useEffect } from "react";

import axios from "axios";

import "./api.css";

import ReactGA from "react-ga4";

function ApiPlans(props) {

    const [activateLoading, setActivateLoading] = useState(false);

    useEffect(() => {
        try {
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/plans`,
            {apiId: props.apiId, clientId: props.clientId}
          )
          .then(res => {
            console.log(res.data);
           props.setApiPlansInfo(res.data.plans);
           props.setApiPlansInfoLoad(false);
           props.setUnlimitedPerc(res.data.unlimitedPerc)
          })
        } catch (error) {}  
      }, []);

      const purchasePlan = async (priceId, planId, planType) => {
        setActivateLoading(true);
        ReactGA.event({
            category: "Stripe Purchase Attempt",
            action: "Tried to Purchase Plan",
            label: `Plan: ${props.apiName}, Type: ${planType}, PlanId: ${props.apiId}`,
        });
        try {
              const stripeBody = {
                isTest: false,
                planId: planId,
                priceId: priceId,
                clientId: props.clientId,
                apiId: props.apiId,
                isUnlimited: props.unlimitedValidity
              };
        
              axios.post(
                `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/stripe`,
                stripeBody
              )
                .then(res => {
                    console.log(res.data)
                    if (res.data.startsWith("https://")) {
                        window.location.replace(res.data);
                    }
                })
    
        } catch (error) {
            setActivateLoading(false);
        }
            
    }

    const getFreeTrial = (planId) => {
        setActivateLoading(true);
        ReactGA.event({
            category: "Free Trial",
            action: "Ask for a free trial",
            label: `Plan: ${props.apiName}, Type: "Probar", PlanId: ${props.apiId}`,
        });
        try {
            const freeTrialBody = {
                planId: planId,
                clientId: props.clientId,
            };
    
            axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/plans/free-trial`,
            freeTrialBody
            )
            .then(res => {
                console.log(res.data);
                if (res.data.success) {
                    window.location.reload(true);
                }
            })

        } catch (error) {
            setActivateLoading(false);
        }
    }

    return(
    <div className="wrapper">
        <div className="flex_content_container">
            {
                props.apiPlansInfoLoad ?
                <div className="api_plans_title_skeleton"></div>
                :
                <h2 className="api_plans_title">{props.lang == "english" ? "Select the plan that aligns perfectly with your needs" : "Seleccione el plan que se ajuste perfectamente a sus necesidades"}</h2>
            }
            <div className="api_plans">
                <div className="api_plans_list">
                    {
                        props.apiPlansInfoLoad
                        ?
                        props.apiPlansInfo.map((plan) =>
                            <div className="api_plans_item api_plans_item_skeleton">
                            </div>
                        )
                        :
                        props.apiPlansInfo
                        .sort((a, b) => {
                            const customOrder = ["Probar", "Inicial", "Medio", "Avanzado"];
                            const indexOfA = customOrder.indexOf(a.Name);
                            const indexOfB = customOrder.indexOf(b.Name);
                            if (indexOfA !== -1 && indexOfB !== -1) {
                              return indexOfA - indexOfB;
                            }
                            if (indexOfA !== -1) {
                              return -1;
                            }
                            if (indexOfB !== -1) {
                              return 1;
                            }                          
                            return 0;
                        })
                        .map((plan, planIndex) =>
                        <div className={"api_plans_item" + (plan.Active ? " api_plans_item_activated" : "")}>
                            <div className="api_plans_highlight">
                                <div className="api_plans_header">
                                    <span className="api_plans_name">{
                                        props.lang == "english" ?
                                        plan.Name == "Probar" ? "Trial" :
                                        plan.Name == "Inicial" ? "Initial" :
                                        plan.Name == "Medio" ? "Medium" :
                                        "Advanced"
                                        : plan.Name
                                    }</span>
                                    { planIndex > 1 ? <span className="api_plans_tag">{
                                        (100 - (( (plan.Price/plan.CallLimit) * 100) / (props.apiPlansInfo[1].Price/props.apiPlansInfo[1].CallLimit))).toFixed(0)
                                    }% off</span> : <></> }
                                </div>
                                {
                                    planIndex == 0
                                    ?
                                    <p>{props.lang == "english" ? "Experience our API with a free trial which includes " + plan.CallLimit + " calls" : "Experimenta nuestra API con una prueba gratuita que incluye " + plan.CallLimit + " llamadas."}</p>
                                    :
                                    props.lang == "english" ?
                                        <p>At just <span>{props.unlimitedValidity ? ((plan.Price/plan.CallLimit)*(1+(props.unlimitedPerc/100))).toFixed(2) : ((plan.Price/plan.CallLimit)).toFixed(2)}</span> per call, you get {plan.CallLimit} API calls, billed monthly</p>
                                        :
                                        <p>Por tan solo <span>{props.unlimitedValidity ? ((plan.Price/plan.CallLimit)*(1+(props.unlimitedPerc/100))).toFixed(2) : ((plan.Price/plan.CallLimit)).toFixed(2)}</span> por llamada, obtienes {plan.CallLimit} llamadas a la API, facturadas mensualmente.</p>
                                }
                                <div className="api_plans_price">
                                    {/* <span className="api_plans_price_dollars">$</span> */}
                                    <span className="api_plans_price_value">{plan.CallLimit}</span>
                                    <span className="api_plans_price_mexico">{props.lang == "english" ? "Calls" : "Llamadas"}</span>
                                </div>
                                {
                                    plan.Active
                                    ?
                                    (plan.UnlimitedValidity  && planIndex !=0 ) ? <span className="api_plans_accuracy">{props.lang == "english" ? "Unlimited validity" : "Validez ilimitada"}</span> : <span className="api_plans_accuracy">{props.lang == "english" ? "Valid for 1 month" : "Válido por 1 mes"}</span>
                                    :
                                    (props.unlimitedValidity && planIndex !=0 ) ? <span className="api_plans_accuracy">{props.lang == "english" ? "Unlimited validity" : "Validez ilimitada"}</span> : <span className="api_plans_accuracy">{props.lang == "english" ? "Valid for 1 month" : "Válido por 1 mes"}</span>
                                }
                            <div
                                className={"btn" + (planIndex == 0 ? " btn_semi_primary" : " btn_primary") + (activateLoading ? " btn_loading" : "")}
                                onClick={() => !plan.Active && (
                                    planIndex == 0
                                    ?
                                    getFreeTrial(plan.PlanId)
                                    :
                                    purchasePlan(plan.PriceStripeId, plan.PlanId, props.lang == "english" ? plan.Name == "Probar" ? "Trial" : plan.Name == "Inicial" ? "Initial" : plan.Name == "Medio" ? "Medium" : "Advanced" : plan.Name)
                                    )
                                }
                                ><span>{planIndex == 0 ? (!plan.Active ? props.lang == "english" ? "Try it out" : "Pruébalo" : props.lang == "english" ? "Free Trial Used" : "Prueba gratuita utilizada") : (!plan.Active ? props.unlimitedValidity ? "For " + (parseInt(plan.Price*(1+(props.unlimitedPerc/100)))) + " MXN" : props.lang == "english" ? ("For " + plan.Price + " MXN") : ("Por " + plan.Price + " MXN") : props.lang == "english" ? "Activated" : "Activado")}</span></div> 
                            </div>
                        </div>
                        )
                    }
                </div>
            </div>
                {
                !props.apiPlansInfoLoad &&
                (
                    props.lang == "english"
                    ?
                    <div className="api_plans_footer">
                        <p className="api_plans_description">If you're interested in exploring postpayment options after a trial, or if you need a more customized solution, <a href="mailto:info@scorce.io">contact us</a>.</p>
                        <p className="tax_text">All plan prices are subject to an additional 16% IVA tax.</p>
                    </div>
                    :
                    <div className="api_plans_footer">
                        <p className="api_plans_description">Si está interesado en explorar opciones de postpago después de una prueba, o si necesita una solución más personalizada, <a href="mailto:info@scorce.io">contáctenos</a>.</p>
                        <p className="tax_text">Todos los precios de los planes están sujetos a un 16% adicional de impuesto IVA.</p>
                    </div>
                )
                }
        </div>
    </div>
    );
}

export default ApiPlans;