import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import axios from "axios";

import "./auth.css";

import Login from "./Form/Login";
import Register from "./Form/Register";
import SetPassword from "./Form/SetPassword";
import ForgotPassword from "./Form/ForgotPassword";

import SideAuth from "./SideAuth/SideAuth";

function Auth(props) {

  const location = useLocation();

  const [formShowed, setFormShowed] = useState("left"); // left - right
  const [formLeft, setFormLeft] = useState("login"); // login - forgot_password
  const [formRight, setFormRight] = useState("register"); // register -- password

  const [carousel, setCarousel] = useState(0);

  const [language, setLanguage] = useState("en");

  const [checkPasswordLoading, setCheckPasswordLoading] = useState(true);
  const [setPasswordCredentialId, setSetPasswordCredentialId] = useState("");
  // Login
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('user_token')) {
      const userToken = searchParams.get('user_token');
      try {

        const checkSetPassword = {
          key: userToken
        };

        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/check-password-token`,
          checkSetPassword
        ).then(res => {
          if (res.data.success) {
            setFormRight("password");
            setFormShowed("right");
            setSetPasswordCredentialId(res.data.credentialId);
          } else {
            console.log("false parameter");
          }
          setCheckPasswordLoading(false);
        })

      } catch (error) {
        setCheckPasswordLoading(false);
        console.log("error catching parameter");
      }
    } else {
      setCheckPasswordLoading(false);
      console.log("no token parameter");
    }
  }, [location]);

  return (
    checkPasswordLoading ? <></> :
    <div className="auth_container">
      <div className="auth_form_flex">
        { 
          formLeft == "login" &&
          
          <Login

            setFormLeft={setFormLeft}

            loginEmail={loginEmail}
            loginPassword={loginPassword}
            setLoginEmail={setLoginEmail}
            setLoginPassword={setLoginPassword}
            setIsConnected={props.setIsConnected}
            setUser={props.setUser}

            lang={props.lang}
            setLang={props.setLang}

            setSetPasswordCredentialId={setSetPasswordCredentialId}

            setFormRight={setFormRight}
            setFormShowed={setFormShowed}

            />
        }
        { 
          formLeft == "forgot_password" &&
          <ForgotPassword
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            setFormLeft={setFormLeft}
            setFormShowed={setFormShowed}
            setIsConnected={props.setIsConnected}
            setUser={props.setUser}
            credentialId={setPasswordCredentialId}

            lang={props.lang}
            setLang={props.setLang}
          />
        }
        {
          formRight == "register" &&
          <Register
            lang={props.lang}
          
            setSetPasswordCredentialId={setSetPasswordCredentialId}

            setFormRight={setFormRight}
            setFormShowed={setFormShowed}

            setIsConnected={props.setIsConnected}
            setUser={props.setUser}

          />
        }
        {
          formRight == "password" &&
          <SetPassword
            setIsConnected={props.setIsConnected}
            setUser={props.setUser}
            credentialId={setPasswordCredentialId}
            lang={props.lang}
          />
        }
      </div>
      <div className={"auth_side" + ((formShowed == "left") ? " auth_side_switched" : "")}>
        <SideAuth
            lang={props.lang}
            carousel={carousel}
            setFormLeft={setFormLeft}
            setFormShowed={setFormShowed}
            setCarousel={setCarousel} 
            setLang={props.setLang}
          /> 
      </div>
    </div>
  );
}

export default Auth;