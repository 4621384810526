import React, { useState, useEffect } from "react";

import Navbar from "../Navbar/Navbar";
import axios from "axios";

import "./sandbox.css";
import SandboxStandard from "./SandboxStandard";
import SandboxDev from "./SandboxDev";

import ReactGA from "react-ga4";

function Sandbox(props) {

  const [tabHeader, setTabHeader] = useState("regular"); // regular - dev

  const [apiList, setApiList] = useState([]);
  const [apiListLoad, setApiListLoad] = useState(true);

  const [selectedApi, setSelectedApi] = useState({empty: true});

  useEffect(() => {
    ReactGA.send({ hitType: "pageView", page: "/sandbox", title: "Sandbox" });
    try {
      axios.post(
        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/sandbox/api-list`,
        { clientId: props.clientId }
      )
        .then((res) => {
          const apiListOrdered = res.data.reduce((groups, item) => {
              const key = item.ApiId;
          
              if (!groups[key]) {
                  groups[key] = [];
              }
          
              groups[key].push(item);
          
              return groups;
          }, {})
          setApiList(apiListOrdered)
          setApiListLoad(false)
        })

    } catch (error) {
    }
  }, []);

  const reduceCall = (endpointId) => {
    setApiList(prevData => {

          const updatedArray = prevData[endpointId].map(item => ({
              ...item,
              NumberCall: item.NumberCall - 1
          }));
  
          return {
              ...prevData,
              [endpointId]: updatedArray
          };
      });
  }

  return (
    <div className="body" style={{background: "#fcfcfe"}}>
      <div className="navbar">
        <Navbar navActive="sandbox" lang={props.lang} />
        
        <div className="title_search">
          <div className="title_search_info">
            <h1>{props.lang == "english" ? "Sandbox" : "Sandbox"}</h1>
            <p>{props.lang == "english" ? "Explore our Sandbox page to interact with our APIs. Experiment with various endpoints and parameters to learn more about our services. This environment provides a hands-on experience with our APIs" : "Explora nuestro Sandbox para interactuar con nuestras APIs. Experimenta con diversos endpoints y parámetros para conocer mejor nuestros servicios. Este entorno proporciona una experiencia práctica con nuestras APIs. "}</p>
            <div className="tabs tabs_page_header">
              <span className={"tab" + (tabHeader == "regular" ? " active" : "")} onClick={() => setTabHeader("regular")}>Standard</span>
              <span className={"tab" + (tabHeader == "dev" ? " active" : "")} onClick={() => setTabHeader("dev")}>Developer</span>
              <div className="tab_controll" style={
                tabHeader == "regular" ? {left: -5} : {left: 108}
              }></div>
            </div>
          </div>
        </div>
      </div>
        {
          tabHeader == "regular"
          ?
          <div className="wrapper_flex">
            <div className="flex_layout">
              <div className="white_side"></div>
              <div className="transparent_side"></div>
            </div>
            <SandboxStandard
              apiListLoad={apiListLoad}
              apiList={apiList}
              selectedApi={selectedApi}
              setSelectedApi={setSelectedApi}
              lang={props.lang}
              clientId={props.clientId}
              reduceCall={reduceCall}
            />
          </div>
          :
          <SandboxDev
            lang={props.lang}
            clientId={props.clientId}
          />
        }
    </div>
  );
}

export default Sandbox;