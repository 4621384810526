import React, { useState, useEffect } from "react";

import axios from "axios";

import CheckIcon from "../../icons/check";

import LoadingIcon from "../../icons/loading";
import SuccessIcon from "../../icons/success";
import ErrorIcon from "../../icons/error";

function ProfileBilling(props) {

  const [company, setCompany] = useState(null);
  const [rfc, setRfc] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [numberExt, setNumberExt] = useState(null);
  const [numberInt, setNumberInt] = useState(null);
  const [billingRequired, setBillingRequired] = useState(false);
  const [zipCode, setZipCode] = useState(null);
  const [address, setAddress] = useState({});
  const [suburbId, setSuburbId] = useState(null);

  const [zipCodeError, setZipCodeError] = useState(false);
  const [zipCodeNotTouched, setZipCodeNotTouched] = useState(true);  

  const [updateInfoBtn, setUpdateInfoBtn] = useState("default"); // default - loading - success - error

  useEffect(() => {
    if (!props.profileBillingLoad) {
      try {
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/profile/get-billing`,
          { clientId: props.clientId }
        )
        .then(res => {
          console.log(res.data);
          props.setProfileBilling(res.data);
          setBillingRequired(res.data.BillingRequired === true ? true : false);
          setCompany(res.data.Company)
          setRfc(res.data.Rfc)
          setStreetName(res.data.NumberInt)
          setNumberExt(res.data.NumberExt)
          setNumberInt(res.data.StreetName)
          setZipCode(res.data.ZipCode)
          props.setProfileBillingLoad(true);
        })
      } catch (error) {}  
    } else {
      setCompany(props.profileBilling.Company)
      setRfc(props.profileBilling.Rfc)
      setStreetName(props.profileBilling.NumberInt)
      setNumberExt(props.profileBilling.NumberExt)
      setNumberInt(props.profileBilling.StreetName)
      setZipCode(props.profileBilling.ZipCode)
      setBillingRequired(props.profileBilling.BillingRequired === true ? true : false);
    }
  }, []);

  const updateProfileBillingFunction = () => {
    let checkErrors = true;

    if (zipCode.length !== 5 || !/^\d+$/.test(zipCode) || zipCodeError) {
      checkErrors = false;
    }

    if (checkErrors) {
      try {
        const userBillingInfo = {
          company: company === null || company === undefined ? "" : company,
          rfc: rfc === null || rfc === undefined ? "" : rfc,
          streetName: streetName === null || streetName === undefined ? "" : streetName,
          numberExt: numberExt === null || numberExt === undefined ? "" : numberExt,
          numberInt: numberInt === null || numberInt === undefined ? "" : numberInt,
          billingRequired: billingRequired,
          suburbId: suburbId === null || suburbId === undefined ? null : suburbId,
          clientId: props.clientId
        };
        console.log(userBillingInfo);
        setUpdateInfoBtn("loading");

        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/profile/update-billing`,
          userBillingInfo
        )
        .then(res => {
          console.log(res.data)
          if (res.data == "success") {
            setUpdateInfoBtn("success");
          } else {
            setUpdateInfoBtn("error");
          }
        })
      } catch (error) {
        setUpdateInfoBtn("error");
      }
    }
  }

  const handleZipCode = (e) => {
    if (e.target.value.length < 6) {
      setZipCode(e.target.value);
      setAddress({});
      setZipCodeNotTouched(false);
      setSuburbId(null);
    }

    if (!/^\d+$/.test(e.target.value) && e.target.value.length > 0) {
      setZipCodeError(true);
    } else {
      setZipCodeError(false);
    }

    if (e.target.value.length == 5 && /^\d+$/.test(e.target.value)) {
      try {
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/profile/update-billing/address`,
          {zipCode: e.target.value}
        ).then(res => {
          console.log(res.data);
          if (Object.keys(res.data).length === 0) {
            setZipCodeError(true);
          } else {
            setAddress(res.data);
            setSuburbId(res.data.SuburbId);
          }
        })
        
      } catch (error) {
        setUpdateInfoBtn("error");
      }
  
    }

  }

  return (
    <div className="wrapper">
      <h1 className="wrapper_titler">{props.lang == "english" ? "Billing information" : "Información de facturación"}</h1>
      <p className="max_length">{props.lang == "english" ? "Ensure your billing details are accurate and complete. Please fill in the following information to ensure smooth transactions and proper invoicing." : "Asegúrese de que sus datos de facturación son exactos y completos. Rellene la siguiente información para garantizar unas transacciones fluidas y una facturación correcta."}</p>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Business Information" : "Información comercial"}</label>
        </div>
        <div className="input_side input_side_padding">
          <div className="input_container">
            <label>{props.lang == "english" ? "Company Name" : "Razón social"}</label>
            {
              props.profileBillingLoad
              ? <input type="text" value={company} onChange={(e) => setCompany(e.target.value)}/>
              : <div className="input_skeleton"></div>
            }
          </div>
          <div className="input_container">
            <label>{props.lang == "english" ? "RFC (Tax ID)" : "RFC"}</label>
            {
              props.profileBillingLoad
              ? <input type="text" value={rfc} onChange={(e) => setRfc(e.target.value.toUpperCase())}/>
              : <div className="input_skeleton"></div>
            }          
          </div>
        </div>
      </div>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Address" : "Dirección"}</label>
        </div>
        <div className="input_side input_side_padding">
          <div className="input_flex input_flex_street">
            <div className="input_container">
              <label>{props.lang == "english" ? "Int. No" : "N. Int."}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={numberInt} onChange={(e) => setNumberInt(e.target.value)}/>
                : <div className="input_skeleton"></div>
              }     
            </div>
            <div className="input_container">
              <label>{props.lang == "english" ? "Out. No." : "N. Ext."}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={numberExt} onChange={(e) => setNumberExt(e.target.value)}/>
                : <div className="input_skeleton"></div>
              }   
            </div>
            <div className="input_container">
              <label>{props.lang == "english" ? "Street" : "Calle"}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={streetName} onChange={(e) => setStreetName(e.target.value)}/>
                : <div className="input_skeleton"></div>
              }   
            </div>
          </div>
          <div className="input_flex input_flex_city_zip">          
            <div className={"input_container" + (zipCodeError ? " input_container_error" : "")}>
              <label>{props.lang == "english" ? "Postal Code" : "Código postal"}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={zipCode} onChange={(e) => handleZipCode(e)}/>
                : <div className="input_skeleton"></div>
              }   
            </div>
            <div className="input_container">
              <label>{props.lang == "english" ? "City" : "Colonia"}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={zipCodeNotTouched ? props.profileBilling.City : Object.keys(address).length === 0 ? "" : address.ZipCode == zipCode ? address.City : ""} disabled/>
                : <div className="input_skeleton"></div>
              }   
            </div>
          </div>
          <div className="input_flex">
            <div className="input_container">
              <label>{props.lang == "english" ? "Municipality" : "Municipio"}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={zipCodeNotTouched ? props.profileBilling.Municipality : Object.keys(address).length === 0 ? "" : address.ZipCode == zipCode ? address.Municipality : ""} disabled/>
                : <div className="input_skeleton"></div>
              }   
            </div>
            <div className="input_container">
              <label>{props.lang == "english" ? "State" : "Estado"}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={zipCodeNotTouched ? props.profileBilling.State : Object.keys(address).length === 0 ? "" : address.ZipCode == zipCode ? address.State : ""} disabled/>
                : <div className="input_skeleton"></div>
              }   
            </div>
          </div>
          <div className="input_container">
            <label>{props.lang == "english" ? "Country" : "País"}</label>
              {
                props.profileBillingLoad
                ? <input type="text" value={zipCodeNotTouched ? props.profileBilling.Country : Object.keys(address).length === 0 ? "" : address.ZipCode == zipCode ? address.Country : ""} disabled/>
                : <div className="input_skeleton"></div>
              }   
          </div>
        </div>
      </div>
      <div className="two_side_input">
        <div className="label_side">
          <label>{props.lang == "english" ? "Invoice" : "Factura"}</label>
        </div>
        <div className="input_side">
          {
            props.profileBillingLoad
            ? <div className={"input_checkbox_container" + (billingRequired ? " active" : "")} onClick={() => setBillingRequired(!billingRequired)}>
                <div className="input_radio">
                  { billingRequired && <CheckIcon /> }
                </div>
                <label>{props.lang == "english" ? "I would like to receive invoices for my records" : "Deseo recibir facturas para mis archivos"}</label>
              </div>
            : <div className="input_container">
                <div className="input_skeleton"></div>
              </div>
          }
        </div>
      </div>
      <div className="two_side_submit">
        {
            updateInfoBtn != "default" &&
                <div className={"btn btn_indicator btn_primary" + (
                    updateInfoBtn == "loading" ? " btn_loading btn_icon":
                    updateInfoBtn == "success" ? " btn_success btn_icon" :
                    " btn_error"
                )}>
                    {
                        updateInfoBtn == "loading" ? <LoadingIcon /> :
                        updateInfoBtn == "success" ? <SuccessIcon /> :
                        <ErrorIcon />
                    }
                    {
                      updateInfoBtn == "error" &&
                      <span>{props.lang == "english" ? "An error occurred. Please try again later." : "Se ha producido un error. Vuelva a intentarlo más tarde."}</span>
                    }
                </div>
        }
        <div className={"btn btn_primary" + (updateInfoBtn == "loading" ? " btn_loading" : "")} onClick={() => updateInfoBtn != "loading" ? updateProfileBillingFunction() : null}>
          <span>{props.lang == "english" ? "Update billing information" : "Modificar datos de facturación"}</span>
        </div>
      </div>
    </div>
  );
}

export default ProfileBilling;