import React from "react";

function DoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M480.5-55Q390-55 312.013-87.584q-77.988-32.583-134.917-89.512T87.584-312.013Q55-390 55-480.5q0-89.5 32.347-167.05 32.347-77.551 89.512-134.917 57.166-57.366 135.154-90.449Q390-906 480-906q75 0 140 22.528T739-820l-70 69q-39-28-86.582-44Q534.836-811 480-811q-141.259 0-236.13 94.326Q149-622.349 149-480.174 149-338 243.817-243.5t236 94.5Q621-149 716-243.377 811-337.753 811-480q0-25.329-4-49.157-4-23.829-11-45.843l75-76q17 40 26 82.364 9 42.363 9 87.866 0 90.77-33.084 168.757-33.083 77.988-90.449 135.154-57.366 57.165-134.917 89.512Q570-55 480.5-55zM419-283L243-460l65-66 111 111 421-421 67 66-488 487z"></path>
    </svg>
  );
}

export default DoneIcon;
