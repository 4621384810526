import React, { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom";

import axios from "axios";

import CheckIcon from "../../icons/check"
import Icon from "../../icons/check_circle"

function ApiInvoicePopup(props) {

    const navigate = useNavigate();

    const [billingRequired, setBillingRequired] = useState(false);
    const [billingInfoAvailable, setBillingInfoAvailable] = useState(false);

    const handleInvoiceUpdate = () => {
        if (billingInfoAvailable) {

            try {
                const getApiBody = {
                    clientId: props.clientId,
                    billingRequired: billingRequired
                }  
                axios.post(
                    `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/invoice/update`,
                    getApiBody
                )
            } catch (error) { console.log(error) }  


            props.setShowInvoicePopup(false);
            navigate("/api/" + props.apiId);    
        } else {
            navigate("/profile", { state: { tab: "billing" } });
        }
    }

    useEffect(() => {
        try {
            const getApiBody = {
              clientId: props.clientId
            }
      
            axios.post(
              `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/invoice`,
              getApiBody
            )
            .then(res => {
                console.log(res.data)
                setBillingInfoAvailable(res.data.AllInfoFilled);
                setBillingRequired(res.data.BillingRequired)
            })
          } catch (error) { console.log(error) }  
    }, [])

  return (
    <div className="api_incoive_popup">
        <div className="api_incoive_popup_background" onClick={() => {navigate("/api/" + props.apiId );props.setShowInvoicePopup(false);}}></div>
        <div className="api_incoive_popup_container">
            <div className="api_invoice_popup_icon">
                <Icon/>
            </div>
            <h1>Payment Successful</h1>
            <p>Your API is now active. Check the box below if you'd like to receive invoices for your records.</p>
            <div className={"input_checkbox_container" + (billingRequired ? " active" : "")} onClick={() => setBillingRequired(!billingRequired)}>
                <div className="input_radio">
                    { billingRequired && <CheckIcon /> }
                </div>
                <label>{props.lang == "english" ? "I would like to receive invoices for my records" : "Deseo recibir facturas para mis archivos"}</label>
            </div>
            <div className="api_invoice_popup_action">
                <div className="btn" onClick={() => {navigate("/api/" + props.apiId );props.setShowInvoicePopup(false);}}>
                    <span>Cancel</span>
                </div>
                <div className="btn btn_primary" onClick={() => handleInvoiceUpdate()}>
                    <span>Confirm</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ApiInvoicePopup