import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import axios from "axios";

import Logo from "../../../icons/logo";
import GoogleLogo from "../../../icons/google_logo";
import GithubLogo from "../../../icons/github_logo";
import CheckIcon from "../../../icons/check";
import LoadingIcon from "../../../icons/loading";
import SuccessIcon from "../../../icons/success";
import ErrorIcon from "../../../icons/error";
import VisbleIcon from "../../../icons/visible";
import NotVisibleIcon from "../../../icons/not_visible";

import { useGoogleLogin } from '@react-oauth/google';

import ReactGA from "react-ga4";

function Login(props) {

    const location = useLocation();

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const [loginBtn, setLoginBtn] = useState("default"); // default - loading - success - error

    const [keepMeCheck, setKeepMeCheck] = useState(false);

    const handleEmail = (e) => {
        props.setLoginEmail(e.target.value);
        setEmailError(false);
        if (loginBtn == "error") {
            setLoginBtn("default");
        }
    }

    const handlePassword = (e) => {
        props.setLoginPassword(e.target.value);
        setPasswordError(false);
        if (loginBtn == "error") {
            setLoginBtn("default");
        }
    }

    const handleEnterPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            loginFunction();
        }
    }

    const loginFunction = () =>  {

        let checkErrors = true;

        if (props.loginEmail.trim().length < 3 || !props.loginEmail.includes("@")) {
            setEmailError(true);
            checkErrors = false;
        }

        if (props.loginPassword.length == 0) {
            setPasswordError(true);
            checkErrors = false;
        }

        if (checkErrors) {
            try {
                const userLogin = {
                  email: props.loginEmail,
                  password: props.loginPassword
                };
      
                setLoginBtn("loading");
          
                axios.post(
                  `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/login`,
                  userLogin
                )
                  .then(res => {
                    if (res.data.userId) {
                        setLoginBtn("success");
                        localStorage.setItem("user", JSON.stringify({
                            userId: res.data.userId,
                            userKey: res.data.userKey,
                            username: res.data.username,
                            avatar: res.data.avatar
                        }));
                        props.setIsConnected(true);
                        props.setUser({
                            userId: res.data.userId,
                            userKey: res.data.userKey,
                            username: res.data.username,
                            avatar: res.data.avatar
                        });
                        ReactGA.event({
                            category: "User Authentication",
                            action: "Login Attempt",
                            label: "Username: " + res.data.username
                        });
                        window.location.reload(true);
                    } else if (res.data.err){
                        setLoginBtn("error");
                    }
                  })
      
              } catch (error) {
                setLoginBtn("error");
            }
        }

    }

    const loginGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setLoginBtn("loading");
            axios.post(
                `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/login/google`,
                {access_token: tokenResponse.access_token}
            )
            .then(res => {
                handleSocialLogin(res);
            })
            .catch(err => setLoginBtn("error"))
        },
        onError: errorResponse => console.log(errorResponse),
    });

    const loginGithub = () => {
        window.location.assign("https://github.com/login/oauth/authorize?client_id=85fd6558c80338e7c383&scope=user:email")
    }

    const handleSocialLogin = (res) => {
        console.log(res);
        if (res.data.userId) {
            setLoginBtn("success");
            localStorage.setItem("user", JSON.stringify({
                userId: res.data.userId,
                userKey: res.data.userKey,
                username: res.data.username,
                avatar: res.data.avatar
            }));
            props.setIsConnected(true);
            props.setUser({
                userId: res.data.userId,
                userKey: res.data.userKey,
                username: res.data.username,
                avatar: res.data.avatar
            });
            window.location.reload(true);
        } else {
            setLoginBtn("error");
        }
    }

    const loginSocialFunction = async (email, lname, fname) => {
        try {
            setLoginBtn("loading");

            const userLogin = {
              email: email,
              lname: lname,
              fname: fname
            };
    
            console.log(userLogin);
            axios.post(
                `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/login-social`,
                userLogin
            )
            .then(res => {
            if (res.data.newUser) {
                console.log("is newUser")
                props.setSetPasswordCredentialId(res.data.credentialId)
                props.setFormRight("password");
                props.setFormShowed("right");
            } else if (res.data.userId) {
                setLoginBtn("success");
                localStorage.setItem("user", JSON.stringify({
                    userId: res.data.userId,
                    userKey: res.data.userKey,
                    username: res.data.username,
                    avatar: res.data.avatar
                }));
                props.setIsConnected(true);
                props.setUser({
                    userId: res.data.userId,
                    userKey: res.data.userKey,
                    username: res.data.username,
                    avatar: res.data.avatar
                });
                window.location.reload(true);
              } else if (res.data.err){
                  setLoginBtn("error");
              }
            })
    
          } catch (error) {
            setLoginBtn("error");
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('code')) {
            const githubToken = searchParams.get('code');
            setLoginBtn("loading");
            axios.post(
                `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/login/github`,
                {code: githubToken}
            )
            .then(res => {
                handleSocialLogin(res);
            })
            .catch(err => setLoginBtn("error"))
        }
    })

  return (
    <div className="login_form">
        <div className="logo_container">
        <Logo />
        </div>
        <div className="login_wrapper">
        <div className="login_form_title">
            <h1>{props.lang == "english" ? "Welcome back!" : "¡Bienvenido de nuevo!"}</h1>
            <p>{props.lang == "english" ? "Enter your credentials to access your account." : "Introduzca sus credenciales para acceder a su cuenta."}</p>
        </div>
        <div className="social_login">

            <div
                className="btn btn_social"
                onClick={() => loginGoogle()}
              >
                <GoogleLogo />
                <span>{props.lang == "english" ? "Log in with Google" : "Iniciar sesión con Google"}</span>
            </div>

            <div className="btn btn_social" onClick={() => loginGithub()}>
                <GithubLogo />
                <span>{props.lang == "english" ? "Log in with Github" : "Iniciar sesión con Github"}</span>
            </div>
        </div>
        <div className="or_divider">
            <div className="divider"></div>
            <span>{props.lang == "english" ? "or" : "o"}</span>
            <div className="divider"></div>
        </div>
        <div className={"input_container" + (emailError ? " input_container_error" : "")}>
            <label>{props.lang == "english" ? "Email address" : "E-mail"}</label>
            <input type="text" onChange={(e) => handleEmail(e)} onKeyUp={(e) => handleEnterPress(e)} value={props.loginEmail}/>
        </div>
        <div className={"input_container" + (passwordError ? " input_container_error" : "")}>
            <div className="input_label_flex">
            <label>{props.lang == "english" ? "Password" : "Contraseña"}</label>
            <span onClick={() => props.setFormLeft("forgot_password")}>{props.lang == "english" ? "Forgot password?" : "¿Olvidó su contraseña?"}</span>
            </div>
            <input type={passwordVisible ? "text" :"password"} onChange={(e) => handlePassword(e)} onKeyUp={(e) => handleEnterPress(e)} value={props.loginPassword}/>
            {
              passwordVisible
              ? <div className="password_visible_btn disable_select" onClick={() => setPasswordVisible(false)}><NotVisibleIcon /></div>
              : <div className="password_visible_btn disable_select" onClick={() => setPasswordVisible(true)}><VisbleIcon /></div>
            }
        </div>
        <div className="login_action">
            <div className={"input_checkbox_container" + (keepMeCheck ? " active" : "")} onClick={() => setKeepMeCheck(!keepMeCheck)}>
                <div className="input_radio">
                    { keepMeCheck && <CheckIcon /> }
                </div>
                <span>{props.lang == "english" ? "Keep me logged in" : "Mantenerme conectado"}</span>
            </div>           
                {
                    loginBtn != "default" &&
                        <div className={"btn btn_icon btn_indicator btn_primary" + (
                            loginBtn == "loading" ? " btn_loading":
                            loginBtn == "success" ? " btn_success" :
                            " btn_error"
                        )}>
                            {
                                loginBtn == "loading" ? <LoadingIcon /> :
                                loginBtn == "success" ? <SuccessIcon /> :
                                <ErrorIcon />
                            }
                        </div>
                }
                <div className={"btn btn_primary" + (["loading", "success"].includes(loginBtn) ? " btn_loading" : "")} onClick={() => loginBtn == "default" ? loginFunction() : null}>
                    <span>{props.lang == "english" ? "Login" : "Iniciar sesión"}</span>
                </div>
            </div>
        </div>
        <div className="login_footer">
        <p className="copyright">© 2023 Scorce S.A.P.I. de C.V.</p>
        <p className="language">{props.lang == "english" ? "Language: " : "Idioma: "}<span onClick={() => {props.setLang("spanish"); localStorage.setItem("lang", "spanish");}}>{props.lang == "english" ? "Spanish" : "Español"}</span> | <span onClick={() => {props.setLang("english"); localStorage.setItem("lang", "english");}}>{props.lang == "english" ? "English" : "Inglés"}</span></p>
        </div>
    </div>
    );
}

export default Login;