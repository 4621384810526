import React, { useState, useEffect } from "react";

import axios from "axios";

import GoogleLogo from "../../../icons/google_logo";
import GithubLogo from "../../../icons/github_logo";

import LoadingIcon from "../../../icons/loading";
import SuccessIcon from "../../../icons/success";
import ErrorIcon from "../../../icons/error";
import CheckIcon from "../../../icons/check";
import MailIcon from "../../../icons/mail";

import { useGoogleLogin } from '@react-oauth/google';

import ReactGA from "react-ga4";

function Register(props) {

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [terms, setTerms] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const [registerBtn, setRegisterBtn] = useState("default"); // default - loading - success - error - error-email

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    if (registerBtn == "error") {
        setRegisterBtn("default");
    }
  }

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(false);
    if (registerBtn == "error") {
        setRegisterBtn("default");
    }
  }

  const handleLastName = (e) => {
    setLastName(e.target.value);
    setLastNameError(false);
    if (registerBtn == "error") {
        setRegisterBtn("default");
    }
  }

  const handlePhone = (e) => {
    setPhone(e.target.value);
    setPhoneError(false);
    if (registerBtn == "error") {
        setRegisterBtn("default");
    }
  }

  const handleTerms = () => {
    setTerms(!terms);
    setTermsError(false);
  }

  const handleEnterPress = (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        registerFunction();
      }
  }

  const registerFunction = () => {
    
    let checkErrors = true;

    const nameRegex = /^[A-Za-z\u00C0-\u024F\u0400-\u04FF\u0500-\u052F\s'-.]+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneNumberRegex = /^[\d\s()+-]*\d[\d\s()+-]*$/;

    if (firstName.length == 0 || !nameRegex.test(firstName)) {
      setFirstNameError(true);
      checkErrors = false;
    }

    if (lastName.length == 0 || !nameRegex.test(lastName)) {
      setLastNameError(true);
      checkErrors = false;
    }

    if (email.length == 0 || !emailRegex.test(email)) {
      setEmailError(true);
      checkErrors = false;
    }

    if (phone.length == 0 || !phoneNumberRegex.test(phone)) {
      setPhoneError(true);
      checkErrors = false;
    }

    if (!terms) {
      setTermsError(true);
      checkErrors = false;
    }

    if (checkErrors) {
      try {

        const userRegister = {
          fname: firstName,
          lname: lastName,
          email: email,
          phone: phone,
        };

        setRegisterBtn("loading");

        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/register`,
          userRegister
        ).then(res => {
          console.log(res.data)
          if (res.data.success) {
            setRegisterBtn("success");
            ReactGA.event({
              category: "User Authentication",
              action: "Register Attempt",
              label: "Username: " + firstName + " " + lastName
            });
          } else if (res.data.err == "Email already exist"){
            setRegisterBtn("error-email");
            setEmailError(true);
          } else {
            setRegisterBtn("error");
          }
        })

      } catch (error) {
        setRegisterBtn("error");
      }
    }
  }

    
    const loginGoogle = useGoogleLogin({
      onSuccess: async tokenResponse => {
          const userInfo = await axios.get(
              'https://www.googleapis.com/oauth2/v3/userinfo',
              { headers: {Authorization: `Bearer ${tokenResponse.access_token}`} }
          );          
          console.log(userInfo.data.email, userInfo.data.family_name, userInfo.data.given_name)  
          loginSocialFunction(userInfo.data.email, userInfo.data.family_name, userInfo.data.given_name)
      },
      onError: errorResponse => console.log(errorResponse),
  })

  const loginSocialFunction = async (email, lname, fname) => {
      try {
          setRegisterBtn("loading");

          const userLogin = {
            email: email,
            lname: lname,
            fname: fname
          };
  
          axios.post(
              `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/login-social`,
              userLogin
          )
          .then(res => {
          if (res.data.newUser) {
              console.log("is newUser")
              props.setSetPasswordCredentialId(res.data.credentialId)
              props.setFormRight("password");
              props.setFormShowed("right");
          } else if (res.data.userId) {
              setRegisterBtn("success");
              localStorage.setItem("user", JSON.stringify({
                  userId: res.data.userId,
                  userKey: res.data.userKey,
                  username: res.data.username,
                  avatar: res.data.avatar
              }));
              props.setIsConnected(true);
              props.setUser({
                  userId: res.data.userId,
                  userKey: res.data.userKey,
                  username: res.data.username,
                  avatar: res.data.avatar
              });
              window.location.reload(true);
            } else if (res.data.err){
                setRegisterBtn("error");
            }
          })
  
        } catch (error) {
          setRegisterBtn("error");
      }
  }

  return (
    <div className="register_form">
        {
          registerBtn == "success" ?
          <div className="login_wrapper register_wrapper_success">
            <div className="login_form_title">
                <div className="login_wrapper_icon">
                  <MailIcon />
                </div>
                <h1>{props.lang == "english" ? "Check your email" : "Revisa tu correo electrónico"}</h1>
                <p>{props.lang == "english" ? <>Registration Successful! An activation link has been sent to <span>{email}</span>. Please use it to set your password and activate your account.</> :
                <>¡Registro exitoso! Se ha enviado un enlace de activación a <span>{email}</span>. Úsalo para establecer tu contraseña y activar tu cuenta.</>
                }</p>
              </div>
          </div>
          :
          <div className="login_wrapper">
            <div className="login_form_title">
              <h1>{props.lang == "english" ? "Create your account" : "Crea tu cuenta"}</h1>
              <p>{props.lang == "english" ? "Enter the fields below to get started." : "Ingresa los campos a continuación para comenzar."}</p>
            </div>
            {/* <div className="social_login">
              <div className="btn btn_social" onClick={() => loginGoogle()} >
                <GoogleLogo />
                <span>{props.lang == "english" ? "Sign up with Google" : "Registrarse con Google"}</span>
              </div>
              <div className="btn btn_social">
                <GithubLogo />
                <span>{props.lang == "english" ? "Sign up with Github" : "Registrarse con Github"}</span>
              </div>
            </div>
            <div className="or_divider">
              <div className="divider"></div>
              <span>{props.lang == "english" ? "or" : "o"}</span>
              <div className="divider"></div>
            </div> */}
            <div className="input_flex">
            <div className={"input_container" + (firstNameError ? " input_container_error" : "")}>
                <label>{props.lang == "english" ? "First Name" : "Nombre"}</label>
              <input type="text" onChange={(e) => handleFirstName(e)} onKeyUp={(e) => handleEnterPress(e)} value={firstName}/>
              </div>
            <div className={"input_container" + (lastNameError ? " input_container_error" : "")}>
                <label>{props.lang == "english" ? "Last Name" : "Primer Apellido"}</label>
              <input type="text" onChange={(e) => handleLastName(e)} onKeyUp={(e) => handleEnterPress(e)} value={lastName}/>
              </div>
            </div>
            <div className={"input_container" + (emailError ? " input_container_error" : "")}>
              <div className="label_flex">
                <label>{props.lang == "english" ? "Email address" : "E-mail"}</label>
                { registerBtn == "error-email" && <span className="input_info error_info">{props.lang == "english" ? "This email is already in use." : "Este correo electrónico ya está en uso."}</span>}          
              </div>
              <input type="text" onChange={(e) => handleEmail(e)} onKeyUp={(e) => handleEnterPress(e)} value={email}/>
            </div>
            <div className={"input_container" + (phoneError ? " input_container_error" : "")}>
              <label>{props.lang == "english" ? "Phone number" : "Número de teléfono"}</label>
              <input type="text" onChange={(e) => handlePhone(e)} onKeyUp={(e) => handleEnterPress(e)} value={phone}/>
            </div>
            <div className={"input_checkbox_container"  + (terms ? " active" : "")  + (termsError ? " error" : "")} onClick={() => handleTerms()}>
              <div className="input_radio">
                {terms && <CheckIcon />}
              </div>
              <span>
                {
                  props.lang == "english"
                  ?
                  <>I agree to <a target="_blank" href="https://www.scorce.io/terminos-y-condiciones">The Terms Conditions</a> and <a target="_blank" href="https://www.scorce.io/politica-de-privacidad">The Privacy Policy</a></>
                  :
                  <>Acepto <a target="_blank" href="https://www.scorce.io/terminos-y-condiciones">los Términos y Condiciones</a> y <a target="_blank" href="https://www.scorce.io/politica-de-privacidad">la Política de Privacidad</a></>
                }
              </span>
            </div>
            <div className="login_action">
              {
                  registerBtn != "default" &&
                    <div className={"btn btn_icon btn_indicator btn_primary" + (
                        registerBtn == "loading" ? " btn_loading":
                        registerBtn == "success" ? " btn_success" :
                        " btn_error"
                    )}>
                        {
                            registerBtn == "loading" ? <LoadingIcon /> :
                            registerBtn == "success" ? <SuccessIcon /> :
                            <ErrorIcon />
                        }
                    </div>
              }
              <div className={"btn btn_primary" + (registerBtn == "loading" ? " btn_loading" : "")} onClick={() => registerBtn != "loading" ? registerFunction() : null}>
                <span>{props.lang == "english" ? "Create account" : "Crear cuenta"}</span>
              </div>
            </div>
          </div>
        }
      </div>
    );
}

export default Register;