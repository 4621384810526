import React from "react";

function IdentifidadDigitalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 1"
      viewBox="0 0 57.88 57.88"
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M9.94 19.44v-4.75c0-2.62 2.13-4.75 4.75-4.75h4.75M9.94 38.44v4.75c0 2.62 2.13 4.75 4.75 4.75h4.75M38.44 9.94h4.75c2.62 0 4.75 2.13 4.75 4.75v4.75M38.44 47.94h4.75c2.62 0 4.75-2.13 4.75-4.75v-4.75"></path>
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.82 24.19h.02M36.07 24.19h.02M23 36.07c3.21 3.28 8.48 3.33 11.76.12l.12-.12"
      ></path>
    </svg>
  );
}

export default IdentifidadDigitalIcon;
