import React from "react";

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M256.478-105.869q-33.49 0-56.637-22.981-23.147-22.98-23.147-56.237v-560.391h-50.609v-79.218h212.306v-40.175h282.653v40.175h212.871v79.218h-50.609v560.391q0 32.507-23.522 55.862-23.522 23.356-56.262 23.356H256.478zm447.044-639.609H256.478v560.391h447.044v-560.391zm-343.87 478.913h69.609v-399h-69.609v399zm171.087 0h70.174v-399h-70.174v399zM256.478-745.478v560.391-560.391z"></path>
    </svg>
  );
}

export default DeleteIcon;
