import React from "react";

function VisibleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5zm-.22-67.044q-42.941 0-72.898-30.059-29.956-30.058-29.956-72.999 0-42.941 30.059-72.898 30.058-29.956 72.999-29.956 42.941 0 72.898 30.059 29.956 30.058 29.956 72.999 0 42.941-30.059 72.898-30.058 29.956-72.999 29.956zM480-186.999q-150.522 0-271.913-86.957Q86.695-360.913 26.999-500q59.696-139.087 181.088-226.044Q329.478-813.001 480-813.001t271.913 86.957Q873.305-639.087 933.001-500q-59.696 139.087-181.088 226.044Q630.522-186.999 480-186.999zM480-500zm-.118 240q120.764 0 221.876-65.5Q802.87-391 855.87-500q-53-109-153.994-174.5Q600.883-740 480.118-740q-120.764 0-221.876 65.5Q157.13-609 103.13-500q54 109 154.994 174.5Q359.117-260 479.882-260z"></path>
    </svg>
  );
}

export default VisibleIcon;
