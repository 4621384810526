import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M225.087-145.869q-32.507 0-55.862-23.356-23.356-23.355-23.356-55.862v-103.391q0-16.707 11.502-28.158 11.501-11.451 28.282-11.451 16.782 0 28.108 11.451t11.326 28.158v103.391h509.826v-103.391q0-16.707 11.501-28.158 11.502-11.451 28.234-11.451t28.39 11.451q11.658 11.451 11.658 28.158v103.391q0 32.507-23.521 55.862-23.522 23.356-56.262 23.356H225.087zm215.304-516.086L351-572.565q-11.626 11.827-27.4 11.609-15.774-.217-27.992-12.084-12.094-11.866-11.765-28.283.33-16.416 12.156-28.807l155.653-156.218q6.224-6.079 13.634-9.235 7.411-3.157 14.91-3.157 7.5 0 14.732 3.157 7.233 3.156 13.42 9.235L665.566-629.13q11.826 11.826 11.826 28.065t-11.435 27.5q-12.443 12.435-28.787 12.131-16.344-.304-28.17-12.131l-89.391-88.39v304.259q0 16.706-11.501 28.158-11.502 11.451-28.283 11.451-16.782 0-28.108-11.451-11.326-11.452-11.326-28.158v-304.259z"></path>
    </svg>
  );
}

export default UploadIcon;
