import React from "react";

function UndoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M264.087-185.869v-79.218h313.957q64.913 0 110.891-44.804 45.978-44.805 45.978-109.848 0-64.478-45.978-109.565t-110.891-45.087H297.175l104.956 104.956-55.566 55.566L145.869-614l200.696-200.696 55.566 55.566-104.956 105.521h279.869q97.826 0 167.739 67.957 69.913 67.956 69.913 165.913 0 97.391-69.913 165.631-69.913 68.239-167.739 68.239H264.087z"></path>
    </svg>
  );
}

export default UndoIcon;
