import React from "react";

function CheckboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M418.522-316.456l291.63-292.87-47.782-48.022L418.522-412.5 301.674-529.348l-47.783 48.022 164.631 164.87zm-304.5 202.434v-732.196h732.196v732.196H114.022z"></path>
    </svg>
  );
}

export default CheckboxIcon;
