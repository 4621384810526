import React from "react";

function CodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 -960 960 960"
    >
      <path d="M311.521-221.912L52.868-480l258.653-258.653 75.653 75.088-184 184 183 183-74.653 74.653zm336.958.565l-75.653-75.088 184-184-183-183 74.653-74.653L907.132-480 648.479-221.347z"></path>
    </svg>
  );
}

export default CodeIcon;
