import React from "react";

function DraftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 -960 960 960"
    >
      <path d="M95 13v-771h498l-94 95H189v582h582v-311l95-95V13H95zm385-385zm169-357l58 57-279 277v75h74l280-278 55 55-302 303H348v-187l301-302zm188 186L649-729l154-154 187 186-153 154z"></path>
    </svg>
  );
}

export default DraftIcon;
