import React, {useState, useEffect} from 'react'
import axios from "axios";

import SquareIcon from "../../icons/square";
import CheckboxIcon from "../../icons/checkbox";
import DraftIcon from "../../icons/draft";
import DoneIcon from "../../icons/done";
import ProgressIcon from "../../icons/progress";
import PendingIcon from "../../icons/pending";
import ApiIcon from "../../icons/api";

import "./admin_api_prices.css"

function AdminApiPrices(props) {

    const [apiPrices, setApiPrices] = useState([]);
    const [apiPricesLoad, setApiPricesLoad] = useState(true);

    const [priceInput, setPriceInput] = useState({});


    const handlePriceChange = (value, name) => {
      const priceInputNew = { ...priceInput }
      priceInputNew[name] = value.replace(/\D/g, '');
      console.log(priceInputNew)
      setPriceInput(priceInputNew)
    }

    const [updatePricesLoading, setUpdatePricesLoading] = useState(false);
    const updatePrices = () => {
      setUpdatePricesLoading(true)
      axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-calls/update", {
        updateObj: priceInput,
      })
      .then((res) => setUpdatePricesLoading(false))
      .catch((err) => setUpdatePricesLoading(false))
    }

    useEffect(() => {
        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/api-calls")
        .then((res) => {
            console.log(res.data)
            setApiPricesLoad(false);
            setApiPrices(res.data.calls)
        })
    }, []);

  return (
    <>
      {
        !apiPricesLoad ?
        <div className="card_list api_card_list api_prices_container">
        {
            apiPrices.map(apiPrice => (
                <div className="card_container"><div className="card">
                  <div className="card_body">
                    <div className="card_flex">
                      <span className="api_icon">
                        <ApiIcon />
                      </span>
                      <h4>{apiPrice.Name}</h4>
                    </div>
                    <div className="input_three_flex_column">
                      <div className="input_container">
                            <label>Probar Calls</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PlanProbarId] ? priceInput[apiPrice.PlanProbarId] : apiPrice.CallProbar} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PlanProbarId)}/>
                                <span>Call</span>
                            </div>
                        </div>
                        <div className="input_container">
                            <label>Initial Calls</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PlanInicialId] ? priceInput[apiPrice.PlanInicialId] : apiPrice.CallInicial} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PlanInicialId)}/>
                                <span>Call</span>
                            </div>
                        </div>
                        <div className="input_container">
                            <label>Medium Calls</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PlanMedioId] ? priceInput[apiPrice.PlanMedioId] : apiPrice.CallMedio} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PlanMedioId)}/>
                                <span>Call</span>
                            </div>
                        </div>
                        <div className="input_container">
                            <label>Advanced Calls</label>
                            <div className="input_container_inside_flex">
                                <input type="text" value={priceInput[apiPrice.PlanAvanzadoId] ? priceInput[apiPrice.PlanAvanzadoId] : apiPrice.CallAvanzado} onChange={(e) => handlePriceChange(e.target.value, apiPrice.PlanAvanzadoId)}/>
                                <span>Call</span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
        }
        {
          !updatePricesLoading
          ?
          <div className="btn api_prices_btn" onClick={() => updatePrices()}>
            <span>Update Calls</span>
          </div>
          :
          <div className="btn api_prices_btn api_prices_btn_loading">
            <span>Update Calls</span>
          </div>
        }
      </div>
        :
        <>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
        </>
      }

    </>
  )
}

export default AdminApiPrices