import React, {useState, useEffect} from 'react'
import axios from "axios";

import Search from "../../icons/search";

import "./admin_api_balances.css"

function AdminUsers(props) {


    const [logs, setLogs] = useState([]);
    const [logsLoading, setLogsLoading] = useState(true);

    const [logsMoreLoading, setLogsMoreLoading] = useState(false);
    const [logsLoadingMax, setLogsLoadingMax] = useState(false);

    const [logsOffset, setLogsOffset] = useState(0);

    useEffect(() => {
      loadLogs();
    }, []);

    const loadLogs = async () => {
      setLogsMoreLoading(true);
      await axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/logs", {
        offset: logsOffset
      })
      .then((res) => {
          if (res.data.logs.length < 25) {
            setLogsLoadingMax(true);
          }
          setLogsOffset(logsOffset + res.data.logs.length);
          setLogs([...logs, ...res.data.logs])  
          setLogsLoading(false);
        })
        setLogsMoreLoading(false);

    }
    
    const formatDate = (dateString) => {

        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }


  return (
<>
<div className="card_list api_card_list api_prices_container api_balances_container">
      {
        !logsLoading ?
        <>
        <table className="table_balances">
            <tr className="table_balances_row">
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width">Request ID</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width">Email</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width">API</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width">Status</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width">Date</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" style={{ width: "0.01%" }}>Null Occurrence</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" style={{ width: "0.01%" }}>Boolean Occurence</th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" style={{ width: "0.01%" }}>Total Prorperties</th>
            </tr>
        {
            logs
            .map(log => (
                <tr className="table_balances_row">
                    <td className="table_balances_item table_balances_item_init_width" style={{ width: "180px" }}>{log.ApiLogId}</td>
                    <td className="table_balances_item table_balances_item_init_width">{log.Email}</td>
                    <td className="table_balances_item table_balances_item_init_width">{
                        {
                            '0d64479f-26d2-4acf-9599-de34071c7576':'FULL FRAUD',
                            'a6cc88ec-6a7a-4b98-9274-f9645a50d062':'FULL EMAIL CHECK',
                            '6a99a132-fe3c-43c0-9673-8c4817896464':'EMAIL CHECK',
                            '1f581de8-53db-4f27-80ed-3a3afd327f9c':'PHONE CHECK',
                            '9dc41379-1865-4415-9eb0-a9a5bb454368':'IP CHECK'
                        }[log.EndPointId]
                    }</td>
                    <td className="table_balances_item table_balances_item_init_width table_balances_item_init_nowrap">{log.Status}</td>
                    <td className="table_balances_item table_balances_item_init_width table_balances_item_init_nowrap">{formatDate(log.CreationDate)}</td>
                    <td className={"table_balances_item table_balances_item_init_width" + ((log.NullOccurrence*100/(log.NullOccurrence + log.BoolOccurrence)).toFixed() >= 50 ? " perc_green" : "  perc_red")}>{log.NullOccurrence} <span className="api_balance_percentange">({ (log.NullOccurrence*100/(log.NullOccurrence + log.BoolOccurrence)).toFixed() }%)</span></td>
                    <td className={"table_balances_item table_balances_item_init_width" + ((log.NullOccurrence*100/(log.NullOccurrence + log.BoolOccurrence)).toFixed() >= 50 ? " perc_green" : "  perc_red")}>{log.BoolOccurrence} <span className="api_balance_percentange">({ 100-(log.NullOccurrence*100/(log.NullOccurrence + log.BoolOccurrence)).toFixed() }%)</span></td>
                    <td className="table_balances_item table_balances_item_init_width">{log.NullOccurrence + log.BoolOccurrence}</td>
                </tr>
            ))
        }
        </table>
        {/* <div className="api_balances_input_search">
            <Search />
            <input className="api_balances_search" type="text" placeholder="Search by email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)}/>
        </div> */}
        {
          logsMoreLoading ? <div className="load_more_user_btn loading">Load more logs</div>
          : !logsLoadingMax ? <div className="load_more_user_btn" onClick={() => loadLogs()}>Load more logs</div> : <></>
        }
        </>
        :
        <>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
        </>
      }

      </div>
    </>
  )
}

export default AdminUsers