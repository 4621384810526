import React from "react";

function LogoFull() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 500 141.8"
      version="1.1"
      viewBox="0 0 500 141.8"
      xmlSpace="preserve"
    >
      <defs>
        <circle id="SVGID_1_" cx="58.8" cy="70.9" r="45.5"></circle>
      </defs>
      <use fill="#fff" overflow="visible" xlinkHref="#SVGID_1_"></use>
      <clipPath>
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <path
        fill="#2139da"
        d="M58.8 86c-2.8 0-5.5.9-7.8 2.6-.5.4-.7 1.1-.3 1.6.4.5 1.1.6 1.6.3 3.9-2.8 9.2-2.8 13.1 0 .2.1.4.2.6.2.4 0 .7-.2.9-.5.4-.5.2-1.2-.3-1.6-2.2-1.7-5-2.6-7.8-2.6z"
        className="st0"
      ></path>
      <circle cx="58.8" cy="67.9" r="3" fill="#2139da" className="st0"></circle>
      <path
        fill="#2139da"
        d="M81.6 74.9c-2.8-4.2-6.4-7.8-10.5-10.7-.3-5.1-1.4-10.2-3.2-15-2.3-5.6-5.5-8.6-9-8.6s-6.7 3.1-9 8.6c-1.9 4.8-3 9.9-3.2 15-4.1 2.9-7.7 6.5-10.6 10.7-2.6 4.1-3.1 7.6-1.6 10.2 1.4 2.5 4.5 3.7 8.7 3.7 2.2 0 4.4-.3 6.5-.8.7-.2 1.4-.4 2-.8 4.4-2.8 10-2.8 14.3 0 .6.4 1.3.7 2 .8 7.6 1.7 13.2.7 15.3-2.9 1.4-2.6.9-6.2-1.7-10.2zm-22.8-32c3.3 0 6.8 4.8 8.7 12.7-5.3-3.6-12.2-3.6-17.4 0 1.9-8 5.4-12.8 8.7-12.7zM36.4 84c-2.1-3.6 2.2-10.6 10-16.8v1.6c-.1 5.8.8 11.6 2.5 17.1-6.4 1.4-11 .7-12.5-1.9zm22.4-1.1c-2.7 0-5.4.8-7.8 2.1-1.6-5.3-2.4-10.7-2.3-16.2 0-3.3.2-6.6.8-9.8 5.2-5 13.5-5 18.8 0 .5 3.3.8 6.5.8 9.8 0 5.5-.7 11-2.3 16.2-2.6-1.4-5.3-2.2-8-2.1zM81.2 84c-1.5 2.6-6.2 3.3-12.5 1.9 1.7-5.5 2.6-11.3 2.5-17.1v-1.6c7.8 6.2 12.1 13.2 10 16.8zM63.4 90.1c-.6 0-1.1.6-1.1 1.2 0 .5-.1 1-.4 1.4l-3.1 4.5-3.1-4.5c-.3-.4-.5-.9-.4-1.4-.1-.6-.6-1.1-1.3-1-.5.1-.9.4-1 .9-.1 1 .2 2 .8 2.8l5 7.3 5-7.3c.6-.8.9-1.8.8-2.8 0-.6-.5-1.1-1.2-1.1z"
        className="st0"
      ></path>
      <path
        fill="#fff"
        d="M141.9 101.2c-15.5 0-25-8.2-25.1-20.5h18.9c0 4.2 2.9 6.1 6.6 6.1 2.9 0 5.6-1.5 5.6-4.4 0-3.3-4.3-4.2-9.8-5.1-8.8-1.4-20.6-4.1-20.6-17.9 0-11.4 9.7-18.8 24.3-18.8s23.9 7.7 24.2 19.2h-18.4c0-3.6-2.4-5.3-6-5.3-3.1 0-5.3 1.5-5.3 4.2 0 3.2 4.4 4 9.8 5 9 1.6 21 3.5 21 17.9 0 11.9-10 19.7-25.2 19.6zM205.1 101.2c-18.3 0-31.5-12.8-31.5-30.4s13.1-30.2 31.5-30.2c16 0 28 10.1 30.2 25.2h-19.1c-1.7-4.7-6.1-7.8-11.1-7.7-6.9 0-11.8 5.2-11.8 12.7s5 12.8 11.8 12.8c5.2.1 9.7-3.3 11.1-8.3h19.3c-2.2 15.5-14.4 25.9-30.4 25.9zM242.1 71c0-17.7 13.4-30.3 31.9-30.3s32 12.6 32 30.3-13.4 30.3-31.9 30.3-32-12.7-32-30.3zm32 12.7c7.1 0 12.3-5.2 12.3-12.7s-5.1-12.7-12.3-12.7-12.3 5.2-12.3 12.7 5.1 12.7 12.3 12.7zM349.7 42.2v18h-7.6c-6.9 0-9.9 3-9.9 10.3v30.8h-19.6V42.5h13.1l3.1 6.3c4-4.7 8.9-6.6 15.7-6.6h5.2zM387.7 101.2c-18.3 0-31.5-12.8-31.5-30.4s13.1-30.2 31.5-30.2c16 0 28 10.1 30.2 25.2h-19.1c-1.7-4.7-6.1-7.8-11.1-7.7-6.9 0-11.8 5.2-11.8 12.7s5 12.8 11.8 12.8c5.2.1 9.7-3.3 11.1-8.3h19.3c-2.2 15.5-14.3 25.9-30.4 25.9zM486.7 70.4c0 1.7-.1 3.5-.4 5.2H444c1.3 6.5 5.4 9.9 11.6 9.9 4.6 0 8.4-2 10.3-5.3h19.5c-4 13-15.5 21.1-29.8 21.1-17.8 0-30.9-13-30.9-30.4s13.1-30.3 30.9-30.3c18.4 0 31.1 13.1 31.1 29.8zm-42.3-5.7h23c-1.7-5.7-5.8-8.8-11.8-8.8-5.7 0-9.6 3.1-11.2 8.8z"
        className="st1"
      ></path>
    </svg>
  );
}

export default LogoFull;