import React, {useState, useEffect} from 'react'
import axios from "axios";

import Search from "../../icons/search";

import "./admin_api_balances.css"
import SortUpIcon from '../../icons/sort_up';
import SortDownIcon from '../../icons/sort_down';

function AdminUsers(props) {

    const [apiBalances, setApiBalances] = useState([]);
    const [apiBalancesLoad, setApiBalancesLoad] = useState(true);

    const [searchEmail, setSearchEmail] = useState("");

    const [usersOffset, setUsersOffset] = useState(0);
    const [usersLoadingMax, setUsersLoadingMax] = useState(false);
    const [userMoreLoading, setUserMoreLoading] = useState(false);

    const [disabledUsers, setDisabledUsers] = useState([]);

    const [usersType, setUsersType] = useState(true); // true: active - false: disabled
    const [usersOrder, setusersOrder] = useState("Email ASC");

    const [selectedcredentialId, setSelectedcredentialId] = useState({credentialId: "", email: ""});

    useEffect(() => {
      loadUsers(usersType, usersOrder);
    }, []);

    const loadUsers = async ( active, order ) => {
      const newType = active != usersType || order != usersOrder;
      setUsersType(active);
      setusersOrder(order);
      if (newType) {
        setApiBalancesLoad(true);
      }
      setUserMoreLoading(true);
      await axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/users", {
        offset: active == usersType && order == usersOrder ? usersOffset : 0,
        active,
        order
      })
      .then((res) => {
          if (res.data.users.length < 51) {
            setUsersLoadingMax(true);
          }
          if (newType) {
            setApiBalances([]);
            setDisabledUsers([]);      
            setUsersOffset(res.data.users.length);
            setApiBalances(res.data.users)  
            console.log(res.data.users)
          } else {
            setUsersOffset(usersOffset + res.data.users.length);
            setApiBalances([...apiBalances, ...res.data.users])  
            console.log([...apiBalances, ...res.data.users])
          }
          setApiBalancesLoad(false);
        })
        setUserMoreLoading(false);

    }

    const disabledUser = ( credentialId ) => {
      setDisabledUsers([...disabledUsers, credentialId]);
      setSelectedcredentialId({credentialId: "", email: ""})
      axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/users/disable",
        { credentialId, active: !usersType }
      )
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);

      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }


  return (
<>
<div className="card_list api_card_list api_prices_container api_balances_container">
      {
        !apiBalancesLoad ?
        <>
        <table className="table_balances">
            <tr className="table_balances_row">
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" onClick={() => loadUsers(usersType, usersOrder != "Email ASC" ? "Email ASC" : "Email DESC")}><span>Email {usersOrder == "Email ASC" && <SortUpIcon />} {usersOrder == "Email DESC" && <SortDownIcon />}</span></th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" onClick={() => loadUsers(usersType, usersOrder != "Name ASC" ? "Name ASC" : "Name DESC")}><span>Full Name {usersOrder == "Name ASC" && <SortUpIcon />} {usersOrder == "Name DESC" && <SortDownIcon /> }</span></th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" onClick={() => loadUsers(usersType, usersOrder != "CreationDate ASC" ? "CreationDate ASC" : "CreationDate DESC")}><span>Registration date {usersOrder == "CreationDate ASC" && <SortUpIcon />} {usersOrder == "CreationDate DESC" && <SortDownIcon />}</span></th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width" onClick={() => loadUsers(usersType, usersOrder != "MostRecentApiLogCreationDate ASC" ? "MostRecentApiLogCreationDate ASC" : "MostRecentApiLogCreationDate DESC")}><span>Last request {usersOrder == "MostRecentApiLogCreationDate ASC" && <SortUpIcon />} {usersOrder == "MostRecentApiLogCreationDate DESC" && <SortDownIcon />}</span></th>
                <th className="table_balances_item table_balances_item_4 table_balances_item_init_width"></th>
            </tr>
        {
            apiBalances
            .filter(apiBalance =>
                apiBalance.Email.toLowerCase().includes(searchEmail.toLowerCase())
                &&
                !disabledUsers.includes(apiBalance.CredentialId)
            )
            .map(apiBalance => (
                <tr className="table_balances_row">
                    <td className="table_balances_item table_balances_item_init_width">{apiBalance.Email}</td>
                    <td className="table_balances_item table_balances_item_init_width">{apiBalance.Name + " " + (apiBalance.SecondLastname ? apiBalance.SecondLastname + " " : "") + apiBalance.FirstLastname}</td>
                    <td className="table_balances_item table_balances_item_init_width">{apiBalance.CreationDate ? formatDate(apiBalance.CreationDate) : "-"}</td>
                    <td className="table_balances_item table_balances_item_init_width">{apiBalance.MostRecentApiLogCreationDate ? formatDate(apiBalance.MostRecentApiLogCreationDate) : "-"}</td>
                    <td className="table_balances_item table_balances_item_init_width"><div className={"btn_disable_user" + (usersType ? "" : " btn_enable_user")} onClick={() => setSelectedcredentialId({credentialId: apiBalance.CredentialId, email: apiBalance.Email})}>{usersType ? "Disabled" : "Enable"}</div></td>                    
                </tr>
            ))
        }
        </table>
        {/* <div className="api_balances_input_search">
            <Search />
            <input className="api_balances_search" type="text" placeholder="Search by email" value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)}/>
        </div> */}
        {
          userMoreLoading ? <div className="load_more_user_btn loading">Loading More users</div>
          : !usersLoadingMax ? <div className="load_more_user_btn" onClick={() => loadUsers(usersType, usersOrder)}>Load more users</div> : <></>
        }
        </>
        :
        <>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
          <div className="task_card_skeleton"></div>
        </>
      }

      <div className="api_balances_input_search api_users_checks">
        <div className={usersType ? "active" : ""} onClick={() => !apiBalancesLoad && !userMoreLoading && usersType == false ? loadUsers(true, "Email ASC") : null }>Active</div>
        <div className={usersType ? "" : "active"} onClick={() => !apiBalancesLoad && !userMoreLoading &&  usersType == true ? loadUsers(false, "Email ASC") : null}>Disabled</div>
      </div>


      {
        selectedcredentialId.credentialId && <div className="admin_user_popup_container">
          <div className="admin_user_popup_background"></div>
          <div className="admin_user_popup">
            <p>Are you sure you want to {usersType ? "disabled" : "enable"} this user? <span>({selectedcredentialId.email})</span></p>
            <div className="admin_user_btn_flex">
              <div onClick={() => setSelectedcredentialId({credentialId: "", email: ""})}>Cancel</div>
              <div className={usersType ? "admin_user_popup_disable" : "admin_user_popup_enable"} onClick={() => disabledUser(selectedcredentialId.credentialId)}>{usersType ? "Disabled" : "Enable"}</div>
            </div>
          </div>
        </div>
      }

      </div>
    </>
  )
}

export default AdminUsers