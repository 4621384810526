import React, { useState, useEffect } from "react";

import CheckIcon from "../../icons/check";
import ArrowBack from "../../icons/arrow_back";
import { CodeBlock, monokai } from "react-code-blocks";
import beautify from 'json-beautify';
import LoadingIcon from "../../icons/loading";
import ErrorIcon from "../../icons/error";

import axios from "axios";

function SandboxStandard(props) {

    const [params, setParams] = useState([]);
    const [paramsLoad, setParamsLoad] = useState(true);

    const [requestParams, setrequestParams] = useState({});
    const [rightsCheck, setRightsCheck] = useState(false);
    const [requestError, setRequestError] = useState("check");
    const [requestLoading, setRequestLoading] = useState(false);
    const [selectedEndpoint, setSelectedEndpoint] = useState({});
    const [resultApiLogId, setResultApiLogId] = useState("");

    const [selectedLog, setSelectedLog] = useState({});
    const [selectedLogLoad, setSelectedLogLoad] = useState(true);
    
    const getParams = (endpoint) => {
        props.setSelectedApi(endpoint)
        console.log(endpoint);
        setSelectedEndpoint(endpoint);
        setParamsLoad(true);
        setrequestParams({});
        setRightsCheck(false);
        setRequestError("");
        setResultApiLogId("");
    
        try {
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/sandbox/params`,
            { endpointId: endpoint.EndPointId }
          )
            .then((res) => {
                console.log(res.data);
                res.data.map( param => {
                    if (param.Description == "application/json") {
                        setrequestParams(prevState => ({
                            ...prevState,
                            [param.Name]: "application/json"
                        }));
                    } else if (param.Required) {
                        setrequestParams(prevState => ({
                            ...prevState,
                            [param.Name]: ""
                        }));
                    }
                });
              setParams(res.data)
              setParamsLoad(false)
            })
    
        } catch (error) {
        }
    }

    const sendRequest = () => {
        setRequestError("");
        setResultApiLogId("");

        if (rightsCheck) {
            
            let paramsArray = []

            Object.keys(requestParams).map(key => {
                paramsArray.push({"name": key, "value": requestParams[key]})
            });

            let checkRequired = true;

            params
            .filter(param => param.Required)
            .map(param => param.Name)
            .forEach(param => {
                if (!Object.keys(requestParams).includes(param)) {
                    checkRequired = false;
                } else {
                    if (requestParams[param].trim().length == 0) {
                        checkRequired = false;
                    }
                }
            })

            if (!checkRequired) {
                setRequestError("fields");
            } else if (
                !(paramsArray.filter(param => param.Required).map(param => param.Name)
                .map(param => requestParams[param] ? requestParams[param].length : 0)
                .filter(param => !param)
                .length == 0)
            ) {
                setRequestError("params");
            } else {
                const requestBody = {
                    clientId: props.clientId,
                    endpointId: selectedEndpoint.EndPointId,
                    params: paramsArray
                };       
                setRequestLoading(true);
                setRequestError("");
                try {    
                    axios.post(
                        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/send-request`,
                        requestBody
                    )
                    .then(res => {
                        if (res.data.err) {
                            if (res.data.err == "Out of balance") {
                                setRequestError("balance");
                            } else {
                                setRequestError("try");
                                console.log(res.data);
                            }
                        } else if(res.data) {
                            console.log(res.data);
                            setResultApiLogId(res.data)
                            loadLog(res.data);
                            setRightsCheck("");
//                            navigate("/api-result/" + res.data);

                            props.reduceCall(selectedEndpoint.ApiId);

                        }
                        setRequestLoading(false);
                    })
                } catch (error) {
                    setRequestError("error");
                    //An error occurred while sending the request, please try again later
                    setRequestLoading(false);
                }    
            }
                        
        } else {
            setRequestError("checkbox");
        }
    }

    const handleChange = (name, value) => {
        setrequestParams(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const loadLog = ( apiLogId ) => {
        setSelectedLog({});
        setSelectedLogLoad(true);
        try {
      
            const getLogDetails = {
              clientId: props.clientId,
              apiLogId: apiLogId
            }
      
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/api/logs/details`,
            getLogDetails
          )
          .then(res => {
            console.log(res.data[0]);
            setSelectedLog(res.data[0]);
            setSelectedLogLoad(false);
          })
        } catch (error) {}  
      }
      

      const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
      
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
      const formatJson = (jsonString) => {
        try {
          const parsedJson = JSON.parse(jsonString);
          const formattedJson = beautify(parsedJson, null, 2, 80);
          return formattedJson;
        } catch (error) {
          return jsonString;
        }
      }

    return (
        <div className="flex_content" id="flex_sandbox">
            <div className="flex_right_side">
                {
                    !props.apiListLoad
                    ?
                    Object.keys(props.apiList).map(apiId => (
                        <>
                            <div className="sandbox_api_list_title">
                                <h2>{props.apiList[apiId][0].ApiName}</h2>
                                <span>{props.apiList[apiId][0].NumberCall} Calls</span>
                            </div>
                            <a href="#flex_sandbox" className="sandbox_api_list_endpoint_flex">
                            { props.apiList[apiId].map(endpoint => (
                                <div className="sandbox_api_list_endpoint" onClick={() => getParams(endpoint)}>
                                    <div className="endpoint_icon"></div>
                                    <h3>{endpoint.Name}</h3>
                                </div>
                            )) }
                            </a>
                        </>
                    ))
                    :
                    <p>loading</p>
                }
            </div>
            <div className="flex_left_side sandbox_api_list_endpoint_action">



                {
                resultApiLogId ?
                    <>
                    <div className="btn btn_primary btn_outline" onClick={() => setResultApiLogId("")}>
                        <ArrowBack />
                        <span>{props.lang == "english" ? "Send another request" : "Enviar otra solicitud"}</span>                        
                    </div>
                    <h6 className="api_log_subtite">{props.lang == "english" ? "Request Id" : "ID de la Petición"}</h6>
                    <h1 className="api_log_title">{resultApiLogId}</h1>
                    <div className="api_log_details">
                    <div className="api_log_detail">
                        <span>{props.lang == "english" ? "API ID" : "ID de la API"}</span>
                        <span className="api_log_detail_value">{selectedEndpoint.ApiId}</span>
                    </div>
                    <div className="api_log_detail">
                        <span>{props.lang == "english" ? "API title" : "Título de la API"}</span>
                        <span className="api_log_detail_value">{selectedEndpoint.ApiName}</span>
                    </div>
                    <div className="api_log_detail">
                        <span>{props.lang == "english" ? "Endpoint ID" : "ID del Endpoint"}</span>
                        <span className="api_log_detail_value">{selectedEndpoint.EndPointId}</span>
                    </div>
                    <div className="api_log_detail">
                        <span>{props.lang == "english" ? "Endpoint title" : "Título del Endpoint"}</span>
                        <span className="api_log_detail_value">{selectedEndpoint.Name}</span>
                    </div>


                    {
                        selectedLogLoad
                        ?
                        <>
                        <div className="api_log_detail api_log_detail_skeleton">
                            <span>{props.lang == "english" ? "Status" : "Estado"}</span>
                            <span className="api_log_status"></span>
                        </div>
                        <div className="api_log_detail api_log_detail_skeleton">
                            <span>{props.lang == "english" ? "Date and time" : "Fecha y hora"}</span>
                            <span className="api_log_detail_value"></span>
                        </div>
                        <div className="api_log_detail api_log_detail_skeleton">
                            <span>{props.lang == "english" ? "Duration" : "Duración"}</span>
                            <span className="api_log_detail_value"></span>
                        </div>
                        <div className="api_log_detail api_log_detail_vert api_log_detail_skeleton">
                            <span>{props.lang == "english" ? "Request Body" : "Cuerpo de la Petición"}</span>
                            <div className="api_log_param">
                                <div className="api_log_detail">
                                    <span className="api_log_deatil_name"></span>
                                    <span className="api_log_detail_value"></span>
                                </div>
                                <div className="api_log_detail">
                                    <span className="api_log_deatil_name"></span>
                                    <span className="api_log_detail_value"></span>
                                </div>
                            </div>
                        </div>
                        <div className="api_log_detail api_log_detail_vert api_log_detail_skeleton">
                            <span>{props.lang == "english" ? "Request Response" : "Respuesta de la Petición"}</span>
                            <div className="api_code api_code_skeleton"></div>
                        </div>
                        </>
                        :
                        <>
                                <div className="api_log_detail">
                                    <span>{props.lang == "english" ? "Status" : "Estado"}</span>
                                    <span className={selectedLog.Status == "200 OK" ? "api_log_status" : "api_log_status api_log_status_err"}>{selectedLog.Status}</span>
                                </div>
                                <div className="api_log_detail">
                                    <span>{props.lang == "english" ? "Date and time" : "Fecha y hora"}</span>
                                    <span className="api_log_detail_value">{formatDateTime(selectedLog.CreationDate)}</span>
                                </div>
                                <div className="api_log_detail">
                                    <span>{props.lang == "english" ? "Duration" : "Duración"}</span>
                                    <span className="api_log_detail_value">{selectedLog.Latency} ms</span>
                                </div>
                                <div className="api_log_detail api_log_detail_vert">
                                    <span>{props.lang == "english" ? "Request Body" : "Cuerpo de la Petición"}</span>
                                    <div className="api_log_param">
                                        {
                                            JSON.parse(selectedLog.JsonRequest).map(req =>
                                            <div className="api_log_detail">
                                                <span>{req.name}</span>
                                                <span className="api_log_detail_value">{req.value}</span>
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="api_log_detail api_log_detail_vert">
                                    <span>Request Response</span>
                                    <div className="api_code">
                        <div className="api_code_background">
                            <CodeBlock
                            text={formatJson(selectedLog.JsonResponse)}
                            language='json'
                            showLineNumbers={true}
                            theme={monokai}
                            wrapLines={true}
                            
                            showInlineLineNumbers={false}
                            wrapLongLines={true}
                            lineProps={{ style: { flexWrap: 'wrap' } }}
                            />
                        </div>
                        </div>
                                </div>
                        </>
                    }
                    </div>
                        </>
                        :
                        <>
                        
                        {
                    props.selectedApi.empty
                    ?
                    <></>
                    :
                    paramsLoad
                    ?
                    <p>Loading</p>
                    :
                    <>
                        <h2>{props.selectedApi.Name}</h2>
                        <p>{props.selectedApi.Description}</p>
                        <div className="sandbox_api_list_endpoint_params">
                            {
                                params?.map(param => (
                                    <div className="input_container">
                                        <div className="input_label_flex">
                                            <label>{param.Name}</label>
                                            <span className={param.Required ? "active" : "" }>
                                                {param.Required ? "Required" : "Optional" }
                                            </span>
                                        </div>
                                        <input type="text" onChange={(e) => handleChange(param.Name, e.target.value)} />
                                    </div>
                                ))
                            }
                            <div className={"input_checkbox_container" + (rightsCheck ? " active" : "")} onClick={() => setRightsCheck(!rightsCheck)}>
                                <div className="input_radio">
                                    { rightsCheck && <CheckIcon /> }
                                </div>
                                <span>{props.lang == "english" ? "I declare that I have the necessary authorization." : "Declaro que cuento con la autorización necesaria"}</span>
                            </div>           
                        </div>
                        {
                            (requestError == "checkbox") &&
                            <div className="btn btn_indicator btn_primary btn_error">
                                <ErrorIcon />
                                <span>{props.lang == "english" ? "You must check the authorization box.": "Debe marcar la casilla de autorización"}</span>
                            </div>
                        }
                        {/* Debe marcar la casilla para confirmar que tiene la autorización necesaria. */}
                        {
                            (requestError == "params") &&
                            <div className="btn btn_indicator btn_primary btn_error">
                                <ErrorIcon />
                                <span>{props.lang == "english" ? "Please fill all required fields." : "Por favor, rellene todos los campos obligatorios."}</span>
                            </div>
                        }
                        {
                            (requestError == "fields") &&
                            <div className="btn btn_indicator btn_primary btn_error">
                                <ErrorIcon />
                                <span>{props.lang == "english" ? "Fill required fields." : "Complete los campos obligatorios."}</span>
                            </div>
                        }
                        {
                            (requestError == "balance") &&
                            <div className="btn btn_indicator btn_primary btn_error">
                                <ErrorIcon />
                                <span>{props.lang == "english" ? "You are out of balance." : "No tienes saldo."}</span>
                            </div>
                        }
                        {
                            (requestError == "error" || requestError == "try") &&
                            <div className="btn btn_indicator btn_primary btn_error">
                                <ErrorIcon />
                                <span>{props.lang == "english" ? "An error occurred. Please try again later." : "Se ha producido un error. Vuelva a intentarlo más tarde."}</span>
                            </div>
                        }
                        <div className="sandbox_api_list_endpoint_submit">
                            {
                                requestLoading &&
                                <div className="btn btn_icon btn_indicator btn_primary btn_loading">
                                    <LoadingIcon />
                                </div>
                            }
                            <div
                                className={"btn btn_primary" + (requestLoading ? " btn_loading" : "")}
                                onClick={() => !requestLoading ? sendRequest() : null}
                            >
                                <span>Send request</span>
                            </div>
                        </div>
                    </>
                }
                        
                        </>
                }


            </div>
        </div>
    );
}

export default SandboxStandard;