import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link } from "react-router-dom";

import CartIcon from "../../icons/cart";

import "./payments.css";

function PaymentsPlans(props) {


  const [activateLoading, setActivateLoading] = useState(false);

    useEffect(() => {
      if (props.availablePlansLoad) {
        try {
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/payments/plans`,
            {clientId: props.clientId}
          )
          .then(res => {
            props.setAvailablePlans(res.data);
            props.setAvailablePlansLoad(false);
          })
        } catch (error) {}  
      }
    }, []);

    const convertDate = (dateString) => {
        const date = new Date(dateString);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${day}/${month}/${year}`;
      }
    
      const calculateDaysRemaining = (targetDate) => {
        const currentDate = new Date();
        const targetDateObj = new Date(targetDate);
        const timeDifference = targetDateObj - currentDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference;
      }

      const formatDate = (dateString) => {
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const monthsSpanish = [
          'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
          'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
      
        const date = new Date(dateString);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(yesterday.getDate() - 1);
      
        if (date.toDateString() === currentDate.toDateString()) {
          return 'Today';
        } else if (date.toDateString() === yesterday.toDateString()) {
          return 'Yesterday';
        } else {
          const day = date.getDate();
          const month = props.lang == "english" ? months[date.getMonth()] : monthsSpanish[date.getMonth()];
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        }
      } 
      
  const purchasePlan = async (priceId, planId, apiId, unlimitedValidity) => {
    setActivateLoading(true);
    try {
          const stripeBody = {
            isTest: false,
            planId: planId,
            priceId: priceId,
            clientId: props.clientId,
            apiId: apiId,
            isUnlimited: unlimitedValidity
          };

    
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/stripe`,
            stripeBody
          )
            .then(res => {
                if (res.data.startsWith("https://")) {
                    window.location.replace(res.data);
                }
            })

    } catch (error) {
        setActivateLoading(false);
    }          
  }


  return (
    <>
    {
      props.availablePlansLoad ?
      <div className="payment_table_skeleton"></div>
      :
      <>
        {
          props.tabHeader == "active" &&
          <>
          {
            props.availablePlans
            .filter(plan => calculateDaysRemaining(plan.ModificationDate) > -4 ? true : false)
            .length == 0
            ?
            <div className="plan_empty_container">
              <div className="plan_empty_icon">
                <CartIcon />
              </div>
              <h3>{props.lang == "english" ? "No Active Plans" : "No hay planes activos"}</h3>
              <p>{props.lang == "english" ? "No active plans found. Explore the marketplace." : "No se han encontrado planes activos. Explorar el mercado."}</p>
              <Link className="btn btn_primary" to="/">
                <span>{props.lang == "english" ? "Marketplace" : "Marketplace"}</span>
              </Link>
            </div>
            :
            <table className="payment_table">
              <thead>
                <tr>
                  <td>
                    <div className="table_element tb_plan_name">API</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_price">{props.lang == "english" ? "Price" : "Precio"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_type">{props.lang == "english" ? "Type of plan" : "Tipo de plan"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_count">{props.lang == "english" ? "Remaining calls" : "Convocatorias restantes"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_date">{props.lang == "english" ? "Subscription Date" : "Llamadas restantes"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_switch">{props.lang == "english" ? "Autopayment" : "Pago automático"}</div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  props.availablePlans
                  .filter(plan => calculateDaysRemaining(plan.ModificationDate) > -4 ? true : false)
                  .map(plan => 
                    <tr>
                      <td>
                        <div className="table_element tb_plan_name">{plan.Name}</div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_price"><span>{plan.Price}</span> MXN</div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_type">
                          <span>{
                            props.lang == "english"
                            ?
                            plan.PlanType == "Probar" ? "FREE TRIAL"
                            :
                            plan.PlanType == "Inicial" ?  "INITIAL PLAN"
                            :
                            plan.PlanType == "Medio" ?  "MEDIUM PLAN"
                            :
                            "ADVANCED PLAN"
                            :
                            plan.PlanType == "Probar" ? "PROBAR"
                            :
                            plan.PlanType == "Inicial" ?  "INICIAL"
                            :
                            plan.PlanType == "Medio" ?  "MEDIO"
                            :
                            "AVANZADO"
                          }</span>
                        </div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_count">
                          <div className="tb_plan_count_wrapper">
                            <span>{plan.NumberCall}</span>
                            <span>/ {plan.CallLimit}</span></div>
                          </div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_date">
                          <span className={calculateDaysRemaining(plan.ModificationDate) <= 0 ? "tb_plan_date_late" : ""}>
                            {
                              calculateDaysRemaining(plan.ModificationDate) > 0
                              ? (props.lang == "english" ? (calculateDaysRemaining(plan.ModificationDate) + " days left") : "Quedan " + (calculateDaysRemaining(plan.ModificationDate)) + " días")
                              : (calculateDaysRemaining(plan.ModificationDate)*-1) + (props.lang == "english" ? " days late" : "días de retraso")
                            }
                          </span>
                          <span>Due to: {convertDate(plan.ModificationDate)}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_switch">
                          <div className={"switch" + (plan.PaymentAutomatic ? " active" : "")}>
                            <div className="switch_ball"></div>
                          </div>
                        </div>
                      </td>
                    </tr>                    
                    )
                }
              </tbody>
            </table>
          }
          </>
        }
        {
          props.tabHeader == "canceled" &&
          <>
          {
            props.availablePlans
            .filter(plan => calculateDaysRemaining(plan.ModificationDate) <= -4 ? true : false)
            .length == 0
            ?
            <div className="plan_empty_container">
              <div className="plan_empty_icon">
                <CartIcon />
              </div>
              <h3>{props.lang == "english" ? "No Deactivated Plans" : "No hay planes desactivados"}</h3>
              <p>{props.lang == "english" ? "You don't have any deactivated plans at the moment..." : "No tienes ningún plan desactivado en este momento..."}</p>
              <div className="plan_empty_btn_flex">
                <div className="btn" onClick={() => props.setTabHeader("active")}>
                  <span>{props.lang == "english" ? "Active plans" : "Planes activos"}</span>
                </div>
                <Link className="btn btn_primary" to="/">
                  <span>{props.lang == "english" ? "Marketplace" : "Marketplace"}</span>
                </Link>
              </div>
            </div>
            :            
            <table className="payment_table">
              <thead>
                <tr>
                  <td>
                    <div className="table_element tb_plan_name">API</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_price">{props.lang == "english" ? "Price" : "Precio"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_type">{props.lang == "english" ? "Type of plan" : "Tipo de plan"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_count">{props.lang == "english" ? "Calls used" : "Llamadas utilizadas"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_date">{props.lang == "english" ? "Expiration Date" : "Fecha de expiración"}</div>
                  </td>
                  <td>
                    <div className="table_element tb_plan_action"></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  props.availablePlans
                  .filter(plan => calculateDaysRemaining(plan.ModificationDate) <= -4 ? true : false)
                  .map(plan =>
                    <tr>
                      <td>
                        <div className="table_element tb_plan_name">{plan.Name}</div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_price"><span>{plan.Price}</span> MXN</div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_type">
                          <span>{
                            props.lang == "english"
                            ?
                            plan.PlanType == "Probar" ? "FREE TRIAL"
                            :
                            plan.PlanType == "Inicial" ?  "INITIAL PLAN"
                            :
                            plan.PlanType == "Medio" ?  "MEDIUM PLAN"
                            :
                            "ADVANCED PLAN"
                            :
                            plan.PlanType == "Probar" ? "PROBAR"
                            :
                            plan.PlanType == "Inicial" ?  "INICIAL"
                            :
                            plan.PlanType == "Medio" ?  "MEDIO"
                            :
                            "AVANZADO"
                          }</span>
                        </div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_count">
                          <div className="tb_plan_count_wrapper">
                            <span>{plan.CallUsed}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_date_main">
                          <span>{formatDate(plan.ModificationDate)}</span>
                        </div>
                      </td>
                      <td>
                        <div className="table_element tb_plan_action">
                          {
                            plan.PlanType != "Probar" &&
                            <div
                              className={ !activateLoading ? "btn btn_small btn_success" : "btn btn_small btn_success btn_loading" }
                              onClick={() => !activateLoading && purchasePlan(plan.PriceStripeId, plan.PlanId, plan.ApiId, plan.UnlimitedValidity)}
                            ><span>{props.lang == "english" ? "Reactivate" : "Reactivar"}</span></div>
                          }
                        </div>
                      </td>
                    </tr>  
                  )
                }
              </tbody>
            </table>
          }
          </>
        }
      </>
    }
    </>
  );
}

export default PaymentsPlans;