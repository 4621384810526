import { useState, useEffect } from "react";

import axios from "axios";

import CheckIcon from "../../../icons/check"

function Step3(props) {


    const [categoryLoad, setCategoryLoad] = useState(true);

    const getCategoriesCountries = () => {
        setCategoryLoad(true);
        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/get-category-country")
        .then(res => {
            setCategoryLoad(false);
            props.setCategoryArray(res.data.categories);
            props.setCountryArray(res.data.countries);
        })
    }

    const handleCategory = (value) => {
        if (props.categorySelected.includes(value)) {
            props.setCategorySelected(props.categorySelected.filter(category => category != value));
        } else {
            props.setCategorySelected([...props.categorySelected, value]);
        }
    }

    const handleCountry = (value) => {
        if (props.countrySelected.includes(value)) {
            props.setCountrySelected(props.countrySelected.filter(country => country != value));
        } else {
            props.setCountrySelected([...props.countrySelected, value]);
        }
    }


    useEffect(() => {
        if (props.categoryArray.length == 0) {
            getCategoriesCountries();
        }
    }, [])

    return (
    <div className="add_new_api_container">
        <div className="card add_api_card">
        {
            (categoryLoad && props.categoryArray.length == 0) ?
            <p>Loading Categories and Countries...</p>
            :
            <>
            <h3>Categories</h3>
            <div className="add_api_checkbox_container">
                {props.categoryArray.map(category =>
                    <div className={"input_checkbox_container" + ( props.categorySelected.includes(category.ApiCategoryId) ? " active" : "" )} onClick={() => handleCategory(category.ApiCategoryId)}>
                        <div className="input_radio">
                            { props.categorySelected.includes(category.ApiCategoryId) && <CheckIcon /> }
                        </div>
                        <span>{category.Value}</span>
                    </div>  
                )}
            </div>
            <h3>Countries</h3>
            <div className="add_api_checkbox_container">
                {props.countryArray.map(country =>
                    <div className={"input_checkbox_container" + ( props.countrySelected.includes(country.CountryId) ? " active" : "" )} onClick={() => handleCountry(country.CountryId)}>
                        <div className="input_radio">
                            { props.countrySelected.includes(country.CountryId) && <CheckIcon /> }
                        </div>
                        <span>{country.Name}</span>
                    </div>  
                )}
            </div>
            </>                
        }
        </div>
        <div className="add_api_footer_action">
            <div className="btn" onClick={() => props.handleBack()}>
                <span>Back</span>
            </div>
            <div className="btn btn_primary" onClick={() => props.handleNext()}>
                <span>Next</span>
            </div>
        </div>
    </div>
    );
  }
  
  export default Step3;
  