import axios from "axios";
import { useState, useEffect } from "react";

import LoadingIcon from "../../../icons/loading";
import SuccessIcon from "../../../icons/success";
import ErrorIcon from "../../../icons/error";
import PlusIcon from "../../../icons/plus";

function Step1(props) {

    const [providerLoad, setProviderLoad] = useState(true);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [addProviderError, setAddProviderError] = useState(false);
    const [addProviderReqError, setAddProviderReqError] = useState("default"); // default - loading - error - success

    const [createProvider, setCreateProvider] = useState(false);

    const handleName = (e) => {
        setName(e.target.value);
        setAddProviderError(false);
        setAddProviderReqError("default");
        setNameError(false);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);        
        setAddProviderError(false);
        setAddProviderReqError("default");
        setEmailError(false);
    }

    const getProvider = () => {
        setProviderLoad(true);
        axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/get-provider")
        .then(res => {
            props.getProviderId(res.data[0].SupplierId)
            setProviderLoad(false);
            props.setProviderArray(res.data);
        })
    }

    const addProvider = () => {

        let checkErrors = true;

        if (name.length == 0) {
            setNameError(true);
            checkErrors = false;
        }

        if (email.length == 0) {
            setEmailError(true);
            checkErrors = false;
        }

        setAddProviderError(false);        

        if (checkErrors) {
            setAddProviderReqError("loading");
            axios.post("https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/admin/add-provider",
                {
                    name: name,
                    email: email
                }
            )
            .then(res => {
                setAddProviderReqError("success");
                getProvider();
            })
            .catch((err) => setAddProviderReqError("error"))   
        }
    }

    useEffect(() => {
        if (props.providerId.length == 0) {
            getProvider();
        }
    }, [])

    return (
    <div className="add_new_api_container">
        <div className="card add_api_card">
            {
                providerLoad && props.providerId.length == 0
                ? <p>Loading provider list</p>
                : 
                <div className="input_container">
                    <label>Provider</label>
                    <div className="input_container_inside_flex">
                        <select onChange={(e) => props.getProviderId(e.target.value)}>
                            {
                                props.providerArray.map(provider => {
                                    if (provider.SupplierId == props.providerId) {
                                        return <option value={provider.SupplierId} selected>{provider.Name}</option>
                                    } else {
                                        return <option value={provider.SupplierId}>{provider.Name}</option>
                                    }
                                })
                            }
                        </select>
                        <div className="btn btn_link btn_w_icon" onClick={() => setCreateProvider(!createProvider)}>
                            <PlusIcon />
                            <span>Add provider</span>
                        </div>
                    </div>
                </div>
            }
        </div>
        {
            createProvider &&
            <div className="card add_api_card">
                <h2>Create new provider</h2>
                <div className={"input_container" + (nameError ? " input_container_error" : "")}>
                    <label>Name of provider</label>
                    <input type="text" value={name} onChange={(e) => handleName(e)}/>
                </div>
                <div className={"input_container" + (emailError ? " input_container_error" : "")}>
                    <label>Email of provider</label>
                    <input type="text"  value={email} onChange={(e) => handleEmail(e)}/>
                </div>
                <div className="add_api_action_container">             
                    {
                        addProviderReqError != "default" &&
                            <div className={"btn btn_icon btn_indicator btn_primary" + (
                                addProviderReqError == "loading" ? " btn_loading":
                                addProviderReqError == "success" ? " btn_success" :
                                " btn_error"
                            )}>
                                {
                                    addProviderReqError == "loading" ? <LoadingIcon /> :
                                    addProviderReqError == "success" ? <SuccessIcon /> :
                                    <ErrorIcon />
                                }
                            </div>
                    }
                    <div className={"btn btn_primary" + (["loading"].includes(addProviderReqError) ? " btn_loading" : "")} onClick={() => ["default", "success"].includes(addProviderReqError) ? addProvider() : null}>
                        <span>Add provider</span>
                    </div>
                </div>
            </div>
        }
        <div className="btn btn_primary" onClick={() => props.handleNext()}>
            <span>Next</span>
        </div>
    </div>
    );
  }
  
  export default Step1;
  