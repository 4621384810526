import React, { useState, useEffect } from "react";

import axios from "axios";

import VisbleIcon from "../../../icons/visible";
import NotVisibleIcon from "../../../icons/not_visible";
import LoadingIcon from "../../../icons/loading";
import SuccessIcon from "../../../icons/success";
import ErrorIcon from "../../../icons/error";

function SetPassword(props) {

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [passwordBtn, setPasswordBtn] = useState("default"); // default - loading - success - error

  const [passwordRequirements, setPasswordRequirements] = useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    specialCharacter: false,
    characterLength: false
  });

  const handlePassword = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setPasswordError(false);
    
    const hasLowerCase = /[a-z]/.test(passwordValue);
    const hasUpperCase = /[A-Z]/.test(passwordValue);
    const hasNumber = /\d/.test(passwordValue);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-="']/.test(passwordValue);
    const hasValidLength = passwordValue.length >= 8;

    setPasswordRequirements({
      lowerCase: hasLowerCase,
      upperCase: hasUpperCase,
      number: hasNumber,
      specialCharacter: hasSpecialCharacter,
      characterLength: hasValidLength
    });

    if (["success","error"].includes(passwordBtn)) {
      setPasswordBtn("default");
    }
  }

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      passwordFunction();
    }
  }

  const passwordFunction = () => {

    const allRequirementsMet = Object.values(passwordRequirements).every(Boolean);

    let checkErrors = true;
    
    if (!allRequirementsMet) {
      setPasswordError(true);
      checkErrors = false;
    }

    if (checkErrors) {
      try {

        setPasswordBtn("loading");
        console.log(          {password: password, credentialId: props.credentialId}          )
        axios.post(
          `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/marketplace/auth/set-password`,
          {password: password, credentialId: props.credentialId}
        ).then(res => {
          if (res.data.userId) {
            setPasswordBtn("success");
            localStorage.setItem("user", JSON.stringify({
                userId: res.data.userId,
                userKey: res.data.userKey,
                username: res.data.username,
                avatar: res.data.avatar
            }));
            props.setIsConnected(true);
            props.setUser({
                userId: res.data.userId,
                userKey: res.data.userKey,
                username: res.data.username,
                avatar: res.data.avatar
            });
            window.location.reload(true);
          } else if (res.data.err){
            setPasswordBtn("error");
          }
        })

      } catch (error) {
        setPasswordBtn("error");
      }
    }
  }


  return (
    <div className="login_form">
            <div className="login_wrapper">
              <div className="login_form_title">
                <h1>{props.lang == "english" ? "Choose your password" : "Elige tu contraseña"}</h1>
                <p>{props.lang == "english" ? "Almost done. Enter you password and you're all set." : "Casi terminado. Ingresa tu contraseña y estarás listo."}</p>
              </div>
              <div className={"input_container" + (passwordError ? " input_container_error" : "")}>
                <label>{props.lang == "english" ? "Password" : "Contraseña"}</label>
                <input type={passwordVisible ? "text" :"password"} onKeyUp={(e) => handleEnterPress(e)} value={password} onChange={(e) => handlePassword(e)}/>
                  {
                    passwordVisible
                    ? <div className="password_visible_btn disable_select" onClick={() => setPasswordVisible(false)}><NotVisibleIcon /></div>
                    : <div className="password_visible_btn disable_select" onClick={() => setPasswordVisible(true)}><VisbleIcon /></div>
                  }
              </div>
              <div className="password_verification">
                <div className={"password_verification_item" + ( passwordRequirements.lowerCase ? " password_verification_valid" : "")}>
                  <div className="password_verification_icon_wrapper">
                    <div className="password_verification_icon"></div>
                  </div>
                      <span>{props.lang == "english" ? "one lowercase letter" : "una letra minúscula"}</span>
                </div>
                <div className={"password_verification_item" + ( passwordRequirements.upperCase ? " password_verification_valid" : "")}>
                <div className="password_verification_icon_wrapper">
                    <div className="password_verification_icon"></div>
                  </div>
                      <span>{props.lang == "english" ? "one uppercase letter" : "una letra mayúscula"}</span>
                </div>
                <div className={"password_verification_item" + ( passwordRequirements.number ? " password_verification_valid" : "")}>
                <div className="password_verification_icon_wrapper">
                    <div className="password_verification_icon"></div>
                  </div>
                      <span>{props.lang == "english" ? "one number" : "un número"}</span>
                </div>
                <div className={"password_verification_item" + ( passwordRequirements.specialCharacter ? " password_verification_valid" : "")}>
                <div className="password_verification_icon_wrapper">
                    <div className="password_verification_icon"></div>
                  </div>
                      <span>{props.lang == "english" ? "one special character" : "un carácter especial"}</span>
                </div>
                <div className={"password_verification_item" + ( passwordRequirements.characterLength ? " password_verification_valid" : "")}>
                <div className="password_verification_icon_wrapper">
                    <div className="password_verification_icon"></div>
                  </div>
                      <span>{props.lang == "english" ? "8 character minimum" : "8 caracteres mínimo"}</span>
                </div>
              </div>
              <div className="login_action">
                {
                    passwordBtn != "default" &&
                      <div className={"btn btn_icon btn_indicator btn_primary" + (
                          passwordBtn == "loading" ? " btn_loading":
                          passwordBtn == "success" ? " btn_success" :
                          " btn_error"
                      )}>
                          {
                              passwordBtn == "loading" ? <LoadingIcon /> :
                              passwordBtn == "success" ? <SuccessIcon /> :
                              <ErrorIcon />
                          }
                      </div>
                }
                <div className={"btn btn_primary" + (passwordBtn == "loading" ? " btn_loading" : "")} onClick={() => passwordBtn != "loading" ? passwordFunction() : null}>
                  <span>{props.lang == "english" ? "Set password" : "Establecer contraseña"}</span>
                </div>
              </div>
            </div>
          </div>
    );
}

export default SetPassword;