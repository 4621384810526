import React from "react";

function PrevencionFraudIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 1"
      viewBox="0 0 57.88 57.88"
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M28.94 10.75a24.223 24.223 0 0017.18 6.06c3.78 12.85-3.58 26.33-16.43 30.1l-.75.21c-12.96-3.36-20.75-16.6-17.38-29.56.07-.25.13-.5.21-.75 6.3.29 12.46-1.89 17.18-6.06M28.94 10.75v36.38"
      ></path>
    </svg>
  );
}

export default PrevencionFraudIcon;
