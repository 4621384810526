import React from "react";

function AuthentificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 1"
      viewBox="0 0 57.88 57.88"
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M38.57 22.04c1.25 2.07 1.78 4.5 1.52 6.9v1.38c0 1.45.38 2.88 1.1 4.14M23.53 27.56c0-3.05 2.47-5.52 5.52-5.52s5.52 2.47 5.52 5.52v1.38c0 2.99.97 5.89 2.76 8.28"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M29.05 27.56v2.76c0 3.95 1.2 7.8 3.45 11.04M23.53 33.08a25.02 25.02 0 002.48 8.28M19.25 38.6c-.93-3.13-1.35-6.39-1.24-9.66v-1.38c-.02-6.1 4.91-11.05 11.01-11.07 1.95 0 3.86.51 5.55 1.48M38.57 22.04c1.25 2.07 1.78 4.5 1.52 6.9v1.38c0 1.45.38 2.88 1.1 4.14"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23.53 27.56c0-3.05 2.47-5.52 5.52-5.52s5.52 2.47 5.52 5.52v1.38c0 2.99.97 5.89 2.76 8.28"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M29.05 27.56v2.76c0 3.95 1.2 7.8 3.45 11.04M23.53 33.08a25.02 25.02 0 002.48 8.28M19.25 38.6c-.93-3.13-1.35-6.39-1.24-9.66v-1.38c-.02-6.1 4.91-11.05 11.01-11.07 1.95 0 3.86.51 5.55 1.48"
      ></path>
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M9.69 19.21v-4.75c0-2.62 2.13-4.75 4.75-4.75h4.75M9.69 38.21v4.75c0 2.62 2.13 4.75 4.75 4.75h4.75M38.19 9.71h4.75a4.752 4.752 0 014.75 4.75v4.75M38.19 47.71h4.75c2.62 0 4.75-2.13 4.75-4.75v-4.75"></path>
      </g>
    </svg>
  );
}

export default AuthentificationIcon;
